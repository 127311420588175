import { gql } from '@apollo/client';

export default class ServiceQuery {
static GET_SERVICE_INVOICE_LIST = gql`
    query getROList(
      $paginate: PaginationInput
      $sort: [SortField]
      $filter: ROFilter
    ) {
      getROList(paginate: $paginate, sort: $sort, filter: $filter) {
        totalCount
        data {
          invoiceNumber
          customerCode
          advisor
          vehicleStock
          vehicleCustomStock
          vehicleYear
          vehicleMake
          vehicleModel
          vehicleTrim
          vehicleEngine
          status
          tax
          fees
          warranty
          deposit
          discount
          shopSupplies
          totalParts
          totalLabor
          total
          totalSubletPrice
          totalSubletCost
          roOpen
          roClosed
          lotName
          hatNumber
          postedDate
          milesIn
          milesOut
          inHouse
          dealNumber
          customer {
            firstName
            lastName
          }
          jobs {
            jobNumber
            cause
            correction
            technician
            technicianImage
            status
            customerStates
            partsTotal
            laborTotal
            laborCost
            hours
            parts {
              partStockNumber
              quantity
              description
              partCost
              total
            }
          }
        }
      }
    }
  `;

static GET_SERVICE_JOBS_LIST = gql`
query getServiceJobs($id: Int!) {
  getServiceJobs(invoiceNumber: $id) {
    jobNumber
    status
    customerStates
    cause
    correction
    technician
    technicianImage
    payType
    laborTotal
    laborCost
    partsTotal
    subletCost
  }
}
`;

static GET_SERVICE_INVOICE_NOTES = gql`
query getServiceInvoiceNotes($id: Int!) {
  getServiceInvoiceNotes(invoiceNumber: $id) {
        noteUser
        noteDate
        noteText
        client
      }
    }`;

static GET_SERVICE_DEFAULT_SETTINGS = gql`
query getServiceSettingsKeys($lotName: String!){
  getServiceSettingsKeys(lotName: $lotName){
    hourlyLaborRate
    customerPayHourlyRate
    shopSuppliesLimit
    shopSuppliesPercent
    surplusTax
    noTaxOnRoLabor
    customerSubletPriceMarkUp
    internalSubletPriceMarkUp
    customTaxRateEnabled
    customTaxRateValue
    allowAddInventoryPartsFromProcess
    chargeSalesTaxASR
    chargeSalesTaxOnWarrantyDeductable
    chargeSalesTaxOnWarrantyParts
    shopSuppliesType
  }
}`;

static GET_MAX_RECON_COST_BY_VEHICLE = gql`
query getMaxReconCost($stockNumber: Int!, $invoiceNumber: Int!) {
  getMaxReconCost(stockNumber: $stockNumber, invoiceNumber: $invoiceNumber){
    maximunReconInventory
    maximumReconCost
    totalRepairs
  }
}`;

static GET_INSPECTION_ITEMS = gql`
query getInspectionItems($activeItems: Boolean) {
  getInspectionItems(activeItems: $activeItems) {
        reconInspectionCategoryId
        name
        order
        active
        items {
            reconInspectionItemId
            name
            jobTypeIds
            active
        }
      }
    }`;

static GET_SAVED_INSPECTION_ITEMS = gql`
query getSavedInspectionItems($invoiceNumber: Int!) {
  getSavedInspectionItems(invoiceNumber: $invoiceNumber) {
      vehicleInspectionItemId
      reconInspectionItemId
      pass
      soon
      fail
      repair
      jobTypeIds
      comment
      images {
        vehicleInspectionItemImageId
        imageURL
      }
    }
  }`;

static GET_SERVICE_INVOICE = gql`
query getServiceInvoiceFull($id: Int!) {
  getServiceInvoiceFull(id: $id) {
      invoiceNumber
      customerCode
      vehicleStock
      vehicleCustomStock
      milesIn
      milesOut
      hatNumber
      vehicleVin
      vehicleYear
      vehicleMake
      vehicleModel
      vehicleTrim
      lotName
      advisor
      saveParts
      deposit
      warranty
      warrantyDeductable
      fees
      shopSupplies
      cancellationFee
      storageFee
      laborDiscount
      discountPercentage
      discount
      quickNote
      status
      totalParts
      totalLabor
      total
      lotId
      totalSubletPrice
      advisorId
      lotId
      licensePlate
      vehicleMiles
      vehicleStyle
      vehicleColor
      roCompleted
      tag
      flag
      inHouse
      isTaxable
      roClosed
      roOpen
      vehicleEngine
      tax
      inspectionDone
      rentalCar
      estimateDelivery
      hashRecord
      userField1
      customerWaiting
      dealNumber
      customerPay
      surplusSalesTax
      overrideShopSuppliesFee
      customer {
        customerCode
        firstName
        lastName
        address
        email
        cellPhone
      }
      jobs {
        invoiceNumber
        jobNumber
        technician
        payType
        hours
        laborCost
        laborTotal
        subletCost
        partsTotal
        subletPrice
        subletInvoice
        subletVendorId
        isHrsChecked
        approved
        status
        discount
        customerStates
        cause
        correction
        notes
        estimatedHours
        actualHours
        recommend1
        laborOperationCode
        technicianImage
        isSublet
        serviceJobId
        technicianId
        flaggedHours
        overrideSubletPrice
        vehicleInspectionItemIds
        payTypeId
        jobTypeId
        jobTypeDescription
        jobDescription
        flaggedHoursPaid
        flaggedHoursApprovedOn
        technicianFunded
        technicianFundedApprovedOn
        warrantyCovered
        internalCovered
        customerPay
        customerTax
        partsTax
        laborTax
        parts {
          serviceJobPartsId
          invoiceNumber
          jobNumber
          partStockNumber
          description
          quantity
          listPrice
          netPrice
          total
          partCost
          partInventoryId
          isTaxable
          partTaxAmount
        }
      }
    }
  }`;

static GET_VEHICLES_BY_CUSTOMER= gql`
query getVehiclesByCustomerForService($code: ID!, $inHouse: Boolean!, $search: String) {
  getVehiclesByCustomerForService(code: $code, inHouse: $inHouse, search: $search) {
    stockNumber
    customStockNumber
    year
    make
    model
    trim
    engine
    vin
    maximumReconCost
    totalRepairs
    lotName
  }
}
`;

static GET_SERVICE_ADVISOR = gql`
query getServiceAdvisor{
  getServiceAdvisor{
        advisor
      }
    }`;

static GET_TECHNICIAN_JOBS = gql`
    query getTechniciansJobs(
      $paginate: PaginationInput
      $sort: [SortField]
      $filter: Filter
    ) {
      getTechniciansJobs(paginate: $paginate, sort: $sort, filter: $filter) {
        totalCount
        data {
          invoiceNumber
          jobNumber
          serviceJobId
          technicianId
          technician
          problemWhat
          problemWhen
          problemCause
          customerStates
          cause
          correction
          recommend1
          recommend2
          recommend3
          recommend4
          recommend5
          status
          estimatedHours
          requestedParts
          serviceInvoice {
            status
            advisor
            lotName
            vehicleStock
            vehicleYear
            vehicleMake
            vehicleModel
            vehicleTrim
            customer {
              firstName
              lastName
            }
          },
        }
      }
    }
  `;

static GET_CUSTOMER_FOR_SERVICE= gql`
query getCustomersForService($inHouse: Boolean!, $search: String!) {
  getCustomersForService(inHouse: $inHouse, search: $search) {
    customerCode
    firstName
    lastName
    cellPhone
    dln
    email
    isTaxable
  }
}
`;

static GET_SERVICE_OPERATION_CODE_LIST = gql`
  query getOperationCodes(
    $paginate: PaginationInput
    $sort: [SortField]
    $filter: ServiceOpCodesFilter
  ) {
    getOperationCodes(paginate: $paginate, sort: $sort, filter: $filter) {
      totalCount
      data {
        opCodeId
        opCode
        description
        customerStates
        cause
        correction
        note
        hours
        laborAmount
        detail {
          jobTemplateDetailId
          jobTemplateId
          partStockNumber
          quantity
          partInventoryId
          partsInventory {
            partStockNumber
            description
            partCost
            listPrice
            netPrice
            lotName
            active
            isTaxable
          }
        }
      }
    }
  }`;

static GET_SERVICE_OPERATION_CODE_MAIN_LIST = gql`
  query getOperationCodes(
    $paginate: PaginationInput
    $sort: [SortField]
    $filter: ServiceOpCodesFilter
  ) {
    getOperationCodes(paginate: $paginate, sort: $sort, filter: $filter) {
      totalCount
      data {
        opCodeId
        opCode
        description
        customerStates
        cause
        correction
        note
        hours
        laborAmount
        detail {
          jobTemplateDetailId
        }
      }
    }
  }`;

static GET_SERVICE_OPERATION_CODE = gql`
  query getOperationCode(
    $id: ID
  ) {
    getOperationCode(id: $id) {
      opCodeId
      opCode
      description
      customerStates
      cause
      correction
      note
      hours
      laborAmount
      detail {
        jobTemplateDetailId
        jobTemplateId
        partStockNumber
        quantity
        partInventoryId
        partsInventory {
          partStockNumber
          description
          partStockNumber
          partCost
          listPrice
          netPrice
        }
      }
    }
  }
`;

static GET_SERVICE_INVOICE_HISTORY = gql`
    query getROHistory(
      $invoiceNumber: Int!,
    ) {
      getROHistory(invoiceNumber: $invoiceNumber) {
          invoiceNumber
          customerCode
          advisor
          vehicleStock
          vehicleCustomStock
          vehicleYear
          vehicleMake
          vehicleModel
          vehicleTrim
          vehicleVin
          status
          tax
          fees
          warranty
          deposit
          discount
          shopSupplies
          totalParts
          totalLabor
          total
          roOpen
          roClosed
          lotName
          hatNumber
          postedDate
          milesIn
          milesOut
          inHouse
          dealNumber
          customer {
            firstName
            lastName
          }
          jobs {
            jobNumber
            cause
            correction
            technician
            technicianImage
            status
            customerStates
            partsTotal
            laborTotal
            laborCost
            hours
            parts {
              partStockNumber
              quantity
              description
              partCost
              total
            }
          }
      }
    }`;
}

const Scope = Object.freeze({
    CRM_SETTING_TAG_WRITE: 'crm.setting.tag:write',
    CRM_SETTING_TAG_READ: 'crm.setting.tag:read',
    CRM_OPPORTUNITY_TAG_WRITE: 'crm.opportunity.tag:write',
    CRM_SETTING_LOST_REASON_READ: 'crm.setting.lost_reason:read',
    CRM_SETTING_LOST_REASON_WRITE: 'crm.setting.lost_reason:write',
    CRM_SETTING_LEAD_SOURCES_READ: 'crm.settings.lead_sources:read',
    CRM_SETTING_LEAD_SOURCES_WRITE: 'crm.settings.lead_sources:write',
    TRAINING_SETTINGS_READ: 'training.settings:read',
    TRAINING_SETTINGS_WRITE: 'training.settings:write',
    AI_SETTINGS_READ: 'ai.settings:read',
    AI_SETTINGS_WRITE: 'ai.settings:write',
    GLOBAL_BI_REPORTS_SETTINGS_READ: 'global.bi.reports.settings:read',
    GLOBAL_BI_REPORTS_SETTINGS_WRITE: 'global.bi.reports.settings:write',
    // TODO Remove the HR and Reports Scopes when those applications are being migrated to the DMSPro
    HR_READ: 'hr:read',
    REPORTS_READ: 'reports:read',
});

export default Scope;

import StringUtils from 'lib/StringUtils';

const useCustomStockNumber = (userInformation, data) => {
    if (userInformation == null) return '';

    const isCustomStockEnabled = userInformation.customStockEnabled;
    const customStockNumber = data?.customStockNumber;
    return isCustomStockEnabled && !StringUtils.isEmpty(customStockNumber) ? customStockNumber : data?.stockNumber;
};

export default useCustomStockNumber;

import React, { useReducer } from 'react';
import {
    makeStyles, Button, useTheme,
    useMediaQuery, Tooltip,
} from '@material-ui/core';
import Header from 'components/widgets/Header';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import clsx from 'clsx';
import { AddIcon, BlockOutlinedIcon, EditOutlinedIcon } from 'components/icons';
import { modules } from 'utils/enum/modules';
import { useHistory } from 'react-router';
import InputSearch from 'components/widgets/InputSearch';
import { ServiceSubModules } from 'utils/enum/ServiceInvoiceEnum';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import ServiceSubscription from 'services/graphQL/subscription/service/ServiceSubscription';
import SubscriptionActionType from 'utils/enum/SubscriptionActionType';
import ServiceQuery from 'services/graphQL/query/service/ServiceQuery';
import { DataSort, FetchPolicy } from 'utils/enum/Core';
import ModalUtils from 'utils/ModalUtils';
import Table from 'components/widgets/Table';
import Container from 'components/widgets/Container';
import NumberUtils from 'lib/NumberUtils';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import ServiceMutation from 'services/graphQL/mutate/service/ServiceMutation';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import Permission from '../../../../utils/enum/Permissions';
import KeyStore from '../../../../utils/KeyStore';

const useStyle = makeStyles((theme) => AccountingStyles.mainList(theme));

const ACTION_TYPES = {
    SET_STATE_VALUES: 'setStateValues',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.payload };
    }
    default:
        return state;
    }
};

const OperationCodeMain = () => {
    const history = useHistory();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [state, dispatch] = useReducer(reducer, {
        paginate: {
            init: 0,
            limit: 50,
        },
        records: [],
        totalCount: 0,
        searchParam: '',
        idToDelete: 0,
    });

    const dispatchChangeState = (newParams) => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            payload: newParams,
        });
    };

    const {
        records, totalCount, paginate, searchParam,
        idToDelete,
    } = state;

    const classes = {
        ...useStyle(),
    };

    const keyStore = new KeyStore();
    const OPERATION_CODE_ADD = keyStore.hasPermission(Permission.SERVICE_OPERATION_CODE_ADD);
    const OPERATION_CODE_DELETE = keyStore.hasPermission(Permission.SERVICE_OPERATION_CODE_DELETE);
    const OPERATION_CODE_EDIT = keyStore.hasPermission(Permission.SERVICE_OPERATION_CODE_EDIT);
    const OPERATION_CODE_READ = keyStore.hasPermission(Permission.SERVICE_OPERATION_CODE_READ);

    const {
        main,
        containerFilterSearch,
        search,
        searchFull,
        buttonSpacing,
        justifyContentCenter,
        containerFilterSearchCustom,
        columnHeaderStyle,
        columnLeft,
        columnRight,
        columnCenter,
        columnStyle,
        actionButtonSuccess,
        hidden,
        actionButtonError,
        containerSplit,
    } = classes;

    const {
        loading,
    } = useQuery(ServiceQuery.GET_SERVICE_OPERATION_CODE_MAIN_LIST, {
        variables: {
            paginate: {
                init: paginate.init,
                limit: paginate.limit,
                ignoreLimit: false,
            },
            sort: {
                fieldName: 'description',
                dir: DataSort.ASC,
            },
            filter: {
                search: searchParam,
            },
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        onCompleted: (queryData) => {
            const currentRecords = [...records];

            // merge the data
            currentRecords.push(...queryData?.getOperationCodes?.data || []);

            dispatchChangeState({
                totalCount: queryData?.getOperationCodes?.totalCount || 0,
                records: currentRecords,
            });
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(errorMessage.graphQLErrors);
        },
    });

    const [deleteOpCode, { loading: isDeleting }] = useMutation(ServiceMutation.DELETE_OP_CODE, {
        onCompleted: (mutationData) => {
            if (mutationData?.deleteOperationCode) {
                ModalUtils.successMessage(null, 'Delete successfully!');
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const loadMore = () => {
        const currentOffset = records?.length || 0;

        dispatchChangeState({
            paginate: {
                ...paginate,
                init: currentOffset,
            },
        });
    };

    const onSearch = (val) => {
        dispatchChangeState({
            searchParam: val,
            paginate: {
                ...paginate,
                init: 0,
            },
            records: [],
            totalCount: 0,
        });
    };

    const onCloseDeleteConfirm = () => {
        dispatchChangeState({
            idToDelete: 0,
        });
    };

    const onDeleteConfirm = () => {
        deleteOpCode({
            variables: {
                id: idToDelete,
            },
        });

        onCloseDeleteConfirm();
    };

    useSubscription(ServiceSubscription.OP_CODE_SUBSCRIPTION, {
        onSubscriptionData: ({ subscriptionData }) => {
            const { action, data: subsData, id } = subscriptionData?.data?.opCodeChanged;
            if (action === SubscriptionActionType.ADDED) {
                const currentRecords = [...records];
                const currentData = JSON.parse(subsData);

                currentRecords.unshift({ ...currentData });

                dispatchChangeState({
                    totalCount: totalCount + 1,
                    records: currentRecords,
                });
            } else if (action === SubscriptionActionType.UPDATED) {
                const currentRecords = [...records];
                const currentData = JSON.parse(subsData);

                const currentIndex = currentRecords.findIndex((item) => Number(item.opCodeId) === Number(id));

                if (currentIndex >= 0) {
                    currentRecords.splice(currentIndex, 1);
                    currentRecords.splice(currentIndex, 0, { ...currentData });

                    dispatchChangeState({
                        totalCount,
                        records: currentRecords,
                    });
                }
            } else if (action === SubscriptionActionType.DELETED) {
                const currentRecords = [...records];
                const currentIndex = currentRecords.findIndex((item) => Number(item.opCodeId) === Number(id));

                if (currentIndex >= 0) {
                    currentRecords.splice(currentIndex, 1);

                    dispatchChangeState({
                        totalCount: totalCount - 1,
                        records: currentRecords,
                    });
                }
            }
        },
    });

    const columns = [
        {
            minWidth: 30,
            width: 30,
            headerClassName: clsx(columnHeaderStyle, columnLeft),
            className: clsx(columnStyle, columnLeft),
            Cell: (cell) => cell.viewIndex + 1,
        },
        {
            Header: 'Op Code',
            minWidth: 200,
            width: 200,
            accessor: 'opCode',
            headerClassName: clsx(columnHeaderStyle, columnLeft),
            className: clsx(columnStyle, columnLeft),
        },
        {
            Header: 'Description',
            minWidth: 200,
            width: 200,
            accessor: 'description',
            headerClassName: clsx(columnHeaderStyle, columnLeft),
            className: clsx(columnStyle, columnLeft),
        },
        {
            Header: 'Customer State',
            minWidth: 200,
            width: 200,
            accessor: 'customerState',
            headerClassName: clsx(columnHeaderStyle, columnLeft),
            className: clsx(columnStyle, columnLeft),
        },
        {
            Header: 'Cause',
            minWidth: 200,
            width: 200,
            accessor: 'cause',
            headerClassName: clsx(columnHeaderStyle, columnLeft),
            className: clsx(columnStyle, columnLeft),
        },
        {
            Header: 'Correction',
            minWidth: 200,
            width: 200,
            accessor: 'correction',
            headerClassName: clsx(columnHeaderStyle, columnLeft),
            className: clsx(columnStyle, columnLeft),
        },
        {
            Header: 'Note',
            minWidth: 200,
            accessor: 'note',
            headerClassName: clsx(columnHeaderStyle, columnLeft),
            className: clsx(columnStyle, columnLeft),
        },
        {
            Header: 'Hours',
            minWidth: 80,
            width: 80,
            accessor: 'hours',
            headerClassName: clsx(columnHeaderStyle, columnRight),
            className: clsx(columnStyle, columnRight),
            Cell: ({ value }) => value,
        },
        {
            Header: 'Labor Amount',
            minWidth: 100,
            width: 100,
            accessor: 'laborAmount',
            headerClassName: clsx(columnHeaderStyle, columnRight),
            className: clsx(columnStyle, columnRight),
            Cell: ({ value }) => NumberUtils.applyCurrencyFormat(value),
        },
        {
            Header: 'Actions',
            width: isMobile ? 120 : 'auto',
            headerClassName: clsx(columnHeaderStyle, columnCenter, isMobile ? '' : hidden),
            className: clsx(columnStyle, columnCenter, isMobile ? '' : hidden, isMobile ? '' : 'actionColumnTarget'),
            Cell: (cellData) => {
                const {
                    original: {
                        opCodeId,
                    },
                } = cellData;

                return (
                    <div className={buttonSpacing}>

                        {(OPERATION_CODE_EDIT || OPERATION_CODE_READ) && (
                            <Button
                                onClick={() => history.push(`/${modules.SERVICE}/${ServiceSubModules.OPERATION_CODES}/edit/${opCodeId}`)}
                                variant="outlined"
                                startIcon={<EditOutlinedIcon className={actionButtonSuccess} />}
                                size="small"
                            >
                                { isMobile ? '' : 'Edit' }
                            </Button>
                        )}
                        {OPERATION_CODE_DELETE && (
                            <Button
                                onClick={() => dispatchChangeState({
                                    idToDelete: opCodeId,
                                })}
                                variant="outlined"
                                startIcon={<BlockOutlinedIcon className={actionButtonError} />}
                                size="small"
                            >
                                { isMobile ? '' : 'Delete' }
                            </Button>
                        )}
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <div className={main}>
                <Header>
                    <div className={clsx(containerFilterSearch, containerFilterSearchCustom)}>
                        <InputSearch
                            customClasses={clsx(search, isMobile ? searchFull : '')}
                            initialSearch={searchParam || ''}
                            onSearch={onSearch}
                        />
                    </div>
                    {OPERATION_CODE_ADD && (
                        <div className={clsx(buttonSpacing, justifyContentCenter)}>
                            <Tooltip title="New Op Code" placement="top-start">
                                <span>
                                    <Button
                                        onClick={() => history.push(`/${modules.SERVICE}/${ServiceSubModules.OPERATION_CODES}/create`)}
                                        variant="outlined"
                                        startIcon={<AddIcon />}
                                        disabled={loading}
                                        size="small"
                                    >
                                        New Op Code
                                    </Button>
                                </span>
                            </Tooltip>
                        </div>
                    )}
                </Header>
                <Container className={containerSplit}>
                    <Table
                        data={records}
                        columns={columns}
                        cursor="default"
                        load={loading}
                        totalRecords={totalCount}
                        loadMore={loadMore}
                        rowSelected
                        className="-highlight actionsInLine"
                    />
                </Container>
            </div>
            {idToDelete && (
                <ConfirmDialog
                    title="Confirm delete record"
                    description="Are you sure you want to delete this Op Code?"
                    open
                    variant="outlined"
                    titlePrimary="Yes"
                    titleSecondary="Cancel"
                    onClose={onCloseDeleteConfirm}
                    onClickSecondary={onCloseDeleteConfirm}
                    onClickPrimary={onDeleteConfirm}
                />
            )}
            {isDeleting && <DialogActionMessage message="Deleting Op Code... " />}
        </>
    );
};

export default OperationCodeMain;

import React, { Component } from 'react';

/* Helper Libraries */
import NumberUtils from 'lib/NumberUtils';
import Dropdown from 'components/widgets/dropdown';
import { BlockOutlinedIcon, SwapHorizIcon } from 'components/icons';

// Material UI
import {
    Typography, Divider, Grid, Tooltip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DealItemsContainer from 'components/containers/deals/list/DealItemsContainer';
import { modules } from 'utils/enum/modules';

// Components and others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import CheckIcon from '@material-ui/icons/Check';
import { TransferStatus } from 'utils/enum/DealEnum';
import VehicleWidget from 'components/widgets/VehicleWidget';
import AvatarWithLabel from 'components/widgets/AvatarWithLabel';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import DownPaymentReceived from 'components/widgets/deals/DownPaymentReceived';
import QuickNoteEditable from 'components/modules/deals/list/QuickNoteEditable';
import DateUtils from 'lib/DateUtils';
import CustomStockWrapper from 'components/modules/deals/read/CustomStockWrapper';
import { Link as RouterLink, withRouter } from 'react-router-dom';
// TODO: Remove the dependency of the ExpansionPanelStyles
import ExpansionPanelStyles from 'styles/widgets/ExpansionPanelStyles';

// TODO: Find a way to join styles
const styles = (theme) => ({
    itemContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        paddingLeft: '2px',
        cursor: 'default',
    },
    dividerPrimary: {
        backgroundColor: theme.palette.primary.main,
        marginLeft: theme.spacing(-1),
        marginRight: theme.spacing(-1),
    },
    textLabelAlign: {
        textAlign: 'center',
    },
    labelStatusDeal: {
        minWidth: 86,
        textAlign: 'end',
        marginRight: theme.spacing(1),
    },
    tag: {
        padding: '2px 5px',
        borderRadius: '2px',
        fontWeight: 400,
        whiteSpace: 'nowrap',
    },
    lotTag: {
        color: '#1e88e5',
        background: '#F4F5FD',
    },
    dealType: {
        color: '#388e3c',
        background: '#e8f5e9',
    },
    small: {
        width: '24px',
        height: '24px',
        border: `1px solid ${theme.palette.border.dustyGray}`,
        backgroundColor: theme.palette.background.silver,
        marginRight: theme.spacing(1.5),
    },
    boxAvatarWithIcon: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(-0.7),
    },
    containerAvatarWithName: {
        paddingTop: theme.spacing(1),
    },
    checkedGreen: {
        color: theme.palette.success.main,
        position: 'absolute',
        top: 0,
        right: '-20px',
    },
    checkedLabel: {
        display: 'flex',
        marginBottom: 0,
        minHeight: '24px',
        alignItems: 'center',
        marginTop: theme.spacing(-0.7),
    },
    columnContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    columnExtended: {
        'justify-content': 'space-between',
        height: '100%',
        'margin-bottom': '5px',
    },
    marginAuto: {
        margin: 'auto',
    },
    textSoldDate: {
        color: theme.palette.text.boulderGray,
        fontWeight: 500,
        fontFamily: 'Roboto',
        fontSize: '13px',
    },
    boldColor: {
        color: theme.palette.text.minsk,
    },
    textBoulder: {
        color: theme.palette.text.boulder,
    },
    textAvatar: {
        color: theme.palette.text.scorpion,
        fontWeight: 400,
        fontFamily: 'Arial',
    },
    profitColumn: {
        maxWidth: '90px !important',
        minWidth: '90px !important',
    },
    operationColumn: {
        '& > h6:first-child': {
            marginTop: '10px',
        },
    },
    iconButton: {
        padding: '0px',
        margin: '0px',
    },
    iconMoreSize: {
        height: '22px',
    },
    iconItemMenu: {
        paddingRight: '6px',
        color: '#605A84',
    },
    tooltip: {
        margin: 0,
    },
    salesmanColumn: {
        flexDirection: 'column',
        maxWidth: 180,
        minWidth: 180,
    },
    actionsColumn: {
        maxWidth: '260px !important',
        minWidth: '260px !important',
    },
    dealStatusColumn: {
        maxWidth: '160px !important',
        minWidth: '160px !important',
        marginRight: '10px',
        '&>div': {
            display: 'block',
            position: 'relative',
        },
    },
    moreActionsColumn: {
        position: 'relative',
    },
    tagAgencyDate: {
        position: 'absolute',
        bottom: 0,
    },
    vehicleWidget: {
        textDecoration: 'initial',
        cursor: 'pointer',
    },
    quickNote: {
        cursor: 'pointer',
    },
    rejectedIcon: {
        color: theme.palette.error.main,
        position: 'absolute',
        top: 0,
        right: '-20px',
    },
    sentIcon: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        top: 0,
        right: '-20px',
    },
    ...ExpansionPanelStyles.expandRow(theme),
});

class DealItems extends Component {
    renderLabelWithChecked(label = '', checked = false, labelClass = '') {
        const { props: { classes } } = this;

        return (
            <div className={`${classes.checkedLabel} ${labelClass}`}>
                <Typography variant="h6" className={`${classes.boldColor} ${classes.labelStatusDeal}`}>{label}</Typography>
                {checked && <CheckIcon className={classes.checkedGreen} fontSize="small" />}
            </div>
        );
    }

    renderTransferLabel(status) {
        const { props: { classes } } = this;
        const accepted = status?.toUpperCase() === TransferStatus.ACCEPTED.toUpperCase();
        const rejected = status?.toUpperCase() === TransferStatus.REJECTED.toUpperCase();
        const sent = status?.toUpperCase() === TransferStatus.SENT.toUpperCase();

        return (
            <div className={`${classes.checkedLabel}`}>
                <Typography variant="h6" className={`${classes.boldColor} ${classes.labelStatusDeal}`}>RFC</Typography>
                {accepted && <CheckIcon className={classes.checkedGreen} fontSize="small" />}
                {rejected && <BlockOutlinedIcon className={classes.rejectedIcon} fontSize="small" />}
                {sent && <SwapHorizIcon className={classes.sentIcon} fontSize="small" />}
            </div>
        );
    }

    renderMoreActions() {
        const {
            props: {
                getActions, handleClick, secureCloseEnabled,
            },
        } = this;
        const actions = getActions(secureCloseEnabled);

        return actions.length > 0 && <Dropdown pullRight actions={actions} onSelect={handleClick} />;
    }

    renderConfirmationDialogToDelete() {
        const {
            props: {
                openDeleteConfirm,
                onCloseDeleteConfirm,
                onDeleteDeal,
                stageId,
                record: {
                    accountNumber,
                },
            },
        } = this;

        return (
            <ConfirmDialog
                title="Confirm delete deal"
                description={`You are about to delete a deal with Acct # = ${accountNumber}`}
                open={openDeleteConfirm}
                showHeader
                titlePrimary="Yes"
                titleSecondary="No"
                variant="outlined"
                dividerFooter={false}
                onClickPrimary={() => {
                    onDeleteDeal(stageId);
                    onCloseDeleteConfirm();
                }}
                onClose={onCloseDeleteConfirm}
                onClickSecondary={onCloseDeleteConfirm}
            />
        );
    }

    renderConfirmationDialogAdminToDelete() {
        const {
            props: {
                openDeleteAdminConfirm,
                onCloseDeleteConfirm,
                onDeleteDeal,
                stageId,
                adminDeleteMessage,
            },
        } = this;

        return (
            <ConfirmDialog
                title="Confirm delete deal"
                description={adminDeleteMessage}
                open={openDeleteAdminConfirm}
                showHeader
                titlePrimary="Yes"
                titleSecondary="No"
                variant="outlined"
                dividerFooter={false}
                onClickPrimary={() => {
                    onDeleteDeal(stageId, true);
                    onCloseDeleteConfirm();
                }}
                onClose={onCloseDeleteConfirm}
                onClickSecondary={onCloseDeleteConfirm}
            />
        );
    }

    // TODO: The information is added based on the payload that is sent in leads
    render() {
        const {
            props: {
                classes,
                record,
                record: {
                    buyer,
                    vehicle,
                    accountNumber,
                    coBuyer,
                },
            },
        } = this;
        const numberUnderVehicleimage = accountNumber || '';
        let frontRow = '';
        let secondRow = '';
        let thirdRow = '';
        let fourthRow = '';

        const {
            year, make, model, trim, stockNumber, purchasedDate,
            customStockNumber,
        } = vehicle ?? {};

        if (vehicle) {
            const vehicleAge = DateUtils.diff(record.soldDate, purchasedDate);
            thirdRow = `${year || ''} - ${make || ''}`;
            fourthRow = `${model || ''} - ${trim || ''} - Age: ${vehicleAge || 0}`;
        }

        if (buyer) {
            const { firstName, lastName } = buyer;

            frontRow = `${firstName || ''} ${lastName || ''}`;
        }

        if (coBuyer) {
            const { firstName, lastName } = coBuyer;

            secondRow = `${firstName || ''} ${lastName || ''}`;
        }

        return (
            <div className={clsx(classes.itemContainer, 'show-icon-when-hovering')}>
                <RouterLink to={`/${modules.DEALS}/${record.accountNumber}`} className={classes.vehicleWidget}>
                    <VehicleWidget
                        frontRow={frontRow}
                        secondRow={secondRow}
                        thirdRow={thirdRow}
                        thirdRowSuffix={(
                            <CustomStockWrapper
                                record={{ stockNumber, customStockNumber }}
                            />
                        )}
                        fourthRow={fourthRow}
                        image={vehicle?.thumbnail}
                        bodyStyle={vehicle?.style}
                        labelUnderVehicleImage="Deal"
                        numberUnderVehicleimage={numberUnderVehicleimage}
                    />
                </RouterLink>
                <div className={`${classes.expansionCol} ${classes.colProspect}`}>
                    <div className={`${classes.columnContainer} ${classes.columnExtended}`}>
                        <div className={classes.textLabelAlign}>
                            <Typography variant="h6" className={`${classes.textSoldDate} ${classes.textBoulder}`}>Sold Date</Typography>
                            <Typography variant="h6" className={classes.boldColor}>{DateUtils.getOnlyDate(record.soldDate)}</Typography>
                            <Typography variant="h6" className={classes.boldColor}>
                                <DownPaymentReceived dealId={record.accountNumber} lotName={record.lotName} cashDownPayment={record.cashDownPayment} />
                            </Typography>
                        </div>
                        <Typography variant="body1"><span className={`${classes.tag} ${classes.lotTag}`}>{record.lotName}</span></Typography>
                    </div>
                </div>
                {/* TODO: this column is necesary for the layout. The content is not added yet */}
                <div className={`${classes.expansionCol} ${classes.colProspect} ${classes.quickNote}`}>
                    <QuickNoteEditable dealId={record.accountNumber} note={record.notes} />
                </div>
                <div className={`${classes.expansionCol} ${classes.colProspect}`}>
                    <div className={`${classes.columnContainer} ${classes.columnExtended}`}>
                        <div className={classes.textLabelAlign}>
                            <Typography variant="h6" className={`${classes.textSoldDate} ${classes.textBoulder}`}>Finance Company</Typography>
                            <Typography variant="h6" className={`${classes.boldColor} ${classes.textLabelAlign}`}>{record.financeCompany || ''}</Typography>
                        </div>
                        <Typography variant="body1"><span className={`${classes.tag} ${classes.dealType}`}>{record.dealType}</span></Typography>
                    </div>
                </div>
                <div className={`${classes.expansionCol} ${classes.colProspect} ${classes.profitColumn}`}>
                    <div className={`${classes.operationColumn} ${classes.marginAuto}`}>
                        <Tooltip title="Frontend profit" placement="right">
                            <Typography
                                className={`${classes.boldColor} ${classes.textLabelAlign}`}
                                variant="h6"
                                color="primary"
                            >
                                {NumberUtils.applyCurrencyFormat(record.frontEndProfit)}
                            </Typography>
                        </Tooltip>
                        <Tooltip title="Backend profit" placement="right">
                            <Typography
                                className={`${classes.boldColor} ${classes.textLabelAlign}`}
                                variant="h6"
                                color="primary"
                            >
                                {NumberUtils.applyCurrencyFormat(record.backEndProfit)}
                            </Typography>
                        </Tooltip>
                        <Divider className={classes.dividerPrimary} />
                        <Tooltip title="Total profit" placement="right">
                            <Typography
                                className={`${classes.boldColor} ${classes.textLabelAlign}`}
                                variant="h6"
                                color="primary"
                            >
                                {NumberUtils.applyCurrencyFormat(record.totalProfit)}
                            </Typography>
                        </Tooltip>
                    </div>
                </div>
                <div className={`${classes.expansionCol} ${classes.salesmanColumn} ${classes.containerAvatarWithName}`}>
                    <AvatarWithLabel person={record.salesPerson1} />
                    <AvatarWithLabel person={record.salesPerson2} />
                    <AvatarWithLabel person={record.financeManager} />
                    <AvatarWithLabel person={record.salesManager} />
                    <AvatarWithLabel person={record.bdcAgent} />
                </div>
                <div className={`${classes.expansionCol} ${classes.colProspect} ${classes.containerAvatarWithName} ${classes.actionsColumn}`}>
                    <Grid className={classes.gridRow} container>
                        <Grid item xs={12} className={classes.dealStatusColumn}>
                            {this.renderLabelWithChecked(record.status)}
                            {this.renderLabelWithChecked(record.dealFunded)}
                            { /* TODO: this is not completed yet */}
                            {this.renderTransferLabel(record.transferStatus)}
                            {this.renderLabelWithChecked('Posted', (record.postedDate !== null))}
                            {record.sentToTagAgencyOn !== null ? this.renderLabelWithChecked('Sent To Tag Agency') : null}
                        </Grid>
                        <Grid className={`with-icon-right ${classes.moreActionsColumn}`} container justify="center" alignItems="center" item xs={2}>
                            {this.renderMoreActions()}
                            {record.sentToTagAgencyOn !== null
                                ? this.renderLabelWithChecked(DateUtils.format(record.sentToTagAgencyOn), false, classes.tagAgencyDate)
                                : null}
                            { this.renderConfirmationDialogToDelete() }
                            { this.renderConfirmationDialogAdminToDelete() }
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
}

DealItems.propTypes = {
    record: PropTypes.oneOfType([PropTypes.object]).isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    handleClick: PropTypes.func.isRequired,
    getActions: PropTypes.func.isRequired,
    onDeleteDeal: PropTypes.func.isRequired,
    onCloseDeleteConfirm: PropTypes.func.isRequired,
    openDeleteConfirm: PropTypes.bool,
    openDeleteAdminConfirm: PropTypes.bool,
    stageId: PropTypes.string,
    secureCloseEnabled: PropTypes.bool.isRequired,
    adminDeleteMessage: PropTypes.string,
};

DealItems.defaultProps = {
    openDeleteConfirm: false,
    openDeleteAdminConfirm: false,
    stageId: null,
    adminDeleteMessage: null,
};

export default withRouter(withStyles(styles)(DealItemsContainer(DealItems)));

import React, { Component } from 'react';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Form, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import InputSSN from 'components/widgets/form/InputSSN';
import PhoneInput from 'components/widgets/form/PhoneInput';
import InputNumber from 'components/widgets/InputNumber';
import NumberFormat from 'react-number-format';
import { withRouter } from 'react-router-dom';
import Header from 'components/widgets/Header';
import Select from 'components/widgets/Select';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import Container from 'components/widgets/Container';
import {
    Button, Grid, Typography,
    Divider,
} from '@material-ui/core';
import ButtonStyles from 'styles/theme/Button';
import StringUtils from 'lib/StringUtils';
import CountryUtils from 'utils/CountryUtils';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import { CustomerSchema } from 'utils/schema/customer/Customer';
import { DefaultAddressSchema } from 'utils/schema/customer/Address';
import CreateCustomerContainer from 'components/containers/deals/create/dealTab/CreateCustomerContainer';
import { withStyles } from '@material-ui/core/styles';
import SuggestAddress from 'components/widgets/customer/SuggestAddress';

const styles = (theme) => ({
    container: {
        background: theme.palette.background.white,
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: theme.spacing(3),
        overflow: 'auto',
    },
    generalInfo: {
        marginBottom: theme.spacing(2),
        overflow: 'auto',
        padding: '15px',
    },
    divider: {
        height: '100%',
    },
    title: {
        color: '#727082',
        fontSize: '16px',
        paddingLeft: '10px',
        paddingBottom: '14px',
        fontWeight: 700,
    },
    businessCheckBox: {
        marginBottom: theme.spacing(2),
        marginLeft: '2px',
    },
    businessCheckLabel: {
        fontSize: '16px !important',
    },
    button: {
        marginRight: theme.spacing(2),
        minWidth: 100,
    },
    ...ButtonStyles.getStyle(theme),
});

const housingStatusOptions = [
    {
        value: 'Mortgage',
        label: 'Mortgage',
    },
    {
        value: 'Rent',
        label: 'Rent',
    },
    {
        value: 'Family',
        label: 'Family',
    },
    {
        value: 'Other',
        label: 'Other',
    },
    {
        value: 'OwnOutright',
        label: 'OwnOutright',
    },
    {
        value: 'Military',
        label: 'Military',
    },
];

class CreateCustomer extends Component {
    handleKeyPress(e, section) {
        const { props: { decodeZip } } = this;
        const currentValue = e.target.value;

        if ((e.keyCode === 13 || e.keyCode === 9) && !StringUtils.isEmpty(currentValue)) {
            decodeZip(currentValue, section);
        }
    }

    renderGeneralInfoForm(record, schemaValidations) {
        const { props: { classes, onChangeValue } } = this;
        const {
            firstName, middleName, lastName, dob,
            ssn, dln, email, cellPhone,
            workPhone, isBusiness, isTaxable,
        } = record;
        const { errors } = schemaValidations;

        return (
            <>
                <Grid className={clsx(classes.gridRow, classes.businessCheckBox)} container spacing={2}>
                    <Form.Check type="checkbox" id="businessDeals">
                        <Form.Check.Input
                            type="checkbox"
                            checked={isBusiness}
                            onChange={(e) => onChangeValue('isBusiness', e.target.checked)}
                        />
                        <Form.Check.Label className={classes.businessCheckLabel}>Business</Form.Check.Label>
                    </Form.Check>
                </Grid>
                <Grid className={classes.gridRow} container spacing={2}>
                    <Grid item xs={isBusiness ? 6 : 2}>
                        <Form.Label className="required">{isBusiness ? 'Business Name' : 'First Name'}</Form.Label>
                        <Form.Control
                            type="text"
                            size="sm"
                            value={firstName}
                            maxLength={70}
                            onChange={(e) => onChangeValue('firstName', e.target.value)}
                            className={isValidField(errors, 'firstName') ? 'invalid-field' : ''}
                        />
                    </Grid>
                    {!isBusiness && (
                        <>
                            <Grid item xs={2}>
                                <Form.Label>Middle Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    size="sm"
                                    value={middleName}
                                    maxLength={70}
                                    onChange={(e) => onChangeValue('middleName', e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Form.Label className="required">Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    size="sm"
                                    value={lastName}
                                    maxLength={70}
                                    onChange={(e) => onChangeValue('lastName', e.target.value)}
                                    className={!isBusiness && isValidField(errors, 'lastName') ? 'invalid-field' : ''}
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={2} className="full-width-date-picker">
                        <Form.Label className={classes.datePicker}>DOB</Form.Label>
                        <DatePicker
                            className={clsx('form-control', 'form-control-sm')}
                            selected={dob}
                            placeholderText="mm/dd/yyyy"
                            maxDate={new Date()}
                            onChange={(date) => onChangeValue('dob', date)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Form.Label>SSN</Form.Label>
                        <InputSSN
                            size="sm"
                            value={ssn}
                            onChange={(value) => onChangeValue('ssn', value)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Form.Label>DLN</Form.Label>
                        <Form.Control
                            type="text"
                            size="sm"
                            value={dln}
                            maxLength={20}
                            onChange={(e) => onChangeValue('dln', e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="text"
                            size="sm"
                            value={email || ''}
                            maxLength={100}
                            onChange={(e) => onChangeValue('email', e.target.value)}
                            className={isValidField(errors, 'email') ? 'invalid-field' : ''}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Form.Label>Cell Phone</Form.Label>
                        <PhoneInput
                            size="sm"
                            value={cellPhone}
                            onChange={(value) => onChangeValue('cellPhone', value)}
                            className={isValidField(errors, 'cellPhone') ? 'invalid-field' : ''}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Form.Label>Work Phone</Form.Label>
                        <PhoneInput
                            size="sm"
                            value={workPhone}
                            onChange={(value) => onChangeValue('workPhone', value)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Form.Check type="checkbox" id="isTaxableId">
                            <Form.Check.Input
                                type="checkbox"
                                checked={isTaxable}
                                onChange={(e) => onChangeValue('isTaxable', e.target.checked)}
                            />
                            <Form.Check.Label className={classes.businessCheckLabel}>Is Taxable</Form.Check.Label>
                        </Form.Check>
                    </Grid>
                </Grid>
            </>
        );
    }

    renderAddressForm(record, schemaValidations) {
        const {
            props: {
                classes, onChangeValue, onChangeCity, isDecodingZip, onEnableDecoder,
            },
        } = this;
        const {
            address1, address2, city, state,
            county, zipCode, country, start, mortgageOrRent,
            housingStatus, phone, listCity,
        } = record;
        const { errors, fields } = schemaValidations;

        return (
            <>
                <Typography className={classes.title}><span>Current Address</span></Typography>
                <Grid container className="am-form">
                    <Grid item xs={6}>
                        <Form.Group as={Col}>
                            <Form.Label className={clsx({ required: fields.address1.isRequired })}>Address Line 1</Form.Label>
                            <Form.Control
                                size="sm"
                                type="text"
                                value={address1}
                                onChange={(e) => onChangeValue('address1', e.target.value, 'address')}
                                className={isValidField(errors, 'address1') ? 'invalid-field' : ''}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Address Line 2</Form.Label>
                            <Form.Control
                                type="text"
                                size="sm"
                                value={address2}
                                onChange={(e) => onChangeValue('address2', e.target.value, 'address')}
                            />
                        </Form.Group>
                        <Form.Group as={Col} className="remove-mb-form-group">
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label className={clsx({ required: fields.zipCode.isRequired })}>Zip Code</Form.Label>
                                    <NumberFormat
                                        disabled={isDecodingZip}
                                        value={zipCode}
                                        format="#####"
                                        size="sm"
                                        onFocus={onEnableDecoder}
                                        onBlur={(e) => this.handleKeyPress(e, 'address')}
                                        onKeyDown={(e) => this.handleKeyPress(e, 'address')}
                                        onValueChange={({ value }) => onChangeValue('zipCode', value, 'address')}
                                        className={clsx('form-control', 'form-control-sm', isValidField(errors, 'zipCode') ? 'invalid-field' : '')}
                                    />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label className={clsx({ required: fields.city.isRequired })}>City</Form.Label>
                                    <Select
                                        name="city"
                                        size="sm"
                                        allowToCreate
                                        value={city}
                                        options={listCity}
                                        placeholder="Select"
                                        maxMenuHeight={170}
                                        onChange={(name, value) => {
                                            onChangeCity(name, value, 'address');
                                        }}
                                        className={isValidField(errors, 'city') ? 'invalid-field' : ''}
                                    />
                                </Form.Group>
                            </Form.Row>
                        </Form.Group>
                        <Form.Group as={Col} className="remove-mb-form-group">
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>County</Form.Label>
                                    <Form.Control
                                        disabled
                                        size="sm"
                                        type="text"
                                        value={county}
                                        onChange={(e) => onChangeValue('county', e.target.value, 'address')}
                                    />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>State</Form.Label>
                                    <Form.Control
                                        disabled
                                        size="sm"
                                        type="text"
                                        value={state}
                                        onChange={(e) => onChangeValue('state', e.target.value, 'address')}
                                        className={isValidField(errors, 'state') ? 'invalid-field' : ''}
                                    />
                                </Form.Group>
                            </Form.Row>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Country</Form.Label>
                            <Select
                                name="country"
                                size="sm"
                                value={country}
                                placeholder="Select"
                                options={CountryUtils.getCountry()}
                                maxMenuHeight={170}
                                onChange={(name, value) => {
                                    onChangeValue(name, value, 'address');
                                }}
                                className={isValidField(errors, 'country') ? 'invalid-field' : ''}
                            />
                        </Form.Group>
                    </Grid>
                    <Grid item xs={6}>
                        <Form.Group as={Col}>
                            <Form.Label>Phone Number</Form.Label>
                            <PhoneInput
                                size="sm"
                                value={phone}
                                onChange={(value) => onChangeValue('phone', value, 'address')}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Housing Status</Form.Label>
                            <Select
                                name="housingStatus"
                                size="sm"
                                value={housingStatus}
                                placeholder="Select"
                                options={housingStatusOptions}
                                maxMenuHeight={170}
                                onChange={(name, value) => {
                                    onChangeValue(name, value, 'address');
                                }}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Rent/Morgage</Form.Label>
                            <InputNumber
                                value={mortgageOrRent || ''}
                                showCurrency
                                size="sm"
                                thousandSeparator
                                onChange={(value) => onChangeValue('mortgageOrRent', value, 'address')}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Row>
                                <Form.Group as={Col} className="full-width-date-picker">
                                    <Form.Label>Since</Form.Label>
                                    <DatePicker
                                        size="sm"
                                        className={clsx('form-control', 'form-control-sm')}
                                        selected={start}
                                        placeholderText="mm/dd/yyyy"
                                        popperContainer={CalendarContainer}
                                        onChange={(date) => onChangeValue('start', date, 'address')}
                                        maxDate={new Date()}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} />
                            </Form.Row>
                        </Form.Group>
                    </Grid>
                </Grid>
            </>
        );
    }

    renderEmploymentForm(record) {
        const {
            props: {
                onChangeValue, onChangeCity, classes, isDecodingZip, onEnableDecoder,
            },
        } = this;
        const {
            employer, title, workPhone, monthlyIncome,
            otherIncomeMonthly, otherIncomeSource, start,
            address1, address2, zipCode, city, county,
            state, country, listCity,
        } = record;

        return (
            <>
                <Typography className={classes.title}><span>Current Employment</span></Typography>
                <Grid container className="am-form">
                    <Grid item xs={6}>
                        <Form.Group as={Col}>
                            <Form.Label>Company</Form.Label>
                            <Form.Control
                                type="text"
                                size="sm"
                                value={employer}
                                onChange={(e) => onChangeValue('employer', e.target.value, 'employment')}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Job Title</Form.Label>
                            <Form.Control
                                type="text"
                                size="sm"
                                value={title}
                                onChange={(e) => onChangeValue('title', e.target.value, 'employment')}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Work Phone</Form.Label>
                            <PhoneInput
                                size="sm"
                                value={workPhone}
                                onChange={(value) => onChangeValue('workPhone', value, 'employment')}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Monthly Income</Form.Label>
                            <InputNumber
                                size="sm"
                                showCurrency
                                value={monthlyIncome}
                                thousandSeparator
                                onChange={(value) => onChangeValue('monthlyIncome', value, 'employment')}
                            />
                        </Form.Group>
                        <Form.Group as={Col} className="remove-mb-form-group">
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Other Income</Form.Label>
                                    <InputNumber
                                        showCurrency
                                        size="sm"
                                        value={otherIncomeMonthly}
                                        thousandSeparator
                                        onChange={(value) => onChangeValue('otherIncomeMonthly', value, 'employment')}
                                    />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label className="blank-label" />
                                    <Form.Control
                                        type="text"
                                        size="sm"
                                        value={otherIncomeSource}
                                        placeholder="Source"
                                        onChange={(e) => onChangeValue('otherIncomeSource', e.target.value, 'employment')}
                                    />
                                </Form.Group>
                            </Form.Row>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Row>
                                <Form.Group as={Col} className="full-width-date-picker">
                                    <Form.Label>Since</Form.Label>
                                    <DatePicker
                                        className={clsx('form-control', 'form-control-sm')}
                                        selected={start}
                                        placeholderText="mm/dd/yyyy"
                                        popperContainer={CalendarContainer}
                                        onChange={(date) => onChangeValue('start', date, 'employment')}
                                        maxDate={new Date()}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} />
                            </Form.Row>
                        </Form.Group>
                    </Grid>
                    <Grid item xs={6}>
                        <Form.Group as={Col}>
                            <Form.Label>Address Line 1</Form.Label>
                            <Form.Control
                                type="text"
                                size="sm"
                                value={address1}
                                onChange={(e) => onChangeValue('address1', e.target.value, 'employment')}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Address Line 2</Form.Label>
                            <Form.Control
                                type="text"
                                size="sm"
                                value={address2}
                                onChange={(e) => onChangeValue('address2', e.target.value, 'employment')}
                            />
                        </Form.Group>
                        <Form.Group as={Col} className="remove-mb-form-group">
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Zip Code</Form.Label>
                                    <NumberFormat
                                        disabled={isDecodingZip}
                                        value={zipCode}
                                        format="#####"
                                        size="sm"
                                        onFocus={onEnableDecoder}
                                        onBlur={(e) => this.handleKeyPress(e, 'employment')}
                                        onKeyDown={(e) => this.handleKeyPress(e, 'employment')}
                                        onValueChange={({ value }) => onChangeValue('zipCode', value, 'employment')}
                                        className={clsx('form-control', 'form-control-sm')}
                                    />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>City</Form.Label>
                                    <Select
                                        name="city"
                                        size="sm"
                                        allowToCreate
                                        value={city}
                                        options={listCity}
                                        placeholder="Select"
                                        maxMenuHeight={170}
                                        onChange={(name, value) => {
                                            onChangeCity(name, value, 'employment');
                                        }}
                                    />
                                </Form.Group>
                            </Form.Row>
                        </Form.Group>
                        <Form.Group as={Col} className="remove-mb-form-group">
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>County</Form.Label>
                                    <Form.Control
                                        disabled
                                        type="text"
                                        size="sm"
                                        value={county}
                                    />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>State</Form.Label>
                                    <Form.Control
                                        disabled
                                        type="text"
                                        size="sm"
                                        value={state}
                                    />
                                </Form.Group>
                            </Form.Row>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Country</Form.Label>
                            <Select
                                name="country"
                                value={country}
                                placeholder="Select"
                                size="sm"
                                options={CountryUtils.getCountry()}
                                maxMenuHeight={140}
                                onChange={(name, value) => {
                                    onChangeValue(name, value, 'employment');
                                }}
                            />
                        </Form.Group>
                    </Grid>
                </Grid>
            </>
        );
    }

    render() {
        const {
            props: {
                classes, toggleCreateCustomer, record, suggestedAddress, validateAddress, loadingSuggestedAddress,
                addressSchema, openModalSuggestedAddress, onChangeAddress, toggleAddressSuggest,
            },
        } = this;
        const { generalInfo, address, employment } = record;
        const customerValidationData = isValidSchema(CustomerSchema, generalInfo);
        const addressValidationData = isValidSchema(addressSchema, address);
        const { isValid: customerIsValid } = customerValidationData;
        const { isValid: addressIsValid } = addressValidationData;

        return (
            <Container className="floatBox">
                <Header>
                    <div className="d-flex-justify-center-align-center">
                        <Button
                            disabled={!customerIsValid || !addressIsValid || loadingSuggestedAddress}
                            variant="contained"
                            className={clsx(classes.button, classes.containedInfo)}
                            onClick={validateAddress}
                        >
                            Save
                        </Button>
                        <Button
                            variant="outlined"
                            className={clsx(classes.button)}
                            onClick={toggleCreateCustomer}
                        >
                            Cancel
                        </Button>
                    </div>
                    <div />
                </Header>
                <Container className={classes.boxContainer}>
                    <Grid container spacing={0} className={clsx(classes.container, 'box-shadow')}>
                        <Grid item xs={12} className="am-form">
                            <Grid container className={classes.generalInfo}>
                                <Grid item xs={8}>
                                    {this.renderGeneralInfoForm(generalInfo, customerValidationData)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={5}>
                                    {this.renderAddressForm(address, addressValidationData)}
                                </Grid>
                                <Grid item xs={2} className="d-flex-justify-center-align-center">
                                    <Divider orientation="vertical" flexItem className={classes.divider} />
                                </Grid>
                                <Grid item xs={5}>
                                    {this.renderEmploymentForm(employment)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
                {openModalSuggestedAddress && (
                    <SuggestAddress
                        currentAddress={address}
                        onClose={toggleAddressSuggest}
                        open={openModalSuggestedAddress}
                        onChangeAddress={onChangeAddress}
                        suggestedAddress={suggestedAddress}
                    />
                )}
            </Container>
        );
    }
}

CreateCustomer.propTypes = {
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    toggleCreateCustomer: PropTypes.func.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    onChangeCity: PropTypes.func.isRequired,
    decodeZip: PropTypes.func.isRequired,
    validateAddress: PropTypes.func.isRequired,
    record: PropTypes.object.isRequired,
    addressSchema: PropTypes.object,
    isDecodingZip: PropTypes.bool.isRequired,
    onEnableDecoder: PropTypes.func.isRequired,
    onChangeAddress: PropTypes.func.isRequired,
    suggestedAddress: PropTypes.object.isRequired,
    toggleAddressSuggest: PropTypes.func.isRequired,
    openModalSuggestedAddress: PropTypes.bool.isRequired,
    loadingSuggestedAddress: PropTypes.bool.isRequired,
};

CreateCustomer.defaultProps = {
    addressSchema: DefaultAddressSchema,
};

export default withRouter(withStyles(styles)(CreateCustomerContainer(CreateCustomer)));

import React, { PureComponent } from 'react';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ButtonStyles from 'styles/theme/Button';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { DialogActions as MuiDialogActions, Button } from '@material-ui/core';
import StringUtils from 'lib/StringUtils';

const styles = (theme) => ({
    box: {
        borderTop: `1px solid${theme.palette.divider}`,
        padding: theme.spacing(1.2, 2),
    },
    button: {
        minWidth: 76,
    },
    hiddenDivider: {
        borderTop: 0,
    },
    ...ButtonStyles.getStyle(theme),
});

class DialogActions extends PureComponent {
    render() {
        const {
            props: {
                titlePrimary, titleSecondary, classes, onClickSecondary, onClickPrimary,
                divider, variant, disablePrimaryButton, hiddenPrimaryButton,
                hiddenSecondaryButton, disableSecondaryButton,
                useAdditionalButton, classSecondaryButton,
                titleAdditional,
                hiddenAdditionalButton,
                onClickAdditional,
                disableAdditionalButton,
            },
        } = this;
        const currenDivider = divider ? '' : classes.hiddenDivider;
        let currentVariant = 'contained';
        let currentClassPrimary = classes.containedFlat;
        let currentClassSecondary = classes.containedSecondaryInfo;

        if (!StringUtils.isEmpty(variant) && variant === 'outlined') {
            currentVariant = variant;
            currentClassPrimary = classes.outlinedSecondaryError;
            currentClassSecondary = classes.outlinedSecondaryInfo;
        }

        return (
            <MuiDialogActions className={clsx(classes.box, currenDivider)}>
                {useAdditionalButton && !hiddenAdditionalButton && (
                    <Button
                        variant={currentVariant}
                        className={clsx(classes.button, currentClassPrimary)}
                        onClick={onClickAdditional}
                        disabled={disableAdditionalButton}
                    >
                        {titleAdditional}
                    </Button>
                )}
                {!hiddenSecondaryButton && (
                    <Button
                        variant={currentVariant}
                        className={clsx(classes.button, currentClassPrimary, classSecondaryButton)}
                        onClick={onClickSecondary}
                        disabled={disableSecondaryButton}
                    >
                        {titleSecondary}
                    </Button>
                )}
                {!hiddenPrimaryButton && (
                    <Button
                        variant={currentVariant}
                        className={clsx(classes.button, currentClassSecondary)}
                        onClick={onClickPrimary}
                        disabled={disablePrimaryButton}
                    >
                        {titlePrimary}
                    </Button>
                )}
            </MuiDialogActions>
        );
    }
}

DialogActions.propTypes = {
    divider: PropTypes.bool,
    variant: PropTypes.string,
    titlePrimary: PropTypes.string,
    onClickPrimary: PropTypes.func,
    onClickSecondary: PropTypes.func,
    titleSecondary: PropTypes.string,
    hiddenPrimaryButton: PropTypes.bool,
    hiddenSecondaryButton: PropTypes.bool,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    disablePrimaryButton: PropTypes.bool,
    disableSecondaryButton: PropTypes.bool,
    useAdditionalButton: PropTypes.bool,
    titleAdditional: PropTypes.string,
    hiddenAdditionalButton: PropTypes.bool,
    onClickAdditional: PropTypes.func,
    disableAdditionalButton: PropTypes.bool,
    classSecondaryButton: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

DialogActions.defaultProps = {
    divider: true,
    variant: 'contained',
    titlePrimary: 'Save',
    titleSecondary: 'Cancel',
    onClickPrimary: () => {},
    onClickSecondary: () => {},
    disablePrimaryButton: false,
    hiddenPrimaryButton: false,
    hiddenSecondaryButton: false,
    disableSecondaryButton: false,
    useAdditionalButton: false,
    titleAdditional: 'Other',
    hiddenAdditionalButton: false,
    onClickAdditional: () => {},
    disableAdditionalButton: false,
    classSecondaryButton: '',
};

export default withStyles(styles)(DialogActions);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Bootstrap
import { Tabs, Tab } from 'react-bootstrap';

// Components and Others
import TabContainer from 'components/widgets/tabs/TabContainer';
import HistoryTab from 'components/modules/customer/read/HistoryTab/HistoryTab';
import PersonalTab from 'components/modules/customer/read/personalTab/PersonalTab';

// Styles
import TabStyles from 'styles/widgets/tabs/TabsStyles';

const styles = (theme) => TabStyles.tabPanel(theme);

class CustomerTabs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: 0,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(key) {
        this.setState({
            value: key,
        });
    }

    render() {
        const { state: { value }, props: { classes, customerCode, isCustomStockEnabled } } = this;

        return (
            <TabContainer>
                <Tabs
                    id="customer-tabs"
                    className={classes.navbar}
                    activeKey={value}
                    onSelect={this.handleChange}
                >
                    <Tab className={classes.tab} eventKey={0} title="Personal" mountOnEnter>
                        <PersonalTab customerCode={customerCode} isCustomStockEnabled={isCustomStockEnabled} />
                    </Tab>
                    <Tab className={classes.tab} eventKey={1} title="History" mountOnEnter>
                        <HistoryTab customerCode={parseInt(customerCode, 10)} className={classes.historyTab} />
                    </Tab>
                </Tabs>
            </TabContainer>
        );
    }
}

CustomerTabs.defaultProps = {
    isCustomStockEnabled: false,
};

CustomerTabs.propTypes = {
    customerCode: PropTypes.string.isRequired,
    isCustomStockEnabled: PropTypes.bool,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withRouter(withStyles(styles)(CustomerTabs));

import React, { useCallback, useState, useEffect } from 'react';

import clsx from 'clsx';
import Split from 'react-split';
import PropTypes from 'prop-types';
import NumberUtils from 'lib/NumberUtils';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import { PayType } from 'utils/enum/PayrollEnum';
import { useMutation, useQuery } from '@apollo/client';
import { FormLabel, makeStyles } from '@material-ui/core';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import PayrollAdditions from 'components/widgets/payroll/PayrollAdditions';
import useEmployeHasCheck from 'components/hook/payroll/useEmployeHasCheck';
import PayrollDeductions from 'components/widgets/payroll/PayrollDeductions';
import PayrollChecksQuery from 'services/graphQL/query/payroll/PayrollChecks';
import SalaryPanel from 'components/widgets/payroll/PayrollChecks/SalaryPanel';
import FlatRatePanel from 'components/widgets/payroll/PayrollChecks/FlatRatePanel';
import PayrollGeneralMutation from 'services/graphQL/mutate/payroll/PayrollGeneral';
import CommissionDrawPanel from 'components/widgets/payroll/PayrollChecks/CommissionDrawPanel';
import CommissionOnlyPanel from 'components/widgets/payroll/PayrollChecks/CommissionOnlyPanel';
import CommissionSalaryPanel from 'components/widgets/payroll/PayrollChecks/CommissionSalaryPanel';
import HourlyLimitOvertimePanel from 'components/widgets/payroll/PayrollChecks/HourlyLimitOvertimePanel';
import CommissionMinimumWageDrawPanel from 'components/widgets/payroll/PayrollChecks/CommissionMinimumWageDrawPanel';
import CommissionHourlyLimitOvertimePanel from 'components/widgets/payroll/PayrollChecks/CommissionHourlyLimitOvertimePanel';

const ownStyles = makeStyles((theme) => ({
    rightSideComponent: {
        overflow: 'auto',
    },
    root: {
        marginLeft: '2px',
    },
    boxRow: {
        padding: theme.spacing(1),
        '& .content-label': {
            paddingTop: 0,
            paddingBottom: 0,
            textAlign: 'end',
            '& h6': {
                fontWeight: 400,
            },
        },
        '& .content-value': {
            paddingTop: 0,
            paddingBottom: 0,
            '& p': {
                textAlign: 'left',
            },
        },
        minHeight: 240,
    },
    textBold: {
        fontWeight: 'bolder !important',
    },
    loadingIndicator: {
        flex: 1,
        height: '100%',
    },
    button: {
        textAlign: 'center',
        marginTop: 10,
        marginLeft: 60,
    },
    split: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(57% - 10px)',
    },
    checkDetail: {
        zIndex: 2,
        background: 'white',
        position: 'relative',
    },
    newButton: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.eucalyptus,
        '&:hover': {
            backgroundColor: theme.palette.background.forestGreen,
        },
    },
    clearDraw: {
        padding: 0,
        minWidth: 35,
        verticalAlign: 'top',
    },
    header: {
        display: 'flex',
        height: 'fit-content',
    },
    title: {
        alignSelf: 'center',
    },
}));

const initialState = {
    openClearDrawDialog: false,
    calculateCheckDetail: false,
    unpaidList: [{ title: '', detail: [] }],
};

const PayrollCheckContainer = ({
    pendingFinalPayment,
    employeeId, payPeriod, salary,
    year, loanBalance, payType,
}) => {
    const classes = ownStyles();
    const [state, setState] = useState(initialState);
    const { openClearDrawDialog, calculateCheckDetail, unpaidList } = state;
    const { hasCheck, refetch: reloadEmployeeHasCheck } = useEmployeHasCheck({ employeeId, payPeriod });

    const {
        data, loading, error, refetch: reloadUnpaidList,
    } = useQuery(PayrollChecksQuery.GET_UNPAID_LIST, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        variables: {
            employeeId,
        },
        skip: !employeeId || !payPeriod || !pendingFinalPayment,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && data?.getPayrollUnpaidList) {
            setState((previousState) => ({
                ...previousState,
                unpaidList: data.getPayrollUnpaidList,
            }));
        }
    }, [data, loading, error]);

    const toggleClearDrawDialog = () => setState((previousState) => ({
        ...previousState,
        openClearDrawDialog: !previousState.openClearDrawDialog,
    }));

    const setCalculateCheckDetail = useCallback((value) => setState((previousState) => ({
        ...previousState,
        calculateCheckDetail: value,
    })), []);

    const [clearDrawBalance, { loading: clearingDrawBalance }] = useMutation(PayrollGeneralMutation.CLEAR_DRAW_BALANCE, {
        onCompleted: (mutationData) => {
            if (mutationData?.clearDrawBalance) {
                ModalUtils.successMessage(null, 'Draw Balance Cleared Successfully!');
                reloadEmployeeHasCheck();
                setCalculateCheckDetail(true);
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    return (
        <div className={classes.rightSideComponent}>
            <Split
                minSize={0}
                sizes={[30, 70]}
                direction="vertical"
                className={clsx(classes.split, 'payrollSplit')}
            >
                <div>
                    <PayrollAdditions
                        year={year}
                        hasCheck={hasCheck}
                        payPeriod={payPeriod}
                        employeeId={employeeId}
                        calculateCheckDetail={calculateCheckDetail}
                        setCalculateCheckDetail={setCalculateCheckDetail}
                    />
                </div>
                <div>
                    <PayrollDeductions
                        hasCheck={hasCheck}
                        payPeriod={payPeriod}
                        employeeId={employeeId}
                        calculateCheckDetail={calculateCheckDetail}
                        setCalculateCheckDetail={setCalculateCheckDetail}
                    />
                </div>
            </Split>
            <div className={classes.checkDetail}>
                {loanBalance !== 0 && (
                    <FormLabel>
                        {`Employee Loan Balance: ${NumberUtils.applyCurrencyFormat(loanBalance)}`}
                    </FormLabel>
                )}
                {payType.toUpperCase() === PayType.SALARY.toUpperCase() && (
                    <SalaryPanel
                        salary={salary}
                        classes={classes}
                        hasCheck={hasCheck}
                        payPeriod={payPeriod}
                        employeeId={employeeId}
                        unpaidList={unpaidList}
                        reloadUnpaidList={reloadUnpaidList}
                        pendingFinalPayment={pendingFinalPayment}
                        calculateCheckDetail={calculateCheckDetail}
                        reloadEmployeeHasCheck={reloadEmployeeHasCheck}
                        setCalculateCheckDetail={setCalculateCheckDetail}
                    />
                )}
                {(payType.toUpperCase() === PayType.HOURLY_LIMITED.toUpperCase()
                || payType.toUpperCase() === PayType.HOURLY_OVERTIME.toUpperCase()) && (
                    <HourlyLimitOvertimePanel
                        classes={classes}
                        hasCheck={hasCheck}
                        payPeriod={payPeriod}
                        employeeId={employeeId}
                        unpaidList={unpaidList}
                        reloadUnpaidList={reloadUnpaidList}
                        pendingFinalPayment={pendingFinalPayment}
                        calculateCheckDetail={calculateCheckDetail}
                        reloadEmployeeHasCheck={reloadEmployeeHasCheck}
                        setCalculateCheckDetail={setCalculateCheckDetail}
                    />
                )}
                {payType.toUpperCase() === PayType.COMMISSION_ONLY.toUpperCase() && (
                    <CommissionOnlyPanel
                        classes={classes}
                        hasCheck={hasCheck}
                        payPeriod={payPeriod}
                        employeeId={employeeId}
                        unpaidList={unpaidList}
                        reloadUnpaidList={reloadUnpaidList}
                        pendingFinalPayment={pendingFinalPayment}
                        calculateCheckDetail={calculateCheckDetail}
                        reloadEmployeeHasCheck={reloadEmployeeHasCheck}
                        setCalculateCheckDetail={setCalculateCheckDetail}
                    />
                )}
                {payType.toUpperCase() === PayType.COMMISSION_DRAW.toUpperCase() && (
                    <CommissionDrawPanel
                        classes={classes}
                        hasCheck={hasCheck}
                        payPeriod={payPeriod}
                        employeeId={employeeId}
                        unpaidList={unpaidList}
                        reloadUnpaidList={reloadUnpaidList}
                        pendingFinalPayment={pendingFinalPayment}
                        calculateCheckDetail={calculateCheckDetail}
                        reloadEmployeeHasCheck={reloadEmployeeHasCheck}
                        setCalculateCheckDetail={setCalculateCheckDetail}
                    />
                )}
                {payType.toUpperCase() === PayType.COMMISSION_MINIMUM_WAGE_DRAW.toUpperCase() && (
                    <CommissionMinimumWageDrawPanel
                        classes={classes}
                        hasCheck={hasCheck}
                        payPeriod={payPeriod}
                        employeeId={employeeId}
                        unpaidList={unpaidList}
                        reloadUnpaidList={reloadUnpaidList}
                        pendingFinalPayment={pendingFinalPayment}
                        calculateCheckDetail={calculateCheckDetail}
                        toggleClearDrawDialog={toggleClearDrawDialog}
                        reloadEmployeeHasCheck={reloadEmployeeHasCheck}
                        setCalculateCheckDetail={setCalculateCheckDetail}
                    />
                )}
                {(payType.toUpperCase() === PayType.COMMISSION_HOURLY_LIMITED.toUpperCase()
                || payType.toUpperCase() === PayType.COMMISSION_HOURLY_OVERTIME.toUpperCase()) && (
                    <CommissionHourlyLimitOvertimePanel
                        classes={classes}
                        hasCheck={hasCheck}
                        payPeriod={payPeriod}
                        employeeId={employeeId}
                        unpaidList={unpaidList}
                        reloadUnpaidList={reloadUnpaidList}
                        pendingFinalPayment={pendingFinalPayment}
                        calculateCheckDetail={calculateCheckDetail}
                        reloadEmployeeHasCheck={reloadEmployeeHasCheck}
                        setCalculateCheckDetail={setCalculateCheckDetail}
                    />
                )}
                {payType.toUpperCase() === PayType.COMMISSION_SALARY.toUpperCase() && (
                    <CommissionSalaryPanel
                        classes={classes}
                        hasCheck={hasCheck}
                        payPeriod={payPeriod}
                        employeeId={employeeId}
                        unpaidList={unpaidList}
                        reloadUnpaidList={reloadUnpaidList}
                        pendingFinalPayment={pendingFinalPayment}
                        calculateCheckDetail={calculateCheckDetail}
                        reloadEmployeeHasCheck={reloadEmployeeHasCheck}
                        setCalculateCheckDetail={setCalculateCheckDetail}
                    />
                )}
                {payType.toUpperCase() === PayType.FLAT_RATE.toUpperCase() && (
                    <FlatRatePanel
                        classes={classes}
                        hasCheck={hasCheck}
                        payPeriod={payPeriod}
                        employeeId={employeeId}
                        calculateCheckDetail={calculateCheckDetail}
                        reloadEmployeeHasCheck={reloadEmployeeHasCheck}
                        setCalculateCheckDetail={setCalculateCheckDetail}
                    />
                )}
            </div>
            <ConfirmDialog
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                title="Confirm Clearing"
                open={openClearDrawDialog}
                onClose={toggleClearDrawDialog}
                onClickSecondary={toggleClearDrawDialog}
                disablePrimaryButton={clearingDrawBalance}
                disableSecondaryButton={clearingDrawBalance}
                description="Are you sure you want to clear the draw to zero?"
                onClickPrimary={() => {
                    toggleClearDrawDialog();
                    clearDrawBalance({ variables: { employeeId } });
                }}
            />
        </div>
    );
};

PayrollCheckContainer.propTypes = {
    year: PropTypes.number.isRequired,
    salary: PropTypes.number.isRequired,
    payType: PropTypes.string.isRequired,
    payPeriod: PropTypes.string.isRequired,
    employeeId: PropTypes.number.isRequired,
    loanBalance: PropTypes.number.isRequired,
    pendingFinalPayment: PropTypes.bool.isRequired,
};

export default PayrollCheckContainer;

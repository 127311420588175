/* eslint-disable no-param-reassign */
import React, {
    useReducer, useEffect, useState, useContext,
} from 'react';
import update from 'immutability-helper';
import produce from 'immer';
import { isFinite, map, compact } from 'lodash';
import clsx from 'clsx';
import {
    Link as RouteLink, useLocation, useParams, useHistory,
} from 'react-router-dom';

import {
    useApolloClient,
    useQuery,
    useLazyQuery,
    useMutation,
    useSubscription,
} from '@apollo/client';
import useCustomStockNumber from 'components/hook/inventory/useCustomStockNumber';
import GeneralUtils from 'utils/GeneralUtils';
import LotQuery from 'services/graphQL/query/LotQuery';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';
import MapInventoryData from 'services/mapData/MapInventoryData';

import If from 'components/widgets/conditional/If';
import DetailLeftPanel from 'components/modules/inventory/read/panels/DetailLeftPanel';
import TabsContent from 'components/modules/inventory/read/panels/TabsContent';
import TopDetailPanel from 'components/modules/inventory/read/panels/TopDetailPanel';
import InventoryDealBanner from 'components/modules/inventory/read/panels/InventoryDealBanner';
import LoadingMask from 'components/widgets/LoadingMask';
import VehicleErrorPage from 'components/widgets/inventory/VehicleErrorPage';
import InputSearch from 'components/widgets/InputSearch';
import UserContext from 'components/context/UserContext';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';
import InventoryModuleSubscription from 'services/graphQL/subscription/inventory/InventoryModuleSubscription';

// Material UI
import {
    makeStyles,
    Typography,
    IconButton,
    Grid,
    Toolbar,
    Hidden,
    AppBar,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MenuIcon from '@material-ui/icons/Menu';
import { Form } from 'react-bootstrap';

import InventoryHelper from 'utils/InventoryHelper';
import { modules } from 'utils/enum/modules';
import {
    DataSort,
    FetchPolicy,
    ALL_LOTS,
} from 'utils/enum/Core';
import LotsCategory from 'utils/enum/LotsCategory';
import { Tabs, InventoryCategory, InventoryPanels } from 'utils/enum/InventoryEnum';
import SettingsHelper from 'utils/SettingsHelper';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';

const useStyles = makeStyles((theme) => ({
    main: {
        height: '100%',
        width: '100%',
        background: theme.palette.background.default,
    },
    header: {
        position: 'absolute',
        top: 0,
        zIndex: 1100,
        width: '100%',
        fontSize: '18px',
        background: '#FFFFFF',
        [theme.breakpoints.down('sm')]: {
            position: 'initial',
            '& > header': {
                position: 'initial',
            },
        },
    },
    menuButton: {
        marginLeft: 'auto',
        marginRight: '8px',
        '& svg': {
            fontSize: '1.8rem',
        },
    },
    toolbarLeft: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            flexDirection: 'column',
            '& > div:nth-child(1)': {
                position: 'fixed',
                top: '0',
                left: '0',
                backgroundColor: theme.palette.background.white,
                zIndex: '999',
                width: '100%',
            },
            '& > div:nth-child(2)': {
                marginLeft: '0px',
                flexDirection: 'column',
                marginBottom: '15px',
            },
        },
    },
    toolbarRight: {
        display: 'flex',
        '& > a:nth-child(1)': {
            marginRight: '15px',
        },
        '& > a': {
            textDecoration: 'none',
            '& > button': {
                padding: '4px',
            },
        },
        '& > a:nth-child(1) > div:nth-child(2)': {
            fontSize: '10px',
            color: theme.palette.text.secondary,
            marginLeft: '-16px',
        },
        '& > a:nth-child(2) > div:nth-child(2)': {
            fontSize: '10px',
            color: theme.palette.text.secondary,
            marginLeft: '-7px',
        },
    },
    toolbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down(780)]: {
            flexDirection: 'column',
            alignItems: 'center',
            paddingBottom: 10,
        },
        '& > div:first-child': {
            [theme.breakpoints.down(780)]: {
                width: '100%',
            },
        },
    },
    mobileDetail: {
        height: '100%',
        [theme.breakpoints.down(780)]: {
            marginTop: 0,
            '& > div:nth-child(2)': {
                paddingTop: '0px',
            },
        },
    },
    searchFilter: {
        marginLeft: 10,
        '& > div:first-child': {
            marginRight: 10,
        },
    },
    includeSold: {
        fontSize: 14,
        minWidth: 156,
    },
    headerPosition: {
        position: 'relative',
    },
}));
const ACTION_TYPES = {
    SET_RECORD: 'setRecord',
    SET_LOCATION: 'setLocation',
    SET_NOTES: 'setNotes',
    SET_REPAIRS: 'setRepairs',
    SET_VEHICLE_DETAIL: 'setVehicleDetail',
    SET_EDITED_SECTION: 'setEditedSection',
    SET_PARAMS: 'setParams',
    SET_STATUS: 'setStatus',
    SET_SEARCH: 'setSearch',
    SET_STOCKS: 'setStocks',
    SET_LOT_SETTINGS: 'setLotSettings',
    SET_VEHICLE_IMAGES: 'setVehicleImages',
    SET_VEHICLE_BLOCKING_STATUS: 'setVehicleBlockingStatus',
};
const getTotalCost = (purchasedPrice, totalCost) => {
    const price = isFinite(purchasedPrice) ? purchasedPrice : 0;
    const cost = isFinite(totalCost) ? totalCost : 0;

    return price + cost;
};
const getTotalRepairs = (repairs) => (isFinite(repairs) ? repairs : 0);
const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORD:
        return action.payload;
    case ACTION_TYPES.SET_LOCATION:
        return update(state, {
            detail: { vehicleLocation: { $set: action.payload } },
        });
    case ACTION_TYPES.SET_NOTES:
        return update(state, {
            detail: { notes: { $set: action.payload } },
        });
    case ACTION_TYPES.SET_VEHICLE_DETAIL:
        const { payload: { fieldName, data } } = action;
        const isString = typeof data === 'string';

        if (isString) {
            return update(state, {
                detail: { [fieldName]: { $set: data } },
            });
        }

        return update(state, {
            detail: {
                [fieldName]: { $merge: data },
                ...(fieldName.toLowerCase() !== InventoryPanels.FLOORPLAN.toLowerCase() ? {} : {
                    pricing: { $merge: { ...state.pricing, floorplanCost: data.floorplanCost } },
                }),
            },
            editedSection: { $set: '' },
        });
    case ACTION_TYPES.SET_EDITED_SECTION:
        return update(state, {
            editedSection: { $set: action.payload },
        });
    case ACTION_TYPES.SET_REPAIRS:
        return update(state, {
            detail: {
                pricing: {
                    repairs: { $set: getTotalRepairs(action.payload) },
                    cost: { $set: getTotalCost(state?.detail?.pricing?.purchasedPrice, action.payload) },
                },
            },
        });
    case ACTION_TYPES.SET_VEHICLE_IMAGES:
        const { payload: { images, thumbnail } } = action;

        return update(state, {
            images: { $set: images },
            detail: { thumbnail: { $set: thumbnail } },
        });
    case ACTION_TYPES.SET_LOT_SETTINGS:
        const { payload: { userTabs, defaultReconCost } } = action;

        return update(state, {
            userTabs: { $set: userTabs },
            defaultReconCost: { $set: defaultReconCost },
        });
    default:
        return {};
    }
};
const getSettingData = (data, onlyReconCost = false) => {
    const { INV_USER_TAB_NAME_1, INV_USER_TAB_NAME_2, INV_DEFAULT_MAX_RECON_COST } = InventoryCategory;
    const invUserTabName1 = SettingsHelper.getValueByKey(data, INV_USER_TAB_NAME_1);
    const invUserTabName2 = SettingsHelper.getValueByKey(data, INV_USER_TAB_NAME_2);
    const defaultReconCost = SettingsHelper.getValueByKey(data, INV_DEFAULT_MAX_RECON_COST);

    return onlyReconCost ? {
        defaultReconCost: Number(defaultReconCost || 0),
    } : {
        invUserTabName1,
        invUserTabName2,
    };
};

const initParams = {
    search: '',
    status: true,
    stocks: {},
    blockingStatus: null,
};

const listReducer = produce((draftState, { type, payload = null }) => {
    switch (type) {
    case ACTION_TYPES.SET_PARAMS:
        draftState.status = true;
        draftState.search = '';

        if (payload.stocks) {
            const index = payload.stocks.indexOf(payload.stockNumber) || 0;

            draftState.stocks = {
                list: payload.stocks,
                previous: (payload.stocks.length > 0 && index > 0 ? index - 1 : null),
                next: (index < payload.stocks.length - 1 ? index + 1 : null),
            };
        }
        break;
    case ACTION_TYPES.SET_STATUS:
        draftState.status = !draftState.status;
        break;
    case ACTION_TYPES.SET_SEARCH:
        draftState.search = payload;
        break;
    case ACTION_TYPES.SET_STOCKS:
        if (payload.stocks) {
            const index = payload.stocks.indexOf(payload.stockNumber) || 0;

            draftState.stocks = {
                list: payload.stocks,
                previous: (payload.stocks.length > 0 && index > 0 ? index - 1 : null),
                next: (index < payload.stocks.length - 1 ? index + 1 : null),
            };
        }
        break;
    case ACTION_TYPES.SET_VEHICLE_BLOCKING_STATUS:
        draftState.blockingStatus = payload;
        break;
    default:
        break;
    }
});

const VehicleDetail = () => {
    const client = useApolloClient();
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [parameters, dispatchParams] = useReducer(listReducer, initParams);
    const { userInformation } = useContext(UserContext);
    const availableLots = userInformation?.lots || [];
    const { stocks, blockingStatus } = parameters;

    let { stockNumber } = useParams();
    stockNumber = Number(stockNumber);

    const {
        data: vehicleDetails,
        loading: loadingVehicleDetails,
        error: errorLoadingVehicleDetails,
        refetch: refetchVehicleDetails,
    } = useQuery(InventoryQuery.GET_VEHICLE_DETAILS, {
        variables: {
            stockNumber,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const {
        data: vehicleBlockingStatus,
        loading: loadingVehicleBlockingStatus,
        error: errorLoadingVehicleBlockingStatus,
        refetch: refetchBlockingStatus,
    } = useQuery(InventoryQuery.GET_VEHICLE_BLOCKING_STATUS, {
        variables: {
            stockNumber,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [pullInventory, { loading: pullingVehicles }] = useLazyQuery(InventoryQuery.getPullInventorySchema([
        'stockNumber',
    ]), {
        onCompleted: (response) => {
            if (response) {
                const vehicles = response.pullInventory ?? [];
                if (vehicles.length <= 0) {
                    ModalUtils.errorMessage(null, 'No matches');
                } else if (vehicles.length === 1) {
                    const stock = vehicles[0].stockNumber;
                    history.push(`/${modules.INVENTORY}/${stock}`);
                } else {
                    const newData = {
                        fromDetail: true,
                        search: parameters.search,
                        status: parameters.status,
                    };

                    history.push(`/${modules.INVENTORY}`, newData);
                }
            }
        },
        onError: (err) => {
            ModalUtils.errorMessage([err]);
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const { data: subscriptionData } = useSubscription(InventoryModuleSubscription.VEHICLE_BLOCKING_STATUS_CHANGED, {
        variables: {
            stockNumber,
        },
        shouldResubscribe: true,
    });

    const [changeBlockingStatus] = useMutation(InventoryMutation.CHANGE_BLOCKING_STATUS, {
        onCompleted: () => {
            refetchBlockingStatus();
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    const [vehicle, dispatchVehicle] = useReducer(reducer, {});
    const [openDrawer, setOpenDrawer] = useState(false);
    const selectedTab = InventoryHelper.getSelectedTab(location.hash);
    const { detail = {} } = vehicle || {};
    const isDetailToolbar = isMobile && selectedTab !== Tabs.DETAIL;
    const lotName = vehicle?.detail?.lotName;
    const { INV_USER_TAB_NAME_1, INV_USER_TAB_NAME_2, INV_DEFAULT_MAX_RECON_COST } = InventoryCategory;

    useEffect(() => {
        const handleWindowUnload = () => {
            if (!blockingStatus || !blockingStatus.isEditing || (blockingStatus.isEditing && blockingStatus.editingById !== userInformation.userId)) return;

            const DocumentNode = InventoryMutation.CHANGE_BLOCKING_STATUS;
            const { loc: { source: { body } } } = DocumentNode;
            GeneralUtils.sendGraphQLPersistentRequest({
                query: body,
                variables: {
                    stockNumber,
                    isBlocking: false,
                },
            });
        };

        window.addEventListener('beforeunload', handleWindowUnload);
        return () => {
            window.removeEventListener('beforeunload', handleWindowUnload);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blockingStatus, userInformation]);

    useEffect(() => {
        if (subscriptionData) {
            const response = subscriptionData.vehicleRecordBlockingStatusChanged;

            if (!response.isEditing) refetchVehicleDetails();
            dispatchParams({
                type: ACTION_TYPES.SET_VEHICLE_BLOCKING_STATUS,
                payload: response,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriptionData]);

    useEffect(() => {
        if (errorLoadingVehicleBlockingStatus) {
            ModalUtils.errorMessage(errorLoadingVehicleBlockingStatus?.graphQLErrors);
            return;
        }

        if (!loadingVehicleBlockingStatus) {
            const status = vehicleBlockingStatus?.getVehicleRecordBlockingStatus;
            if (status) {
                dispatchParams({
                    type: ACTION_TYPES.SET_VEHICLE_BLOCKING_STATUS,
                    payload: status,
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingVehicleBlockingStatus, errorLoadingVehicleBlockingStatus]);

    useEffect(() => {
        const getSettings = async () => {
            try {
                const { data: userTabsData } = await client.query({
                    query: LotQuery.GET_SETTINGS,
                    variables: {
                        category: LotsCategory.INVENTORY,
                        lotName,
                        key: [INV_USER_TAB_NAME_1, INV_USER_TAB_NAME_2],
                    },
                    fetchPolicy: FetchPolicy.NETWORK_ONLY,
                });

                const { data: defaultReconCostData } = await client.query({
                    query: LotQuery.GET_SETTINGS,
                    variables: {
                        category: LotsCategory.INVENTORY,
                        lotName: ALL_LOTS,
                        key: [INV_DEFAULT_MAX_RECON_COST],
                    },
                    fetchPolicy: FetchPolicy.NETWORK_ONLY,
                });

                dispatchVehicle({
                    type: ACTION_TYPES.SET_LOT_SETTINGS,
                    payload: {
                        userTabs: getSettingData(userTabsData?.getSettings),
                        defaultReconCost: getSettingData(defaultReconCostData?.getSettings, true).defaultReconCost,
                    },
                });
            } catch (err) {
                ModalUtils.errorMessage(null, err);
            }
        };

        if (lotName && vehicle.detail) getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lotName, vehicle.detail]);

    useEffect(() => {
        dispatchParams({
            type: ACTION_TYPES.SET_STOCKS,
            payload: {
                stocks: location?.stocks,
                stockNumber,
            },
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.stocks]);

    useEffect(() => {
        if (errorLoadingVehicleDetails) {
            ModalUtils.errorMessage(errorLoadingVehicleDetails?.graphQLErrors);
            return;
        }

        if (!loadingVehicleDetails) {
            const record = MapInventoryData.mapVehicleDetail(vehicleDetails?.getVehicleDetails);

            dispatchVehicle({
                type: ACTION_TYPES.SET_RECORD,
                payload: record,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingVehicleDetails, errorLoadingVehicleDetails]);

    useEffect(() => {
        if (!StringUtils.isEmpty(parameters.search)) {
            pullInventory({
                variables: {
                    search: parameters.search,
                    active: parameters.status,
                    lots: compact(map(availableLots, 'lotName')),
                    paginate: {
                        start: 0,
                        limit: 50,
                    },
                    sort: {
                        field: 'stockNumber',
                        dir: DataSort.DESC,
                    },
                },
            });
        }
        // eslint-disable-next-line
    }, [parameters.status, parameters.search]);

    const onChangeVehicleLocation = (newLocation) => {
        dispatchVehicle({
            type: ACTION_TYPES.SET_LOCATION,
            payload: newLocation,
        });
    };
    const onChangeVehicleNotes = (newNotes) => {
        dispatchVehicle({
            type: ACTION_TYPES.SET_NOTES,
            payload: newNotes,
        });
    };
    const setRepairs = (repairs) => {
        dispatchVehicle({
            type: ACTION_TYPES.SET_REPAIRS,
            payload: repairs,
        });
    };
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpenDrawer(open);
    };
    const setVehicleDetail = (fieldName, newData = {}) => {
        dispatchVehicle({
            type: ACTION_TYPES.SET_VEHICLE_DETAIL,
            payload: {
                fieldName,
                data: newData,
            },
        });
    };
    const setVehicleImages = (images, thumbnail) => {
        dispatchVehicle({
            type: ACTION_TYPES.SET_VEHICLE_IMAGES,
            payload: { images, thumbnail },
        });
    };
    const setEditedSection = (section) => {
        dispatchVehicle({
            type: ACTION_TYPES.SET_EDITED_SECTION,
            payload: section,
        });
    };
    const onSearch = (searchValue) => {
        dispatchParams({
            type: ACTION_TYPES.SET_SEARCH,
            payload: searchValue,
        });
    };
    const onChangeIncludeSold = () => {
        dispatchParams({ type: ACTION_TYPES.SET_STATUS });
    };

    const onChangeVehicle = (stock) => {
        if (stock == null) return;

        dispatchParams({
            type: ACTION_TYPES.SET_PARAMS,
            payload: {
                stocks: location?.stocks,
                stockNumber: stock,
            },
        });
    };

    const renderNextPreviousButtons = () => {
        const { list, previous, next } = stocks;
        if (!list) return null;

        const previousStock = previous !== null
            ? list[previous] : stockNumber;
        const nextStock = next !== null
            ? list[next] : stockNumber;

        return (
            <>
                <RouteLink
                    to={{
                        pathname: `/inventory/${previousStock}`,
                        stocks: list,
                    }}
                >
                    <IconButton
                        edge="start"
                        disabled={previous === null}
                        onClick={() => onChangeVehicle(previousStock)}
                    >
                        <ArrowBackOutlinedIcon />
                    </IconButton>
                    <div>
                        Previous
                    </div>
                </RouteLink>
                <RouteLink
                    to={{
                        pathname: `/inventory/${nextStock}`,
                        stocks: list,
                    }}
                >
                    <IconButton
                        edge="start"
                        disabled={next === null}
                        onClick={() => onChangeVehicle(nextStock)}
                    >
                        <ArrowForwardOutlinedIcon />
                    </IconButton>
                    <div>
                        Next
                    </div>
                </RouteLink>
            </>
        );
    };

    const customizedStockNumber = useCustomStockNumber(userInformation, vehicle?.detail);
    const navigationArrowsIncluded = (!stocks.list ? false : stocks.list.length > 0);
    if (errorLoadingVehicleDetails) {
        return (<VehicleErrorPage message="Error loading vehicle" />);
    }

    if (loadingVehicleDetails || pullingVehicles) {
        return (<LoadingMask />);
    }

    return (
        <div className={classes.main}>
            <div className={classes.header}>
                <AppBar color="inherit" elevation={1} className={classes.headerPosition}>
                    <Toolbar className={clsx(classes.toolbar)}>
                        <div className={classes.toolbarLeft}>
                            <div className="d-flex-center">
                                <RouteLink to={{ pathname: `/${modules.INVENTORY}` }}>
                                    <IconButton aria-label="Go back">
                                        <ArrowBackIcon />
                                    </IconButton>
                                </RouteLink>
                                <Typography variant="h6">
                                    { customizedStockNumber }
                                </Typography>
                                <Hidden mdUp>
                                    <IconButton
                                        edge="end"
                                        className={classes.menuButton}
                                        color="inherit"
                                        aria-label="open drawer"
                                        onClick={toggleDrawer(true)}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                </Hidden>
                            </div>
                            <div className={clsx('d-flex-center', classes.searchFilter)}>
                                <InputSearch initialSearch={parameters.search} onSearch={onSearch} />
                                <Form.Check
                                    type="checkbox"
                                    label="Include sold vehicles"
                                    className={classes.includeSold}
                                    checked={!parameters.status}
                                    onChange={onChangeIncludeSold}
                                />
                            </div>
                        </div>
                        <If condition={navigationArrowsIncluded}>
                            <div className={classes.toolbarRight}>
                                {renderNextPreviousButtons()}
                            </div>
                        </If>
                    </Toolbar>
                    {isDetailToolbar && (
                        <TopDetailPanel
                            detail={detail}
                            invUserTabName={vehicle.userTabs}
                            stockNumber={stockNumber}
                        />
                    )}
                </AppBar>
            </div>
            <Hidden smDown>
                <Toolbar styles={{ width: '100%' }} />
            </Hidden>
            <InventoryDealBanner
                stockNumber={stockNumber}
                blockingStatus={blockingStatus}
                currentUserId={userInformation?.userId}
            />
            <Grid
                container
                spacing={2}
                className={classes.mobileDetail}
            >
                <Grid item lg={3} md={4} xs={12}>
                    <DetailLeftPanel
                        record={vehicle}
                        stockNumber={stockNumber}
                        onChangeVehicleLocation={onChangeVehicleLocation}
                        onChangeVehicleNotes={onChangeVehicleNotes}
                        invUserTabName={vehicle.userTabs}
                        onReload={refetchVehicleDetails}
                        blockingStatus={blockingStatus}
                        currentUserId={userInformation?.userId}
                        changeBlockingStatus={changeBlockingStatus}
                    />
                </Grid>
                <Grid item lg={9} md={8} xs={12}>
                    <TabsContent
                        onReload={refetchVehicleDetails}
                        stockNumber={stockNumber}
                        record={vehicle}
                        setRepairs={setRepairs}
                        openDrawer={openDrawer}
                        toggleDrawer={toggleDrawer}
                        setVehicleDetail={setVehicleDetail}
                        setVehicleImages={setVehicleImages}
                        setEditedSection={setEditedSection}
                        invUserTabName={vehicle.userTabs}
                        defaultReconCost={vehicle.defaultReconCost}
                        blockingStatus={blockingStatus}
                        currentUserId={userInformation?.userId}
                        changeBlockingStatus={changeBlockingStatus}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default VehicleDetail;

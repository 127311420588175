import React, { useEffect, useState, useContext } from 'react';

// Material UI
import {
    Divider, Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BoxWidget from 'components/widgets/activities/BoxWidget';
import Placeholder from 'components/widgets/Placeholder';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DateUtils from 'lib/DateUtils';
import CardVehicle from 'components/modules/lead/widgets/CardVehicle';
import LeadHelper from 'components/modules/lead/LeadHelper';
import { LeadTypeStyle } from 'styles/modules/crm/LeadStyles';
import UserContext from 'components/context/UserContext';
import useCustomStockNumber from 'components/hook/inventory/useCustomStockNumber';

// HTTP
import { useQuery } from '@apollo/client';
import LeadQuery from 'services/graphQL/query/crm/LeadQuery';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';

const useStyle = makeStyles((theme = {}) => ({
    label: {
        color: `${theme.palette.text.minsk}`,
        whiteSpace: 'nowrap',
        fontSize: '14px',
        fontWeight: '500',
        textAlign: 'right',
        lineHeight: '20px',
    },
    value: {
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '20px',
        color: theme.palette.text.gray,
        whiteSpace: 'nowrap',
    },
    ...LeadTypeStyle,
    middleColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    rightColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
    //
    vehicle: {
        padding: 0,
        border: 'none',
        minWidth: 210,
        maxWidth: 210,
    },
    widget: {
        marginBottom: '20px',
    },
    container: {
        overflow: 'auto',
    },
    item: {
        display: 'flex',
        padding: theme.spacing(1),
    },
}));

const LeadWidget = ({ crmId }) => {
    const classes = useStyle();
    const [records, setRecords] = useState([]);
    const { data, loading, error } = useQuery(LeadQuery.GET_LEADS_BY_CRM_ID, { variables: { crmId }, fetchPolicy: FetchPolicy.NETWORK_ONLY });

    useEffect(() => {
        if (!loading && !error) {
            setRecords(data?.getLeadsByCRMId);
        } else if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
        }
    }, [data, loading, error]);

    return (
        <BoxWidget
            title={`Leads (${records.length})`}
            minHeight="auto"
            maxHeight="auto"
            className={classes.widget}
        >
            {records.length > 0 ? (
                <div className={classes.container}>
                    {records.map((record, index) => <Item key={index} {...record} classes={classes} />)}
                </div>
            )
                : <Placeholder text="No Leads" />}
        </BoxWidget>
    );
};

const Item = ({
    vehicle, source, leadType, createdOn, classes,
}) => {
    const date = DateUtils.calendarWithTime(createdOn);
    const type = LeadHelper.getLeadType(leadType);
    const typeIcon = LeadHelper.getLeadTypeIcon(type);
    const cardVehicle = vehicle || {};

    const { userInformation } = useContext(UserContext);
    const customizedStockNumber = useCustomStockNumber(
        userInformation,
        { stockNumber: vehicle?.stockNumber, customStockNumber: vehicle?.inventory?.customStockNumber },
    );

    return (
        <div>
            <Grid container className={classes.item}>
                <Grid item xs={4}>
                    <CardVehicle
                        className={classes.vehicle}
                        vehicle={{ ...cardVehicle, thumbnail: vehicle?.inventory?.thumbnail }}
                    />
                </Grid>
                <Grid item xs={4} className={classes.middleColumn}>
                    <div>
                        <span className={classes.label}>Stock: </span>
                        <span className={classes.value}>
                            {customizedStockNumber ?? 'None'}
                        </span>
                    </div>
                    <div>
                        <span className={classes.label}>Source: </span>
                        <span className={clsx(classes.value)}>
                            {source ?? 'None'}
                        </span>
                    </div>
                </Grid>
                <Grid item xs={4} className={classes.rightColumn}>
                    <div>
                        <span className={classes.label}>Lead Type: </span>
                        <span className={clsx(classes.value, classes.leadType, typeIcon.name)}>
                            {typeIcon.icon}
                            <span>{type}</span>
                        </span>
                    </div>
                    <div>
                        <span className={classes.label}>Date: </span>
                        <span className={clsx(classes.value)}>
                            {date}
                        </span>
                    </div>
                </Grid>
            </Grid>
            <Divider />
        </div>
    );
};

Item.defaultProps = {
    vehicle: null,
    createdOn: null,
};

Item.propTypes = {
    vehicle: PropTypes.object,
    source: PropTypes.string.isRequired,
    leadType: PropTypes.string.isRequired,
    createdOn: PropTypes.string,
    classes: PropTypes.object.isRequired,
};

LeadWidget.propTypes = {
    crmId: PropTypes.string.isRequired,
};

export default LeadWidget;

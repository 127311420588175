import { gql } from '@apollo/client';

export default class PayrollChecksQuery {
    static GET_PAYROLL_CHECK_DETAIL_SALARY = gql`
        query getPayrollCheckDetail($employeeId: Int!, $payPeriod: String!, $pendingFinalPayment: Boolean) {
            getPayrollCheckDetail(employeeId: $employeeId, payPeriod: $payPeriod, pendingFinalPayment: $pendingFinalPayment) {
                netPay
                userTax
                grossPay
                medicare
                stateTax
                userTaxName
                withholdings
                preTaxBonuses
                postTaxBonuses
                socialSecurity
                preTaxDeductions
                postTaxDeductions
                standardTaxBonuses
                standardTaxDeductions
                check {
                    amount
                    checkNumber
                    achCheckNumber
                }
            }
        }
    `;

    static GET_PAYROLL_CHECK_DETAIL_HOURLY = gql`
        query getPayrollCheckDetail($employeeId: Int!, $payPeriod: String!) {
            getPayrollCheckDetail(employeeId: $employeeId, payPeriod: $payPeriod) {
                netPay
                userTax
                grossPay
                medicare
                stateTax
                hourlyRate
                hoursWorked
                userTaxName
                overtimeRate
                withholdings
                preTaxBonuses
                overtimeWorked
                postTaxBonuses
                socialSecurity
                preTaxDeductions
                postTaxDeductions
                standardTaxBonuses
                standardTaxDeductions
                check {
                    amount
                    checkNumber
                    achCheckNumber
                }
            }
        }
    `;

    static GET_PAYROLL_CHECK_DETAIL_COMMISSION_ONLY = gql`
        query getPayrollCheckDetail($employeeId: Int!, $payPeriod: String!) {
            getPayrollCheckDetail(employeeId: $employeeId, payPeriod: $payPeriod) {
                netPay
                userTax
                grossPay
                medicare
                stateTax
                commissions
                userTaxName
                withholdings
                preTaxBonuses
                postTaxBonuses
                socialSecurity
                preTaxDeductions
                postTaxDeductions
                standardTaxBonuses
                standardTaxDeductions
                check {
                    checkNumber
                    achCheckNumber
                    amount
                }
                commissionList {
                    approvedJob
                    commissionId
                    approvedTotal
                    approvedAccount
                }
            }
        }
    `;

    static GET_PAYROLL_CHECK_DETAIL_COMMISSION_DRAW = gql`
        query getPayrollCheckDetail($employeeId: Int!, $payPeriod: String!) {
            getPayrollCheckDetail(employeeId: $employeeId, payPeriod: $payPeriod) {
                draw
                netPay
                userTax
                grossPay
                medicare
                stateTax
                hourlyRate
                commissions
                hoursWorked
                userTaxName
                withholdings
                preTaxBonuses
                postTaxBonuses
                socialSecurity
                preTaxDeductions
                postTaxDeductions
                standardTaxBonuses
                standardTaxDeductions
                check {
                    checkNumber
                    achCheckNumber
                    amount
                }
                commissionList {
                    approvedJob
                    commissionId
                    approvedTotal
                    approvedAccount
                }
            }
        }
    `;

    static GET_PAYROLL_CHECK_DETAIL_COMMISSION_MINIMUM_WAGE_DRAW = gql`
        query getPayrollCheckDetail($employeeId: Int!, $payPeriod: String!) {
            getPayrollCheckDetail(employeeId: $employeeId, payPeriod: $payPeriod) {
                draw
                netPay
                userTax
                grossPay
                lastDraw
                medicare
                stateTax
                hourlyRate
                commissions
                hoursWorked
                userTaxName
                withholdings
                preTaxBonuses
                postTaxBonuses
                socialSecurity
                preTaxDeductions
                commissionBalance
                postTaxDeductions
                standardTaxBonuses
                standardTaxDeductions
                check {
                    checkNumber
                    achCheckNumber
                    amount
                }
                commissionList {
                    approvedJob
                    commissionId
                    approvedTotal
                    approvedAccount
                }
            }
        }
    `;

    static GET_PAYROLL_CHECK_DETAIL_COMMISSION_HOURLY = gql`
        query getPayrollCheckDetail($employeeId: Int!, $payPeriod: String!) {
            getPayrollCheckDetail(employeeId: $employeeId, payPeriod: $payPeriod) {
                netPay
                userTax
                grossPay
                medicare
                stateTax
                hourlyRate
                commissions
                hoursWorked
                userTaxName
                overtimeRate
                withholdings
                preTaxBonuses
                overtimeWorked
                postTaxBonuses
                socialSecurity
                preTaxDeductions
                postTaxDeductions
                standardTaxBonuses
                standardTaxDeductions
                check {
                    checkNumber
                    achCheckNumber
                    amount
                }
                commissionList {
                    approvedJob
                    commissionId
                    approvedTotal
                    approvedAccount
                }
            }
        }
    `;

    static GET_PAYROLL_CHECK_DETAIL_COMMISSION_SALARY = gql`
        query getPayrollCheckDetail($employeeId: Int!, $payPeriod: String!, $pendingFinalPayment: Boolean) {
            getPayrollCheckDetail(employeeId: $employeeId, payPeriod: $payPeriod, pendingFinalPayment: $pendingFinalPayment) {
                netPay
                userTax
                grossPay
                medicare
                stateTax
                salaryPaid
                commissions
                userTaxName
                withholdings
                preTaxBonuses
                postTaxBonuses
                socialSecurity
                preTaxDeductions
                postTaxDeductions
                standardTaxBonuses
                standardTaxDeductions
                check {
                    checkNumber
                    achCheckNumber
                    amount
                }
                commissionList {
                    approvedJob
                    commissionId
                    approvedTotal
                    approvedAccount
                }
            }
        }
    `;

    static GET_NEW_PAYROLL_CHECK_GENERATED = gql`
        query getNewPayrollCheckGenerated($employeeId: Int!, $payPeriod: String!, $checkData: PayrollCheckDetailInput!) {
            getNewPayrollCheckGenerated(employeeId: $employeeId, payPeriod: $payPeriod, checkData: $checkData) {
                check {
                    paidTo
                    bankAccount
                    lotId
                    lotName
                    memo
                    achCheckNumber
                    checkNumber
                    date
                    amount
                    accountDescription
                }
                lines {
                    accountNumber
                    debit
                    credit
                    controlNumber
                    memo
                }
            }
        }
    `;

    static CAN_GENERATE_PAYROLL_CHECK = gql`
        query canGeneratePayrollCheck($employeeId: Int!, $payPeriod: String!) {
            canGeneratePayrollCheck(employeeId: $employeeId, payPeriod: $payPeriod)
        }
    `;

    static GET_UNPAID_LIST = gql`
        query getPayrollUnpaidList($employeeId: Int!) {
            getPayrollUnpaidList(employeeId: $employeeId) {
                title,
                detail,
            }
        }
    `;
}

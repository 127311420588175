import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import UserContext from 'components/context/UserContext';
import useCustomStockNumber from 'components/hook/inventory/useCustomStockNumber';

function CustomStockWrapper({ record }) {
    const { userInformation } = useContext(UserContext);
    const customizedStockNumber = useCustomStockNumber(userInformation, record);
    return (
        <span>
            {customizedStockNumber}
        </span>
    );
}

CustomStockWrapper.propTypes = {
    record: PropTypes.object.isRequired,
};

export default CustomStockWrapper;

import React, { useEffect, useState } from 'react';

import {
    makeStyles, Button, Grid, Typography,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import DateUtils from 'lib/DateUtils';
import KeyStore from 'utils/KeyStore';
import DatePicker from 'react-datepicker';
import ModalUtils from 'utils/ModalUtils';
import { Col, Form } from 'react-bootstrap';
import { FetchPolicy } from 'utils/enum/Core';
import Select from 'components/widgets/Select';
import { useMutation, useQuery } from '@apollo/client';
import InputNumber from 'components/widgets/InputNumber';
import DropdownQuery from 'components/widgets/DropdownQuery';
import { AccountingCOAType } from 'utils/enum/AccountingEnum';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import PayrollSettingsQuery from 'services/graphQL/query/payroll/Settings';
import PayrollSettingsMutation from 'services/graphQL/mutate/payroll/Settings';
import AccountingCOAQuery from 'services/graphQL/query/accounting/AccountingCOAQuery';
import CustomFieldsDialog from 'components/modules/settings/payroll/CustomFieldsDialog';
import { PayPeriodType, PayPeriodStartOnDay, OvertimeRate } from 'utils/enum/PayrollEnum';
import PayrollDefaultSettingsSchema from 'utils/schema/settings/payroll/PayollDefaultSettingsSchema';

const useStyles = makeStyles((theme) => ({
    box: {
        marginTop: '10px',
        paddingTop: '10px',
        border: `solid 1px ${theme.palette.border.mercury}`,
    },
    labels: {
        fontSize: '0.875rem',
    },
    inputSpinner: {
        fontSize: '14px !important',
    },
    wrapperDate: {
        display: 'flex',
        flex: 1,
    },
    padding: {
        paddingLeft: 15,
        paddingRight: 15,
    },
    paddingStart: {
        paddingLeft: 0,
    },
    radioContainer: {
        height: 31,
    },
    customFieldsText: {
        cursor: 'pointer',
        textDecoration: 'underline',
        marginLeft: theme.spacing(2),
        color: theme.palette.text.infoDark,
    },
}));

const PayPeriodTypeList = [
    { label: PayPeriodType.WEEKLY, value: PayPeriodType.WEEKLY },
    { label: PayPeriodType.BI_WEEKLY, value: PayPeriodType.BI_WEEKLY },
    { label: PayPeriodType.SEMI_MONTHLY, value: PayPeriodType.SEMI_MONTHLY },
    { label: PayPeriodType.MONTHLY, value: PayPeriodType.MONTHLY },
    { label: PayPeriodType.QUARTERLY, value: PayPeriodType.QUARTERLY },
    { label: PayPeriodType.SEMI_ANNUALLY, value: PayPeriodType.SEMI_ANNUALLY },
    { label: PayPeriodType.ANNUALLY, value: PayPeriodType.ANNUALLY },
];

const PayPeriodDayList = [
    { label: PayPeriodStartOnDay.MONDAY, value: PayPeriodStartOnDay.MONDAY },
    { label: PayPeriodStartOnDay.TUESDAY, value: PayPeriodStartOnDay.TUESDAY },
    { label: PayPeriodStartOnDay.WEDNESDAY, value: PayPeriodStartOnDay.WEDNESDAY },
    { label: PayPeriodStartOnDay.THURSDAY, value: PayPeriodStartOnDay.THURSDAY },
    { label: PayPeriodStartOnDay.FRIDAY, value: PayPeriodStartOnDay.FRIDAY },
    { label: PayPeriodStartOnDay.SATURDAY, value: PayPeriodStartOnDay.SATURDAY },
    { label: PayPeriodStartOnDay.SUNDAY, value: PayPeriodStartOnDay.SUNDAY },
];

const overtimeRateList = [
    { label: OvertimeRate[1], value: OvertimeRate[1] },
    { label: OvertimeRate[1.25], value: OvertimeRate[1.25] },
    { label: OvertimeRate[1.5], value: OvertimeRate[1.5] },
    { label: OvertimeRate[1.75], value: OvertimeRate[1.75] },
    { label: OvertimeRate[2], value: OvertimeRate[2] },
];

const CheckType = {
    ACH: 'Ach',
    CHECK: 'Check',
};

const PayrollDefaultSettings = () => {
    const classes = useStyles();
    const keyStore = new KeyStore();
    const lotName = keyStore.getSelectedLot()?.lotName;

    const [state, setState] = useState({
        payPeriod: '',
        defaultBank: 0,
        minimumWage: 0,
        overtimeRate: 0,
        medicareTaxRate: 0,
        payPeriodStarts: '',
        weeklyRegularHours: 0,
        medicareSingleLimit: 0,
        socialSecurityLimit: 0,
        medicareMarriedLimit: 0,
        payPeriodStartDate: null,
        socialSecurityTaxRate: 0,
        medicareAdditionalTaxRate: 0,
        openCustomFieldsDialog: false,
        defaultCheckType: CheckType.CHECK,
    });

    const {
        payPeriod,
        defaultBank,
        minimumWage,
        overtimeRate,
        medicareTaxRate,
        payPeriodStarts,
        defaultCheckType,
        payPeriodStartDate,
        weeklyRegularHours,
        medicareSingleLimit,
        socialSecurityLimit,
        medicareMarriedLimit,
        socialSecurityTaxRate,
        openCustomFieldsDialog,
        medicareAdditionalTaxRate,
    } = state;

    const handleChange = (fieldName, value) => {
        setState((previousState) => ({
            ...previousState,
            [fieldName]: value,
        }));
    };

    const {
        data, loading, error,
    } = useQuery(PayrollSettingsQuery.GET_PAYROLL_DEFAULT_SETTINGS, {
        variables: { lotName: keyStore.getSelectedLot()?.lotName },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading) {
            const { getPayrollDefaultSettings } = data;
            const recordSettings = { ...getPayrollDefaultSettings };
            const { payPeriodStartDate: date, editableCheckNumbers, ...rest } = recordSettings;
            setState((previousState) => ({
                ...previousState,
                ...rest,
                payPeriodStartDate: DateUtils.isValid(date) ? new Date(date) : null,
            }));
        }
    }, [data, loading, error]);

    const [saveData, { loading: updating }] = useMutation(PayrollSettingsMutation.SAVE_PAYROLL_DEFAULT_SETTINGS, {
        onCompleted: (mutationData) => {
            if (mutationData && mutationData.savePayrollDefaultSettings) {
                ModalUtils.successMessage(null, 'Saved Successfully');
            }
        },
        onError: (mutationError) => {
            ModalUtils.errorMessage(null, mutationError);
        },
    });

    const onSave = () => {
        const {
            openCustomFieldsDialog: openDialog,
            ...rest
        } = state;
        saveData({
            variables: {
                input: {
                    ...rest,
                    lotName,
                    payPeriodStartDate: DateUtils.getOnlyDate(payPeriodStartDate),
                },
            },
        });
    };

    const toggleCustomFieldsDialog = () => setState((previousState) => ({
        ...previousState,
        openCustomFieldsDialog: !previousState.openCustomFieldsDialog,
    }));

    const isValidData = isValidSchema(PayrollDefaultSettingsSchema, state);
    const { isValid, errors } = isValidData;

    return (
        <Grid container>
            <Grid item md={5} className={classes.box}>
                <Grid container>
                    <Grid item md={6}>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labels}>Pay Period</Form.Label>
                            <Select
                                size="sm"
                                name="payPeriod"
                                onChange={handleChange}
                                options={PayPeriodTypeList}
                                value={payPeriod || ''}
                                className={isValidField(errors, 'payPeriod') ? 'invalid-field' : ''}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labels}>Regular Hours per week</Form.Label>
                            <InputNumber
                                size="sm"
                                decimalScale={0}
                                value={weeklyRegularHours}
                                onChange={(value) => handleChange('weeklyRegularHours', value)}
                                className={isValidField(errors, 'weeklyRegularHours') ? 'invalid-field' : ''}
                            />
                            {isValidField(errors, 'weeklyRegularHours') && <p className="text-danger">Value must be between 15 and 50</p>}
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labels}>Overtime Rate</Form.Label>
                            <Select
                                size="sm"
                                name="overtimeRate"
                                onChange={handleChange}
                                options={overtimeRateList}
                                value={overtimeRate || ''}
                                className={isValidField(errors, 'overtimeRate') ? 'invalid-field' : ''}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labels}>Minimum Wage per hour</Form.Label>
                            <InputNumber
                                size="sm"
                                decimalScale={2}
                                value={minimumWage}
                                onChange={(value) => handleChange('minimumWage', value)}
                                className={isValidField(errors, 'minimumWage') ? 'invalid-field' : ''}
                            />
                            {isValidField(errors, 'minimumWage') && <p className="text-danger">Value must be between 0.25 and 20</p>}
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labels}>Default Bank</Form.Label>
                            <DropdownQuery
                                name="defaultBank"
                                onChange={handleChange}
                                value={defaultBank}
                                placeholder="Select an account"
                                className={isValidField(errors, 'defaultBank') ? 'invalid-field' : ''}
                                dataSource={{
                                    query: AccountingCOAQuery.GET_ACCOUNTING_COA_LIST,
                                    variables: {
                                        paginate: {
                                            init: 0,
                                            ignoreLimit: true,
                                        },
                                        filters: {
                                            accountTypes: [AccountingCOAType.BANK, AccountingCOAType.CASH],
                                        },
                                    },
                                    idField: 'accountNumber',
                                    descriptionField: 'fullDescription',
                                    rootData: 'getAccountingCOAList.data',
                                }}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labels}>Social Security Limit</Form.Label>
                            <InputNumber
                                size="sm"
                                showCurrency
                                decimalScale={2}
                                thousandSeparator
                                value={socialSecurityLimit}
                                onChange={(value) => handleChange('socialSecurityLimit', value)}
                                className={isValidField(errors, 'socialSecurityLimit') ? 'invalid-field' : ''}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labels}>Social Security Tax Rate</Form.Label>
                            <InputNumber
                                size="sm"
                                decimalScale={3}
                                value={socialSecurityTaxRate}
                                onChange={(value) => handleChange('socialSecurityTaxRate', value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Button
                                size="small"
                                variant="outlined"
                                onClick={onSave}
                                disabled={updating || !isValid}
                                startIcon={<SaveOutlinedIcon />}
                            >
                                {updating ? 'Saving...' : 'Save'}
                                {updating && <CircularProgress size={20} />}
                            </Button>
                        </Form.Group>
                    </Grid>
                    <Grid item md={6}>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labels}>Default Check or ACH</Form.Label>
                            <Form.Group
                                className={`form-check form-check-inline mb-0 ${classes.radioContainer}`}
                            >
                                <Form.Check
                                    type="radio"
                                    label="Check"
                                    id="checkType"
                                    key="checkType"
                                    name="checkType"
                                    className={classes.paddingStart}
                                    onChange={() => handleChange('defaultCheckType', CheckType.CHECK)}
                                    checked={isEmpty(defaultCheckType) || defaultCheckType === CheckType.CHECK}
                                />
                                <Form.Check
                                    type="radio"
                                    label="ACH"
                                    id="achType"
                                    key="achType"
                                    name="achType"
                                    checked={defaultCheckType === CheckType.ACH}
                                    onChange={() => handleChange('defaultCheckType', CheckType.ACH)}
                                />
                            </Form.Group>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labels}>Pay Period Start On</Form.Label>
                            <Select
                                size="sm"
                                name="payPeriodStarts"
                                onChange={handleChange}
                                options={PayPeriodDayList}
                                value={payPeriodStarts || ''}
                                className={isValidField(errors, 'payPeriodStarts') ? 'invalid-field' : ''}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labels}>Pay Period Start Date</Form.Label>
                            <DatePicker
                                size="sm"
                                name="payPeriodStartDate"
                                placeholderText="mm/dd/yyyy"
                                popperContainer={CalendarContainer}
                                selected={payPeriodStartDate}
                                wrapperClassName={classes.wrapperDate}
                                onChange={(date) => handleChange('payPeriodStartDate', date)}
                                className={isValidField(errors, 'payPeriodStartDate')
                                    ? 'invalid-field form-control form-control-sm'
                                    : 'form-control form-control-sm'}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labels}>Medicare Single Limit</Form.Label>
                            <InputNumber
                                size="sm"
                                decimalScale={2}
                                value={medicareSingleLimit}
                                onChange={(value) => handleChange('medicareSingleLimit', value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labels}>Medicare Married Limit</Form.Label>
                            <InputNumber
                                size="sm"
                                decimalScale={2}
                                value={medicareMarriedLimit}
                                onChange={(value) => handleChange('medicareMarriedLimit', value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labels}>Medicare Tax Rate</Form.Label>
                            <InputNumber
                                size="sm"
                                decimalScale={4}
                                value={medicareTaxRate}
                                onChange={(value) => handleChange('medicareTaxRate', value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labels}>Medicare Additional Tax Rate</Form.Label>
                            <InputNumber
                                size="sm"
                                decimalScale={4}
                                value={medicareAdditionalTaxRate}
                                onChange={(value) => handleChange('medicareAdditionalTaxRate', value)}
                            />
                        </Form.Group>
                        <Typography
                            onClick={toggleCustomFieldsDialog}
                            className={classes.customFieldsText}
                        >
                            State Tax Fields
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            {openCustomFieldsDialog && (
                <CustomFieldsDialog
                    lotName={lotName}
                    onClose={toggleCustomFieldsDialog}
                />
            )}
        </Grid>
    );
};
export default PayrollDefaultSettings;

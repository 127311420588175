import React, { Component } from 'react';

// Components and Others
import PropTypes from 'prop-types';
import Search from 'components/widgets/Search';
import DealStyles from 'styles/modules/DealStyles';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import CustomStockWrapper from 'components/modules/deals/read/CustomStockWrapper';
import VehicleDialogContainer from 'components/containers/deals/read/vehicle/VehicleDialogContainer';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import {
    DialogContent, Dialog,
} from '@material-ui/core';
import Table from 'components/widgets/Table';
import NumberUtils from 'lib/NumberUtils';

const styles = (theme) => DealStyles.dialogSearch(theme);

class VehicleDialog extends Component {
    getColumns() {
        const columnStyle = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        };

        return [{
            Header: 'Stock',
            id: 'stockNumber',
            minWidth: 75,
            accessor: 'stockNumber',
            style: columnStyle,
            Cell: (cell) => {
                const {
                    original: {
                        stockNumber, customStockNumber,
                    },
                } = cell;

                return (
                    <CustomStockWrapper
                        record={{ stockNumber, customStockNumber }}
                    />
                );
            },
        }, {
            Header: 'Year',
            id: 'year',
            minWidth: 75,
            accessor: 'year',
            style: columnStyle,
        }, {
            Header: 'Make',
            id: 'make',
            minWidth: 75,
            accessor: 'make',
            style: columnStyle,
        }, {
            Header: 'Model',
            id: 'model',
            minWidth: 75,
            accessor: 'model',
            style: columnStyle,
        }, {
            Header: 'Trim',
            id: 'trim',
            minWidth: 80,
            accessor: 'trim',
            style: columnStyle,
        }, {
            Header: 'Miles',
            id: 'miles',
            minWidth: 75,
            accessor: 'miles',
            style: columnStyle,
            Cell: (props) => {
                const miles = props.value || 0;

                return <span>{NumberUtils.applyThousandsFormat(miles)}</span>;
            },
        }, {
            Header: 'Lot Name',
            id: 'lotName',
            minWidth: 80,
            accessor: 'lotName',
            style: columnStyle,
        }, {
            Header: 'VIN',
            id: 'vin',
            minWidth: 120,
            accessor: 'vin',
            style: columnStyle,
        }];
    }

    render() {
        const {
            props: {
                toggleModal, open, classes,
                onClickPrimary, records, isLoading,
                onSearch, loadMore, totalCount,
                onSelectVehicle, selectedRecord,
                onClickRecord, title, disablePrimaryButton,
                vehicleSearchData: { searchTerm },
            },
        } = this;

        return (
            <Dialog
                open={open}
                fullWidth
                scroll="paper"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    className: classes.dialog,
                }}
            >
                <DialogAppBar title={title} onClose={toggleModal} titleVariant="subtitle1" toolbarSize="sm" />
                <DialogContent className={classes.dialogContent}>
                    <div className={classes.boxSearch}>
                        <Search autoFocus fullWidth type="text" onKeyDown={onSearch} text={searchTerm} />
                    </div>
                    <Table
                        rowSelected
                        load={isLoading}
                        loadMore={loadMore}
                        totalRecords={totalCount}
                        data={records}
                        columns={this.getColumns()}
                        getTrProps={(state, rowInfo) => {
                            const record = rowInfo.original;

                            return {
                                onDoubleClick: () => {
                                    onSelectVehicle(record);
                                },
                                onClick: () => {
                                    onClickRecord(record);
                                },
                            };
                        }}
                    />
                </DialogContent>
                <DialogActions
                    titlePrimary="Ok"
                    onClickSecondary={toggleModal}
                    onClickPrimary={onClickPrimary}
                    disablePrimaryButton={!selectedRecord || disablePrimaryButton}
                />
            </Dialog>
        );
    }
}

VehicleDialog.propTypes = {
    onClickPrimary: PropTypes.func,
    open: PropTypes.bool.isRequired,
    totalCount: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onSearch: PropTypes.func.isRequired,
    loadMore: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
    onSelectVehicle: PropTypes.func.isRequired,
    records: PropTypes.arrayOf(PropTypes.object).isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    selectedRecord: PropTypes.object,
    onClickRecord: PropTypes.func.isRequired,
    title: PropTypes.string,
    disablePrimaryButton: PropTypes.bool,
    vehicleSearchData: PropTypes.object,
};

VehicleDialog.defaultProps = {
    onClickPrimary: () => {},
    // TODO: read more about this
    selectedRecord: null,
    title: 'Vehicles',
    disablePrimaryButton: false,
    vehicleSearchData: {},
};

export default withStyles(styles)(VehicleDialogContainer(VehicleDialog));

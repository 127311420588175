import React, { useContext } from 'react';

// Material UI
import {
    Divider, Grid,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ProcessStage from 'components/modules/crm/widgets/ProcessStage';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import LaunchIcon from '@material-ui/icons/Launch';
import If from 'components/widgets/conditional/If';
import CardVehicle from 'components/modules/lead/widgets/CardVehicle';
import UserContext from 'components/context/UserContext';
import useCustomStockNumber from 'components/hook/inventory/useCustomStockNumber';

// Helpers
import DateUtils from 'lib/DateUtils';
import NumberUtils from 'lib/NumberUtils';
import { modules } from 'utils/enum/modules';

const useStyle = makeStyles((theme = {}) => ({
    label: {
        color: `${theme.palette.text.minsk}`,
        whiteSpace: 'nowrap',
        fontSize: '14px',
        fontWeight: '500',
        textAlign: 'right',
        lineHeight: '20px',
    },
    value: {
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '20px',
        color: theme.palette.text.gray,
        whiteSpace: 'nowrap',
    },
    middleColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    rightColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
    vehicle: {
        padding: 0,
        border: 'none',
        minWidth: 210,
        maxWidth: 210,
    },
    item: {
        display: 'flex',
        padding: theme.spacing(1),
    },
    processStageWrapper: {
        margin: '5px 0px',
        width: '100%',
    },
    step: {
        padding: '2px 0px',
    },
    status: {
        textTransform: 'uppercase',
        marginLeft: theme.spacing(0.5),
        '&.open': {
            color: 'blue',
        },
        '&.sold': {
            color: '#40a63b',
        },
        '&.lost': {
            color: 'red',
        },
    },
    stage: {
        margin: '5px 0px',
    },
    opportunity: {
        textDecoration: 'initial',
        color: theme.palette.text.outerSpace,
    },
    opportunityLink: {
        marginLeft: theme.spacing(1),
    },
    icon: {
        color: theme.palette.text.butterflyBush,
    },
}));

const OpportunityWidgetItem = ({
    processStage, availableCash, vehicle, vehicleYear, vehicleMake, vehicleModel, vehicleTrim,
    crmId, openNewTab, modifiedOn, status, showed, demo, writeUp, finance,
}) => {
    const classes = useStyle();
    const currentModifiedOn = DateUtils.calendarWithTime(modifiedOn);

    const { userInformation } = useContext(UserContext);
    const customizedStockNumber = useCustomStockNumber(
        userInformation,
        { stockNumber: vehicle?.stockNumber, customStockNumber: vehicle?.customStockNumber },
    );

    return (
        <div className={classes.opportunity}>
            <Grid container className={classes.item}>
                <Grid item xs={4}>
                    <CardVehicle
                        className={classes.vehicle}
                        vehicle={vehicle || {
                            year: vehicleYear, make: vehicleMake, model: vehicleModel, trim: vehicleTrim,
                        }}
                    />
                </Grid>
                <Grid item xs={4} className={classes.middleColumn}>
                    <div>
                        <span className={classes.label}>Stock: </span>
                        <span className={classes.value}>
                            {customizedStockNumber ?? 'None'}
                        </span>
                    </div>
                    <div>
                        <span className={classes.label}>Cash Down: </span>
                        <span className={clsx(classes.value)}>
                            {NumberUtils.applyCurrencyFormat(availableCash, '$0,0')}
                        </span>
                    </div>
                </Grid>
                <Grid item xs={4} className={classes.rightColumn}>
                    <div className={classes.processStageWrapper}>
                        <ProcessStage
                            disabled
                            data={{
                                crmId,
                                showed,
                                demo,
                                writeUp,
                                finance,
                            }}
                            stepClass={classes.step}
                        />
                    </div>
                    <div className="d-flex-center">
                        <span className={classes.label}>Status: </span>
                        <span className={clsx(classes.value, classes.status, status.toLowerCase())}>
                            {status}
                        </span>
                        <If condition={openNewTab}>
                            <div className={classes.opportunityLink}>
                                <RouterLink to={`/${modules.OPPORTUNITIES}/${crmId}`} target="_blank">
                                    <LaunchIcon className={classes.icon} />
                                </RouterLink>
                            </div>
                        </If>
                    </div>
                    <div className={classes.stage}>
                        <span className={classes.label}>Stage: </span>
                        <span className={classes.value}>
                            {processStage.trim() || 'Unknown'}
                        </span>
                    </div>
                    <div>
                        <span className={classes.label}>Last Update: </span>
                        <span className={clsx(classes.value)}>
                            {currentModifiedOn}
                        </span>
                    </div>
                </Grid>
            </Grid>
            <Divider />
        </div>
    );
};
OpportunityWidgetItem.defaultProps = {
    availableCash: 0,
    vehicle: null,
    openNewTab: false,
    vehicleYear: null,
    vehicleMake: null,
    vehicleModel: null,
    vehicleTrim: null,
};

OpportunityWidgetItem.propTypes = {
    crmId: PropTypes.string.isRequired,
    processStage: PropTypes.string.isRequired,
    availableCash: PropTypes.number,
    vehicle: PropTypes.object,
    vehicleYear: PropTypes.string,
    vehicleMake: PropTypes.string,
    vehicleModel: PropTypes.string,
    vehicleTrim: PropTypes.string,
    modifiedOn: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    openNewTab: PropTypes.bool,
    showed: PropTypes.bool.isRequired,
    demo: PropTypes.bool.isRequired,
    writeUp: PropTypes.bool.isRequired,
    finance: PropTypes.bool.isRequired,
};

export default OpportunityWidgetItem;

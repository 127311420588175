import CRM from 'utils/enum/scopeMapper/CRM';
import User from 'utils/enum/scopeMapper/User';
import Deals from 'utils/enum/scopeMapper/Deals';
import Forms from 'utils/enum/scopeMapper/Forms';
import Payroll from 'utils/enum/scopeMapper/Payroll';
import Service from 'utils/enum/scopeMapper/Service';
import Inventory from 'utils/enum/scopeMapper/Inventory';
import Collection from 'utils/enum/scopeMapper/Collection';
import General from 'utils/enum/scopeMapper/General';
import PaymentProvider from 'utils/enum/scopeMapper/PaymentProvider';

export default {
    CRM_READ: 'crm:read',
    CRM_CONVERSATION_READ: 'crm.conversation:read',
    CRM_DASHBOARD_READ: 'crm.dashboard.read',
    CRM_CONVERSATION_WRITE: 'crm.conversation:write',
    CRM_CHAT_READ: 'crm.chat:read',
    CRM_CHAT_WRITE: 'crm.chat:write',
    CRM_OPPORTUNITY_READ: 'crm.opportunity:read',
    CRM_LEAD_SMS_WRITE: 'crm.lead.sms:write',
    CRM_LEAD_ACTIVITY_WRITE: 'crm.lead.activity:write',
    CRM_LEAD_TRADE_IN_WRITE: 'crm.lead.trade_in:write',
    CRM_OPPORTUNITY_WRITE: 'crm.opportunity:write',
    CORE_VENDOR_READ: 'core.vendor:read',
    CORE_CATALOGS_READ: 'core.catalogs:read',
    CORE_CATALOGS_WRITE: 'core.catalogs:write',
    SETTINGS_READ: 'settings:read',
    ACCOUNTING_READ: 'accounting:read',
    ACCOUNTING_SETTINGS_READ: 'accounting.settings:read',
    ACCOUNTING_SETTINGS_WRITE: 'accounting.settings:write',
    ACCOUNTING_BANKING_READ: 'accounting.banking:read',
    ACCOUNTING_GENERAL_JOURNAL_READ: 'accounting.general_journal:read',
    ACCOUNTING_GENERAL_JOURNAL_WRITE: 'accounting.general_journal:write',
    ACCOUNTING_BANKING_CHECK_WRITE: 'accounting.banking.check:write',
    ACCOUNTING_BANKING_DEPOSIT_WRITE: 'accounting.banking.deposit:write',
    ACCOUNTING_BANKING_TRANSFER_WRITE: 'accounting.banking.transfer:write',
    ACCOUNTING_BANKING_CHECK_APPROVE: 'accounting.banking.check:approve',
    ACCOUNTING_BANKING_CHECK_PRINT: 'accounting.banking.check:print',
    ACCOUNTING_BANKING_CHECK_VOID: 'accounting.banking.check:void',
    ACCOUNTING_ACCOUNT_RECEIVABLE_READ: 'accounting.account_receivable:read',
    ACCOUNTING_ACCOUNT_RECEIVABLE_COLLECTION_AND_PAYOUTS: 'accounting.account_receivable:collections_and_payouts',
    ACCOUNTING_ACCOUNT_RECEIVABLE_OPEN_BALANCES: 'accounting.account_receivable:open_balances',
    ACCOUNTING_ACCOUNTS_PAYABLE_READ: 'accounting.accounts_payable:read',
    CUSTOMER_READ: 'customer:read',
    CUSTOMER_WRITE: 'customer:write',
    ACCOUNTING_ACCOUNT_RECEIVABLE_EDIT_PAYMENTS: 'accounting.account_receivable:edit_payments',
    ACCOUNTING_ACCOUNT_RECEIVABLE_VOID_PAYMENTS: 'accounting.account_receivable:void_payments',
    CRM_MAILBOX_READ: 'crm.mailbox:read',
    ASSIGN_PAYMENT: 'accounting.account_receivable:assign_payments',
    CRM_MESSAGE_CENTER_READ: 'crm.message_center:read',
    CRM_MESSAGE_CENTER_WRITE: 'crm.message_center:write',
    INVOICE_READ: 'accounting.accounts_payable:read',
    INVOICE_WRITE: 'accounting.accounts_payable:write',
    INVOICE_VOID: 'accounting.accounts_payable:void',
    INVOICE_PAYMENT: 'accounting.accounts_payable:pay',
    CRM_OPPORTUNITY_MANAGEMENT: 'crm.opportunity:management',
    SETTINGS_BULLETIN_BOARD_WRITE: 'settings.bulletin_board:write',
    SETTINGS_GENERAL_READ: 'settings.general:read',
    SETTINGS_GENERAL_WRITE: 'settings.general:write',
    QUERIES_WRITE: 'queries:write',
    CRM_OPPORTUNITY_CHANGE_SALES_REPRESENTATIVE: 'crm.opportunity:change_sales_representative',
    CRM_OPPORTUNITY_CHANGE_RECORD_MANAGER: 'crm.opportunity:change_record_manager',
    CRM_SETTINGS_READ: 'crm.settings:read',
    CRM_SETTINGS_WRITE: 'crm.settings:write',
    ACCOUNTING_COA_READ: 'accounting.settings.coa:read',
    ACCOUNTING_COA_WRITE: 'accounting.settings.coa:write',
    DEALS_SETTINGS_READ: 'deals.settings:read',
    DEALS_SETTINGS_WRITE: 'deals.settings:write',
    DASHBOARD_RECON_MANAGE_APPROVALS: 'dashboard.recon:manage_approvals',
    DASHBOARD_RECON_MANAGE_PARTS_LIST: 'dashboard.recon:manage_parts_list',
    REPORTS_INVENTORY_READ: 'reports.inventory:read',
    REPORTS_FINANCIALS_READ: 'reports.financials:read',
    REPORTS_FINANCIALS_WRITE: 'reports.financials:write',
    BI_SETTINGS_READ: 'bi.settings:read',
    BI_SETTINGS_WRITE: 'bi.settings:write',
    BI_PANEL_READ: 'bi.panel:read',
    ...User,
    ...Payroll,
    ...CRM,
    PARTS_READ: 'parts:read',
    PARTS_WRITE_ADD: 'parts:write_add',
    PARTS_WRITE_EDIT: 'parts:write_edit',
    PARTS_OVERRIDE_PRICE: 'parts:override_price',
    PARTS_FLAGS: 'parts:flags',
    PARTS_CHANGE_PARTS_QUANTITY: 'parts:change_parts_quantity',
    PARTS_TICKET_READ: 'parts.parts_ticket:read',
    PARTS_TICKET_WRITE_ADD: 'parts.parts_ticket:write_add',
    PARTS_TICKET_WRITE_EDIT: 'parts.parts_ticket:write_edit',
    PARTS_TICKET_WRITE_ADD_DELETE_PARTS: 'parts.parts_ticket:add_delete_parts',
    PURCHASE_READ: 'parts.purchase:read',
    PURCHASE_WRITE: 'parts.purchase:write',
    PURCHASE_APPROVAL_READ: 'parts.purchase_approval:read',
    BHPH_SETTINGS_READ: 'bhph.settings:read',
    SETTINGS_BHPH_PORTFOLIO_READ: 'bhph.portfolio:read',
    SETTINGS_BHPH_PORTFOLIO_WRITE: 'bhph.portfolio:write',
    DEALS_DOCUMENTS_READ: 'deals.documents:read',
    DEALS_DOCUMENTS_WRITE: 'deals.documents:write',
    DEALS_DOCUMENTS_DELETE: 'deals.documents:delete',
    MENU_DEALER_COST: 'menu.dealer_cost:read',
    MENU_SETTINGS: 'menu.settings:read',
    MENU_CONTRACTS: 'menu.contracts_all:read',
    INTEGRATIONS_SETTINGS_READ: 'integrations.settings:read',
    INTEGRATIONS_SETTINGS_WRITE: 'integrations.settings:write',
    ...Service,
    ...Inventory,
    ...Collection,
    ...Forms,
    ...Deals,
    ...General,
    ...PaymentProvider,
};

import GraphQLClient from 'services/apollo/GraphQLClient';
import VehicleQuery from 'services/graphQL/query/VehicleQuery';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';

export default class InventoryService {
    constructor() {
        this.graphqlClient = new GraphQLClient();
    }

    /**
     * Get the list of vehicles
     * @param {Object} input
     * @param {Object} input.filter
     * @param {string} input.filter.searchTerm
     * @param {string} input.filter.lotName
     * @param {Object} input.paginate
     * @param {number} input.paginate.init
     * @param {number} input.paginate.limit
     */
    async getVehicles(input) {
        return this.graphqlClient
            .query(VehicleQuery.GET_VEHICLES, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    return { graphQLErrors };
                }

                return { data };
            });
    }

    /**
     * Update vehicle
     * @param {Object} input
     * @param {number} input.stockNumber
     * @param {Object} input.input
     */
    async updateVehicle(input) {
        return this.graphqlClient
            .mutate(InventoryMutation.UPDATE_VEHICLE, input);
    }

    /**
     * Create vehicle
     * @param {Object} input
     */
    async createVehicle() {
        return 0;
    }

    /**
     * Get a Random VIN number
     * @returns {Promise<Object>}
     */
    async getRandomVIN() {
        return this.graphqlClient
            .query(VehicleQuery.GET_RANDOM_VIN)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    return { graphQLErrors };
                }

                return { data };
            });
    }
}

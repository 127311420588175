const GOAL_DATA_TYPE = Object.freeze({
    NUMERIC: 'Numeric',
    ALPHANUMERIC: 'Alphanumeric',
    DATE_TIME: 'Date Time',
});

const ENTITY_SECTION = Object.freeze({
    CATEGORIES: 'Categories',
    RULES: 'Rules',
    CHARTS: 'Charts',
    USERS: 'users',
});

const ENTITY_TYPE = Object.freeze({
    RULE: 'Rule',
    CHART: 'Chart',
    CUSTOM_REPORT: 'Custom Report',
});

const STYLE_COMPONENT = Object.freeze({
    TEXT: 'Text',
    NUMBER: 'Number',
    SELECT: 'Select',
    CHECKBOX: 'Checkbox',
    ANNOTATION: 'Annotation',
    CONDITIONAL_FORMATTING: 'Conditional Formatting',
    DISPLAY_TOTALS: 'Display Totals',
});

const AGGREGATE_FUNCTIONS = Object.freeze({
    AVG: 'AVG',
    COUNT: 'COUNT',
    MAX: 'MAX',
    MIN: 'MIN',
    SUM: 'SUM',
});

const CHART_COLUMN_DATA_TYPE = Object.freeze({
    ALPHANUMERIC: 'Alphanumeric',
    DATE: 'Date',
    FLAG: 'Flag',
    NUMERIC: 'Numeric',
});

const NUMERIC_CONDITIONAL = Object.freeze({
    EQUAL: 'Equal',
    GREATER_EQUAL_THAN: 'Greater/Equal Than',
    LOWER_EQUAL_THAN: 'Lower/Equal Than',
});

const COMPONENT_WIDTH = [
    100,
    75,
    50,
    25,
];

const PRINTABLE_TYPE = Object.freeze({
    JSON: 'json',
    IMAGE: 'image',
    PDF: 'pdf',
});

const ENTITY_FIELD_NAME = Object.freeze({
    SELECTED_EMPLOYEE_TYPE: 'selectedEmployeeType',
    SELECTED_RULE_USER: 'selectedRuleUser',
    SELECTED_CHART_USER: 'selectedChartUser',
    SELECTED_RULE_USERS: 'selectedRuleUsers',
    SELECTED_CHART_USERS: 'selectedChartUsers',
    SELECTED_ENTITY_TYPE: 'selectedEntityType',
});

const FORMATTING_CONDITION = Object.freeze({
    EQUALS: '===',
    DOES_NOT_EQUALS: '!==',
    LESS_THAN: '<',
    LESS_THAN_OR_EQUAL_TO: '<=',
    GREATER_THAN: '>',
    GREATER_THAN_OR_EQUAL_TO: '>=',
    BEGINS_WITH: '.startsWith(*)',
    ENDS_WITH: '.endsWith(*)',
    CONTAINS: '.includes(*)',
    DOES_NOT_CONTAIN: '.includes(*) === false',
});

const COMMON_CONSTANT = Object.freeze({
    PAGINATION_PAGE_SIZE: 5000,
});

const CHART_PALETTE = Object.freeze({
    PALETTE_1: 'palette1',
    PALETTE_2: 'palette2',
    PALETTE_3: 'palette3',
    PALETTE_4: 'palette4',
    PALETTE_5: 'palette5',
    PALETTE_6: 'palette6',
    PALETTE_7: 'palette7',
    PALETTE_8: 'palette8',
    PALETTE_9: 'palette9',
    PALETTE_10: 'palette10',
});

export {
    GOAL_DATA_TYPE,
    ENTITY_SECTION,
    ENTITY_TYPE,
    STYLE_COMPONENT,
    CHART_COLUMN_DATA_TYPE,
    AGGREGATE_FUNCTIONS,
    NUMERIC_CONDITIONAL,
    COMPONENT_WIDTH,
    PRINTABLE_TYPE,
    ENTITY_FIELD_NAME,
    FORMATTING_CONDITION,
    COMMON_CONSTANT,
    CHART_PALETTE,
};

export default class SettingsStyle {
    static content(theme) {
        return ({
            scheduleReport: {
                '& label': {
                    display: 'block',
                    fontSize: '12px',
                    marginBottom: '10px',
                },
            },
            containerTime: {
                display: 'flex',
                alignItems: 'center',
                '& .form-control': {
                    height: 31,
                    fontSize: '0.9rem !important',
                },
                '& .react-numeric-input': {
                    maxWidth: 60,
                    marginRight: 5,
                },
            },
            selectContainer: {
                minWidth: 60,
                margin: 0,
                alignItems: 'center',
                '& > label': {
                    minWidth: 111,
                    marginBottom: 0,
                    marginRight: 10,
                    fontWeight: 500,
                    textAlign: 'end',
                    fontSize: '14px',
                    color: theme.palette.text.minsk,
                },
                '& > *:last-child': {
                    flex: 1,
                    textAlign: 'justify',
                },
            },
            box: {
                height: '100%',
                marginTop: '10px',
                padding: '10px',
                '& > div > button': {
                    marginRight: '10px',
                },
                [theme.breakpoints.down('sm')]: {
                    padding: 0,
                },
            },
            splitter: {
                display: 'flex',
                overflow: 'hidden',
                height: '100%',
                width: '100%',
                '& > .gutter': {
                    marginTop: '32px',
                    marginBottom: '22px',
                },
            },
            container: {
                width: '100%',
                height: '96%',
                paddingLeft: theme.spacing(0.4),
                paddingRight: theme.spacing(0.4),
                [theme.breakpoints.down('sm')]: {
                    marginBottom: '50px',
                },
            },
            label: {
                textAlign: 'left',
                fontWeight: 'bold',
                fontSize: '13px',
                marginBottom: theme.spacing(0.65),
                color: theme.palette.text.darkShark,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                '& > button': {
                    minWidth: '32px',
                },
                '& > button span.MuiButton-startIcon': {
                    marginRight: 0,
                },
            },
            boxSearch: {
                marginBottom: theme.spacing(0.25),
                display: 'flex',
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                },
                '& > div:nth-child(1)': {
                    width: '100%',
                    border: `1px solid ${theme.palette.border.ghost}`,
                },
                '& > div:nth-child(2)': {
                    width: 'auto',
                    display: 'flex',
                    marginLeft: '10px',
                    alignItems: 'center',
                    [theme.breakpoints.down('sm')]: {
                        marginLeft: 0,
                        marginTop: '5px',
                    },
                    '& > div': {
                        width: '250px',
                        marginRight: '5px',
                        '& > div': {
                            height: '34px !important',
                        },
                    },
                    '& > button:nth-child(2)': {
                        height: '100%',
                        marginRight: '5px',
                    },
                    '& > button:nth-child(3)': {
                        height: '100%',
                        width: '100px',
                    },
                },
            },
            globalList: {
                marginTop: '17px',
                height: '96% !important',
            },
            list: {
                height: '91%',
                overflowY: 'auto',
                overflowX: 'hidden',
                border: `1px solid ${theme.palette.border.ghost}`,
                lineHeight: 2,
            },
            listItem: {
                userSelect: 'none',
                whiteSpace: 'nowrap',
                cursor: 'pointer',
                paddingLeft: '5px',
                fontSize: '12px',
                '&:hover': {
                    backgroundColor: theme.palette.background.silver,
                    color: theme.palette.text.darkShark,
                },
            },
            listItemSelected: {
                userSelect: 'none',
                whiteSpace: 'nowrap',
                position: 'relative',
                cursor: 'pointer',
                paddingLeft: '5px',
                fontSize: '12px',
                backgroundColor: theme.palette.info.main,
                color: theme.palette.info.contrastText,
                '& > span': {
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    display: 'inline-block',
                    width: '85%',
                    height: '19px',
                },
                '& > div': {
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    '& > span > button': {
                        minWidth: '25px',
                        backgroundColor: 'transparent',
                        color: theme.palette.text.white,
                        '& span.MuiButton-startIcon': {
                            margin: 0,
                        },
                    },
                },
            },
            AppBar: {
                color: theme.palette.text.white,
                backgroundColor: theme.palette.background.sanMarino,
                '& h4': {
                    color: theme.palette.text.white,
                },
            },
            content: {
                padding: '20px 10px',
                [theme.breakpoints.down('sm')]: {
                    paddingLeft: 0,
                    paddingRight: 0,
                },
            },
            dialogActions: {
                '& > .form-group': {
                    display: 'flex',
                    justifyContent: 'flex-end',
                    paddingRight: '40px',
                    [theme.breakpoints.down('sm')]: {
                        paddingRight: 0,
                    },
                },
                '& > .form-group:nth-child(1)': {
                    justifyContent: 'flex-start !important',
                    marginLeft: '25px',
                    '& > label': {
                        whiteSpace: 'nowrap',
                    },
                    [theme.breakpoints.down('sm')]: {
                        marginLeft: 0,
                    },
                },
            },
            subLabel: {
                fontSize: '14px',
            },
            input: {
                fontSize: '13px',
                '& > div:nth-child(1)': {
                    fontSize: '13px !important',
                },
            },
            '@global': {
                '.css-26l3qy-menu': {
                    minWidth: '150px',
                },
                '.css-26l3qy-menu div, .css-2b097c-menu div': {
                    fontSize: '13px',
                    lineHeight: '1.4',
                },
                '.css-26l3qy-menu > div, .css-2b097c-menu > div': {
                    maxHeight: '130px',
                    overflowX: 'hidden',
                },
            },
            bolded: {
                backgroundColor: theme.palette.background.black,
                color: theme.palette.text.white,
            },
            hiddenColorSelector: {
                width: '0px !important',
                height: '0px !important',
                visibility: 'hidden',
                marginRight: '0 !important',
                border: 0,
            },
            queryWrapper: {
                '& > div:nth-child(1) textarea': {
                    resize: 'none',
                    height: '250px',
                    fontSize: '13px',
                },
                '& > div:nth-child(2) > div': {
                    '& > div:nth-child(3)': {
                        marginTop: '10px',
                        borderRadius: '5px',
                        border: `1px solid ${theme.palette.border.ghost}`,
                        height: '153px',
                        lineHeight: 1.5,
                        marginBottom: '5px',
                        '& > div': {
                            fontSize: '13px',
                            cursor: 'pointer',
                            paddingLeft: '5px',
                            userSelect: 'none',
                        },
                        '& > div:hover': {
                            backgroundColor: theme.palette.text.dodgerBlue,
                            color: theme.palette.text.white,
                        },
                    },
                    '& > div:nth-child(4)': {
                        fontSize: '11px',
                        textAlign: 'justify',
                    },
                },
            },
            instructionsWrapper: {
                '& > div.jodit-container': {
                    height: '200px !important',
                    border: `solid 1px ${theme.palette.border.mercury}`,
                    fontSize: '13px',
                    '& > div.jodit-workplace': {
                        listStylePosition: 'inside',
                    },
                },
            },
            visualizationWrapper: {
                paddingLeft: '15px',
                paddingRight: '15px',
                '& > label': {
                    width: '100%',
                    borderBottom: `1px solid ${theme.palette.border.ghost}`,
                    paddingBottom: '4px',
                    marginBottom: '20px',
                },
                '& > div': {
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginBottom: '10px',
                    [theme.breakpoints.down('sm')]: {
                        flexDirection: 'column',
                        padding: 0,
                        '& > div:nth-child(2)': {
                            marginTop: '10px',
                        },
                        '& > div:nth-child(1), & > div:nth-child(2)': {
                            width: '100% !important',
                            paddingRight: '0px !important',
                        },
                        '& > div:nth-child(3)': {
                            width: '100% !important',
                            marginTop: '10px !important',
                        },
                    },
                    '& > div:nth-child(1)': {
                        width: '250px',
                        paddingRight: '15px',
                        flexShrink: 0,
                        position: 'relative',
                        '& > div > div:hover': {
                            backgroundColor: theme.palette.border.ghost,
                        },
                    },
                    ' & > div:nth-child(2)': {
                        width: '300px',
                        paddingRight: '15px',
                        flexShrink: 0,
                        position: 'relative',
                        '& > div > div:hover': {
                            backgroundColor: theme.palette.border.ghost,
                        },
                    },
                    '& > div:nth-child(3)': {
                        flexGrow: 1,
                        overflow: 'hidden',
                    },
                    '& > div > span': {
                        fontSize: '12px',
                        display: 'block',
                        marginBottom: '10px',
                    },
                    '& > div > div': {
                        display: 'flex',
                        height: '300px',
                        overflowX: 'hidden',
                        overflowY: 'auto',
                        border: `1px solid ${theme.palette.background.whitesmoke}`,
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        alignContent: 'flex-start',
                        padding: '5px',
                        '& > div': {
                            height: '35px',
                            marginRight: '7px',
                            marginBottom: '5px',
                            cursor: 'pointer',
                            padding: 2,
                            '& > svg': {
                                width: '28px',
                                height: '28px',
                            },
                        },
                    },
                },
            },
            selectedChart: {
                backgroundColor: theme.palette.background.sanMarino,
            },
            refresh: {
                position: 'absolute',
                top: '-3px',
                right: '14px',
                height: 'initial !important',
                border: 'initial !important',
                padding: 'initial !important',
                display: 'block !important',
                '& > button': {
                    padding: 0,
                    minWidth: '32px',
                    '& span.MuiButton-startIcon': {
                        margin: 0,
                        '& > svg': {
                            fill: theme.palette.success.main,
                        },
                    },
                },
            },
            noChartType: {
                fontSize: '12px',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
            tabsWrapper: {
                padding: '0px !important',
                overflow: 'hidden !important',
                '& > div.MuiTabs-root': {
                    minHeight: 'initial !important',
                    height: 'initial !important',
                    padding: '0px !important',
                    margin: '0px !important',
                    cursor: 'initial !important',
                    width: '100%',
                    '& > div > div > button': {
                        minWidth: '33%',
                        height: '40px',
                        minHeight: 'initial',
                    },
                },
            },
            tableStyle: {
                width: '100% !important',
                position: 'relative',
                marginBottom: '5px !important',
                paddingTop: '5px',
                paddingBottom: '5px',
                '& > div.MuiInputBase-root': {
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    width: '200px',
                    fontSize: '12px',
                    [theme.breakpoints.down('sm')]: {
                        marginTop: '5px',
                        position: 'relative',
                        width: '100%',
                        top: 'initial',
                        left: 'initial',
                    },
                    '& > div.MuiSelect-root': {
                        padding: '4px',
                    },
                },
                '&:nth-of-type(even)': {
                    backgroundColor: theme.palette.background.whitesmoke,
                },
            },
            optionsWrapper: {
                cursor: 'initial !important',
                paddingLeft: '15px !important',
                paddingRight: '15px !important',
                paddingTop: '20px !important',
                height: '86% !important',
                width: '100%',
                overflowX: 'hidden',
                overflowY: 'auto',
                margin: 0,
                [theme.breakpoints.down('sm')]: {
                    padding: '12px !important',
                    marginRight: '0px !important',
                },
                '& > div.input-tab': {
                    maxHeight: '350px !important',
                    [theme.breakpoints.down('sm')]: {
                        maxHeight: 'initial !important',
                    },
                },
                '& > div.style-tab': {
                    maxHeight: '580px !important',
                    [theme.breakpoints.down('sm')]: {
                        maxHeight: 'initial !important',
                    },
                },
                '& > div': {
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    overflow: 'hidden',
                },
                '& > div > div': {
                    width: '50%',
                    marginBottom: '15px',
                    [theme.breakpoints.down('sm')]: {
                        width: '100%',
                        overflowY: 'auto',
                    },
                    '& > div:nth-child(1)': {
                        fontSize: '13px',
                        fontStyle: 'italic',
                    },
                    '& > div:nth-child(2)': {
                        width: '200px',
                        border: `1px solid ${theme.palette.background.whitesmoke}`,
                        height: '30px',
                        marginTop: '4px',
                        borderRadius: '2px',
                        '& > div': {
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            paddingLeft: '5px',
                            paddingRight: '5px',
                            fontSize: '13px',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            height: '100%',
                            '& > svg': {
                                fill: theme.palette.background.red,
                                width: '20px',
                                height: '20px',
                            },
                        },
                    },
                    '& > label': {
                        marginLeft: '-2px',
                        marginTop: '5px',
                        marginBottom: '5px',
                        minWidth: '120px',
                        '& > span': {
                            padding: 0,
                            marginRight: '5px',
                        },
                    },
                    '& > input': {
                        width: '200px',
                        marginTop: '4px',
                        height: '30px',
                        marginBottom: '5px',
                    },
                    '& > div.select-bootstrap': {
                        width: '200px',
                        marginBottom: '5px',
                        marginTop: '5px',
                    },
                    '& > span': {
                        fontSize: '10px',
                        display: 'block',
                        width: '200px',
                        textAlign: 'right',
                    },
                },
            },
            filtersWrapper: {
                cursor: 'initial !important',
                paddingLeft: '30px !important',
                paddingRight: '30px !important',
                paddingTop: '20px !important',
                height: '86% !important',
                width: '100%',
                overflowX: 'hidden',
                overflowY: 'auto',
                margin: 0,
                '& > div:nth-child(2)': {
                    marginTop: '5px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    '& > input': {
                        width: '200px',
                        marginRight: '5px',
                    },
                    '& > div': {
                        width: '150px',
                        marginRight: '10px',
                    },
                    '& > button': {
                        padding: 0,
                        minWidth: 'auto',
                        '& > span > span': {
                            margin: 0,
                        },
                    },
                },
            },
            columnsDrop: {
                border: `1px solid ${theme.palette.border.ghost} !important`,
                height: '170px !important',
                overflowX: 'hidden',
                overflowY: 'auto',
                marginBottom: 0,
                width: '100% !important',
                borderRadius: '0px !important',
                '& > div': {
                    width: '100%',
                    display: 'flex !important',
                    justifyContent: 'space-between !important',
                    alignItems: 'center !important',
                    paddingLeft: '10px !important',
                    paddingRight: '10px !important',
                    fontStyle: 'initial',
                    fontWeight: 'bold !important',
                    cursor: 'pointer !important',
                    marginTop: '5px',
                    fontSize: '13px !important',
                    height: '25px !important',
                    '&:hover': {
                        backgroundColor: theme.palette.border.ghost,
                    },
                    '& > div': {
                        display: 'flex',
                        alignItems: 'center',
                    },
                    '& > div > div': {
                        marginRight: '5px',
                        marginTop: '1px',
                        height: '18px',
                        '& > div': {
                            padding: 0,
                            '& span': {
                                fontSize: '13px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            },
                        },
                        [theme.breakpoints.down('sm')]: {
                            display: 'none',
                        },
                    },
                    '& > div > svg': {
                        fill: theme.palette.background.red,
                        width: '20px',
                        height: '20px',
                    },
                },
            },
            pivotOption: {
                display: 'flex',
                justifyContent: 'flex-end',
                fontSize: '13px',
                marginRight: '15px',
                marginTop: '5px',
                '& > div': {
                    alignItems: 'center',
                    marginBottom: 0,
                    '& > label': {
                        marginBottom: 0,
                    },
                    '& > span': {
                        padding: 0,
                        paddingLeft: '5px',
                    },
                },
            },
            filtersDrop: {
                height: '82%',
                marginBottom: 0,
                border: `1px solid ${theme.palette.border.ghost}`,
                overflowX: 'hidden',
                overflowY: 'auto',
                '& > div': {
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    fontStyle: 'initial',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    marginTop: '5px',
                    fontSize: '13px',
                    height: '30px',
                    '&:hover': {
                        backgroundColor: theme.palette.border.ghost,
                    },
                    '& > div': {
                        display: 'flex',
                        alignItems: 'center',
                        '& > div': {
                            width: '100px',
                            marginRight: '5px',
                            marginTop: '1px',
                            height: '29px',
                            '& > div': {
                                padding: 0,
                                '& span': {
                                    fontSize: '13px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                },
                            },
                            [theme.breakpoints.down('sm')]: {
                                display: 'none',
                            },
                        },
                        '& > svg': {
                            fill: theme.palette.background.red,
                            width: '25px',
                            height: '25px',
                        },
                    },
                },
            },
            previewWrapper: {
                paddingLeft: '15px',
                paddingRight: '15px',
                position: 'relative',
                marginTop: '20px',
                '& div.columns-list-selector': {
                    maxHeight: '300px',
                },
                '& div.pivot-table-wrapper': {
                    maxHeight: '750px',
                },
                '& > label': {
                    width: '100%',
                    borderBottom: `1px solid ${theme.palette.border.ghost}`,
                    paddingBottom: '10px',
                },
                '& > button': {
                    position: 'absolute',
                    left: '70px',
                    top: '-2px',
                    zIndex: 1000,
                    padding: 0,
                    minWidth: '32px',
                    '& > span > span': {
                        padding: 0,
                        margin: 0,
                        '& > svg': {
                            fontSize: '24px !important',
                            fill: theme.palette.success.main,
                        },
                    },
                },
                '& > div:nth-child(3)': {
                    position: 'relative',
                    minHeight: '350px',
                    borderBottom: `1px solid ${theme.palette.border.ghost}`,
                    [theme.breakpoints.down('sm')]: {
                        minHeight: 'initial',
                    },
                },
                '& > div': {
                    marginBottom: '20px',
                    '& > div': {
                        marginTop: 0,
                    },
                    '& > div:nth-child(2)': {
                        margin: 0,
                    },
                },
            },
            noPreview: {
                width: '100%',
                display: 'flex',
                fontSize: '12px',
                alignItems: 'center',
                justifyContent: 'center',
                height: '200px',
            },
            usersWrapper: {
                paddingLeft: '15px',
                paddingRight: '15px',
                '& > label': {
                    width: '100%',
                    borderBottom: `1px solid ${theme.palette.border.ghost}`,
                    paddingBottom: '4px',
                    marginBottom: '20px',
                },
                '& > div:nth-child(2)': {
                    display: 'flex',
                    [theme.breakpoints.down('sm')]: {
                        flexDirection: 'column',
                    },
                    '& > div:nth-child(1)': {
                        marginRight: '5px',
                        [theme.breakpoints.down('sm')]: {
                            marginRight: 0,
                            marginBottom: '5px',
                        },
                    },
                    '& > div': {
                        width: '250px',
                        [theme.breakpoints.down('sm')]: {
                            width: '100%',
                        },
                    },
                },
            },
            tableContainer: {
                marginTop: '15px',
                height: '300px',
                overflowY: 'hidden',
                overflowX: 'hidden',
                '& > div': {
                    overflow: 'hidden',
                },
                '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
                    backgroundColor: `${theme.palette.background.white} !important`,
                    border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
                    marginBottom: '2px',
                    '& > div': {
                        height: '30px',
                        borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                        alignItems: 'center',
                    },
                },
                '& .ReactVirtualized__Table__rowColumn': {
                    justifyContent: 'left',
                    padding: '7px 5px',
                    fontSize: '12px',
                    color: theme.palette.text.outerSpace,
                    display: 'flex',
                    '& > .MuiTextField-root': {
                        width: '90%',
                        [theme.breakpoints.down('md')]: {
                            width: '100%',
                        },
                    },
                },
                '& .DragHandleIcon': {
                    color: theme.palette.text.waterloo,
                },
            },
            tableHeader: {
                textAlign: 'left',
                color: theme.palette.text.waterloo,
                borderRight: `1px solid ${theme.palette.border.ghost}`,
                height: '100%',
                alignItems: 'center',
            },
            extraStyle: {
                overflowX: 'auto',
                '& > div': {
                    overflow: 'initial',
                },
            },
            text: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textWrap: 'nowrap',
                fontSize: '12px',
            },
            deleteButton: {
                minWidth: '32px',
                '& span.MuiButton-startIcon': {
                    margin: 0,
                },
            },
            addDisabled: {
                '& g': {
                    fill: theme.palette.border.ghost,
                },
            },
            table: {
                width: '100% !important',
                marginBottom: '0px !important',
            },
            conditionalFormatting: {
                height: 'auto !important',
                borderRadius: 'initial !important',
                marginTop: '5px !important',
                position: 'relative',
                width: '100% !important',
                border: `1px solid ${theme.palette.border.ghost} !important`,
                paddingBottom: '10px',
                '& > button': {
                    padding: 0,
                    minWidth: '23px',
                    position: 'absolute',
                    top: '-21px',
                    right: 0,
                    '& > span > span': {
                        margin: 0,
                        '& > svg': {
                            width: '17px',
                            height: '17px',
                        },
                    },
                },
                '& > div': {
                    height: '135px  !important',
                    cursor: 'initial  !important',
                    alignItems: 'flex-start !important',
                    paddingLeft: '0px !important',
                    paddingRight: '0px !important',
                    flexDirection: 'column !important',
                    justifyContent: 'initial !important',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    [theme.breakpoints.down('md')]: {
                        overflowX: 'auto',
                    },
                },
            },
            displayTotals: {
                position: 'absolute',
                top: '4px',
                left: '90px',
                width: 'auto !important',
                padding: 0,
                [theme.breakpoints.down('sm')]: {
                    right: 0,
                    left: 'initial',
                },
                '& svg': {
                    width: '20px',
                    height: '20px',
                },
            },
            annotations: {
                border: 'initial !important',
                height: 'auto !important',
                borderRadius: 'initial !important',
                marginTop: '5px !important',
                position: 'relative',
                width: '90% !important',
                [theme.breakpoints.down('sm')]: {
                    width: '100% !important',
                },
                '& > button': {
                    padding: 0,
                    minWidth: '23px',
                    position: 'absolute',
                    top: '-21px',
                    right: 0,
                    '& > span > span': {
                        margin: 0,
                        '& > svg': {
                            width: '17px',
                            height: '17px',
                        },
                    },
                },
                '& > div': {
                    height: '100px  !important',
                    border: `1px solid ${theme.palette.border.ghost}`,
                    borderWidth: '0.5px',
                    cursor: 'initial  !important',
                    alignItems: 'flex-start !important',
                    paddingLeft: '0px !important',
                    paddingRight: '0px !important',
                    flexDirection: 'column !important',
                    justifyContent: 'initial !important',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                },
            },
            conditionalRow: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '2px',
                width: '100%',
                marginBottom: '5px',
                minWidth: '550px',
                '& > div:nth-child(1)': {
                    width: '90%',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    fontSize: '12px',
                    fontWeight: 'normal',
                    marginRight: '15px',
                    '& > div.select-bootstrap': {
                        width: '120px',
                        marginRight: '1px',
                    },
                    '& > div.select-bootstrap:nth-child(4)': {
                        width: '57px !important',
                    },
                    '& > input': {
                        width: '80px',
                        marginRight: '1px',
                        height: '31px',
                    },
                    '& > button': {
                        padding: '4px',
                        minWidth: '32px',
                        borderRadius: '4px',
                        border: `1px solid ${theme.palette.border.ghost}`,
                        height: '31px',
                        marginRight: '1px',
                        '& > span > span': {
                            marginLeft: 0,
                            marginRight: 0,
                        },
                    },
                },
                '& > div:nth-child(2)': {
                    width: '2%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    '& > button': {
                        height: '31px',
                    },
                },
            },
            annotationRow: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '2px',
                '& > input:nth-child(1)': {
                    marginRight: '1px',
                    height: '25px',
                    width: '120px',
                    borderRadius: 0,
                    flexShrink: 0,
                    [theme.breakpoints.down('sm')]: {
                        width: '100px',
                    },
                },
                '& > input:nth-child(2)': {
                    height: '25px',
                    borderRadius: 0,
                    flexGrow: 1,
                    marginRight: '1px',
                },
                '& > input:nth-child(3)': {
                    borderRadius: 0,
                    marginRight: '10px',
                    flexShrink: 0,
                    width: '19px',
                    height: '25px',
                    '-webkit-appearance': 'initial',
                    border: `1px solid ${theme.palette.border.ghost}`,
                },
                '& > button:nth-child(4)': {
                    flexShrink: 0,
                    minWidth: '20px',
                    borderRadius: 0,
                    '& svg': {
                        width: '15px',
                        height: '15px',
                    },
                },
            },
            globalListItem: {
                marginBottom: '5px',
                userSelect: 'none',
                whiteSpace: 'nowrap',
                cursor: 'pointer',
                paddingLeft: '5px',
                fontSize: '12px',
                display: 'flex',
                justifyContent: 'space-between',
                '&:hover': {
                    backgroundColor: theme.palette.background.silver,
                    color: theme.palette.text.darkShark,
                },
                '& > span': {
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    display: 'inline-block',
                    width: '85%',
                    height: '19px',
                },
                '& > div:nth-child(1)': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                '& > div:nth-child(2) > span > button': {
                    minWidth: 'auto',
                    padding: '2px',
                    marginRight: '5px',
                    '& span.MuiButton-startIcon': {
                        margin: 0,
                        '& > svg': {
                            width: '15px',
                            height: '15px',
                        },
                    },
                },
            },
        });
    }
}

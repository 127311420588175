/* eslint-disable no-param-reassign */
import React, { useEffect, useReducer, useCallback } from 'react';

import clsx from 'clsx';
import { isEmpty } from 'lodash';
import KeyStore from 'utils/KeyStore';
import { Form } from 'react-bootstrap';
import ArrayUtils from 'lib/ArrayUtils';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import Table from 'components/widgets/Table';
import { FetchPolicy } from 'utils/enum/Core';
import MessageUtils from 'utils/MessageUtils';
import Filter from 'components/widgets/Filter';
import Permission from 'utils/enum/Permissions';
import { usePlaidLink } from 'react-plaid-link';
import Loading from 'components/widgets/Loading';
import { makeStyles, Button } from '@material-ui/core';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { AddIcon, EditOutlinedIcon, LinkIcon } from 'components/icons';
import PlaidMappingsQuery from 'services/graphQL/query/plaid/PlaidMappingsQuery';
import PlaidMappingDialog from 'components/modules/settings/plaid/PlaidMappingDialog';
import PlaidMappingsMutation from 'services/graphQL/mutate/plaid/PlaidMappingsMutation';

const useStyles = makeStyles((theme) => ({
    tableHeight: {
        height: 'calc(95vh - 165px)',
    },
    boxContent: {
        overflow: 'hidden',
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flex: 1,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    buttonSpacing: {
        '& > *': {
            margin: theme.spacing(0.3),
        },
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    hidden: {
        display: 'none',
    },
    actionButtonSuccess: {
        color: theme.palette.text.outerSpace,
    },
    filterContainer: {
        height: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '& > div': {
            position: 'absolute',
            left: '5px',
            width: '15%',
            textAlign: 'left',
            display: 'flex',
            paddingTop: '4px',
            paddingBottom: '4px',
        },
    },
    loadingIndicator: {
        flex: 1,
        height: '100%',
    },
    linkButton: {
        marginLeft: 'auto',
    },
}));

const ACTION_TYPES = {
    INIT_STATE: 'initState',
    APPLY_FILTER: 'applyFilter',
    TOGGLE_ACTIVE: 'toggleActive',
    SET_PLAID_LINK_TOKEN: 'setPlaidLinkToken',
    TOGGLE_ADD_EDIT_DIALOG: 'toggleAddEditDialog',
};

const reducer = (state, action) => {
    const {
        type,
        value,
        accessor,
    } = action;
    switch (type) {
    case ACTION_TYPES.INIT_STATE:
        return update(state, {
            items: { $set: value },
            loadTable: { $set: true },
            selectedMapId: { $set: '' },
            openAddEditDialog: { $set: false },
        });
    case ACTION_TYPES.TOGGLE_ADD_EDIT_DIALOG:
        const { openAddEditDialog } = state;
        return update(state, {
            selectedMapId: { $set: value },
            openAddEditDialog: { $set: !openAddEditDialog },
        });
    case ACTION_TYPES.APPLY_FILTER:
        const { filtersApplied } = state;
        const filtered = [...filtersApplied];
        let insertNewFilter = 1;

        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter.id === accessor) {
                    if (value === '' || !value.length) filtered.splice(i, 1);
                    else filter.value = value;
                    insertNewFilter = 0;
                }
            });
        }

        if (insertNewFilter && value.length) {
            filtered.push({ id: accessor, value });
        }
        return update(state, {
            filtersApplied: { $set: filtered },
        });
    case ACTION_TYPES.TOGGLE_ACTIVE:
        const { active } = state;
        return update(state, {
            active: { $set: !active },
        });
    case ACTION_TYPES.SET_PLAID_LINK_TOKEN:
        return update(state, {
            plaidLinkToken: { $set: value },
        });
    default: return state;
    }
};

const initState = {
    items: [],
    active: true,
    loadTable: false,
    selectedMapId: '',
    filtersApplied: [],
    plaidLinkToken: '',
    openAddEditDialog: false,
};

const PlaidMappings = () => {
    const classes = useStyles();
    const keyStore = new KeyStore();
    const [state, dispatch] = useReducer(reducer, initState);

    const {
        openAddEditDialog, filtersApplied, active,
        items, selectedMapId, loadTable, plaidLinkToken,
    } = state;

    const [savePlaidBank] = useMutation(PlaidMappingsMutation.UPSERT_PLAID_BANK);

    const onSuccess = useCallback(async (publicToken, metaData) => {
        try {
            const input = {
                plaidPublicToken: publicToken,
                institutionName: metaData?.institution?.name,
                institutionId: metaData?.institution?.institution_id,
            };

            const response = await savePlaidBank({
                variables: {
                    input,
                },
            });

            if (response.data?.upsertPlaidBank) {
                ModalUtils.successMessage(null, 'Saved Successfully');
            } else {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', 'Plaid Bank'));
            }
        } catch (err) {
            ModalUtils.errorMessage(err?.graphQLErrors);
        }
    }, [savePlaidBank]);

    const config = {
        onSuccess,
        token: plaidLinkToken,
    };

    const { open: openPlaidDialog } = usePlaidLink(config);

    useEffect(() => {
        if (!isEmpty(plaidLinkToken)) openPlaidDialog();
    }, [plaidLinkToken, openPlaidDialog]);

    const {
        data, loading, error, refetch,
    } = useQuery(PlaidMappingsQuery.GET_PLAID_MAPPING_LIST, {
        variables: { active },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getPlaidLinkToken, {
        data: plaidLinkTokenData,
        error: plaidLinkTokenError,
        loading: plaidLinkTokenLoading,
    }] = useLazyQuery(PlaidMappingsQuery.GET_PLAID_LINK_TOKEN, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading) {
            const { getPlaidMappingList } = data;
            dispatch({
                value: getPlaidMappingList,
                type: ACTION_TYPES.INIT_STATE,
            });
        }
    }, [data, loading, error]);

    useEffect(() => {
        if (plaidLinkTokenError) {
            ModalUtils.errorMessage(plaidLinkTokenError?.graphQLErrors);
            return;
        }

        if (!plaidLinkTokenLoading && !isEmpty(plaidLinkTokenData)) {
            const { getPlaidLinkToken: getPlaidLinkTokenResult } = plaidLinkTokenData;
            dispatch({
                value: getPlaidLinkTokenResult,
                type: ACTION_TYPES.SET_PLAID_LINK_TOKEN,
            });
        }
    }, [plaidLinkTokenData, plaidLinkTokenLoading, plaidLinkTokenError]);

    const toggleAddEdit = ({ id, saving }) => {
        dispatch({
            value: id,
            type: ACTION_TYPES.TOGGLE_ADD_EDIT_DIALOG,
        });
        if (saving) refetch();
    };

    const getFilterValues = (values, columnId) => {
        if (values?.length > 0) {
            const mappedRecords = [
                ...new Map(values
                    .map((a) => ({ value: a[columnId], label: (String(a[columnId]) || '(Blanks)') }))
                    .map((item) => [item.label, item])).values(),
            ];
            const sortedResult = ArrayUtils.sortByObjectField(mappedRecords, 'value');
            return sortedResult;
        }
        return [];
    };

    const onFilteredChangeCustom = (value, accessor) => {
        dispatch({
            value,
            accessor,
            type: ACTION_TYPES.APPLY_FILTER,
        });
    };

    const resetFilters = (columnId) => {
        onFilteredChangeCustom([], columnId);
    };

    const toggleActive = () => dispatch({
        type: ACTION_TYPES.TOGGLE_ACTIVE,
    });

    const getColumns = () => [{
        minWidth: 10,
        id: 'bankAccountNumber',
        accessor: 'bankAccountNumber',
        Header: 'Bank Account #',
        className: 'd-flex-justify-center-align-center',
    }, {
        minWidth: 10,
        Header: 'Last Four',
        id: 'institutionAccountLastFour',
        accessor: 'institutionAccountLastFour',
        className: 'd-flex-justify-center-align-center',
    }, {
        minWidth: 40,
        Header: 'Bank Name',
        id: 'institutionName',
        accessor: 'institutionName',
        className: 'd-flex-justify-center-align-center',
    }, {
        minWidth: 40,
        Header: 'Bank Account Name',
        id: 'institutionAccountName',
        accessor: 'institutionAccountName',
        className: 'd-flex-justify-center-align-center',
    }, {
        width: 'auto',
        id: 'actions',
        Header: 'Actions',
        headerClassName: clsx(classes.hidden),
        className: clsx(classes.hidden, 'actionColumnTarget'),
        Cell: (cellData) => {
            const { original: { plaidMappingId } } = cellData;

            return (
                <div className={classes.buttonSpacing}>
                    {keyStore.hasPermission(Permission.INTEGRATIONS_SETTINGS_WRITE) && (
                        <>
                            <Button
                                size="small"
                                variant="outlined"
                                onClick={() => toggleAddEdit({ id: plaidMappingId, saving: false })}
                                startIcon={<EditOutlinedIcon className={classes.actionButtonSuccess} />}
                            >
                                Edit
                            </Button>
                        </>
                    )}
                </div>
            );
        },
    }];

    const loadColumns = () => {
        const columns = getColumns();
        columns.forEach((column) => {
            column.Header = (
                <div
                    className={classes.filterContainer}
                >
                    {column.Header}
                    <div
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        <Filter
                            showTooltip
                            showIconOnly
                            useInternalSearch
                            maxWidthLabel={200}
                            records={getFilterValues(items, column.id)}
                            onClearFilter={() => resetFilters(column.id)}
                            applyFilter={(record) => onFilteredChangeCustom(
                                record.map((item) => (item.label === '(Blanks)' ? '' : item.value)), column.id,
                            )}
                        />
                    </div>
                </div>
            );
        });
        return columns;
    };

    return (
        <>
            <div className={classes.root}>
                <div className={classes.header}>
                    {keyStore.hasPermission(Permission.PAYROLL_MAPPINGS_WRITE) && (
                        <Button
                            size="small"
                            variant="outlined"
                            startIcon={<AddIcon />}
                            onClick={() => toggleAddEdit({ id: '', saving: false })}
                        >
                            New Mapping
                        </Button>
                    )}
                    <div className="d-flex-center">
                        <Form.Group
                            className="form-check form-check-inline mb-0"
                        >
                            <Form.Check
                                key="radioActive"
                                type="radio"
                                name="radio"
                                id="radioActive"
                                label="Active"
                                value={active}
                                checked={active}
                                onChange={toggleActive}
                            />
                            <Form.Check
                                key="radioInactive"
                                type="radio"
                                name="radio"
                                id="radioInactive"
                                label="In-Active"
                                value={!active}
                                checked={!active}
                                onChange={toggleActive}
                            />
                        </Form.Group>
                    </div>
                    {keyStore.hasPermission(Permission.PAYROLL_MAPPINGS_WRITE) && (
                        <Button
                            size="small"
                            variant="outlined"
                            startIcon={<LinkIcon />}
                            onClick={getPlaidLinkToken}
                            className={classes.linkButton}
                        >
                            Link Bank Account
                        </Button>
                    )}
                </div>
                <div className={classes.tableHeight}>
                    {!loadTable && (
                        <div className={classes.loadingIndicator}>
                            <Loading />
                        </div>
                    )}
                    {loadTable && (
                        <Table
                            data={items}
                            load={loading}
                            columns={loadColumns()}
                            filtered={filtersApplied}
                            totalRecords={items.count}
                            className="-highlight actionsInLine payrollTable"
                            defaultFilterMethod={(localFilter, row) => {
                                const id = localFilter.pivotId || localFilter.id;
                                if (typeof localFilter.value === 'object') {
                                    return row[id] !== undefined
                                        ? localFilter.value.indexOf(row[id]) > -1
                                        : true;
                                }
                                return row[id] !== undefined
                                    ? String(row[id]).indexOf(localFilter.value) > -1
                                    : true;
                            }}
                        />
                    )}
                </div>
            </div>
            {openAddEditDialog && (
                <PlaidMappingDialog
                    mapId={selectedMapId}
                    onClose={toggleAddEdit}
                />
            )}
        </>
    );
};
export default PlaidMappings;

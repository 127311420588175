import { gql } from '@apollo/client';

export default class ActivityQuery {
    static GET_CRM_ACTIVITIES = gql`
        query getCRMActivities($startDate: Date!, $endDate: Date!, $assignee: [Int!], $lots: [Int!], $active: Boolean) {
            getCRMActivities(startDate: $startDate, endDate: $endDate, assignee: $assignee, lots: $lots, active: $active) {
                __typename
                activityId
                type
                note
                subject
                realizationDate
                modifiedOn
                createdOn
                opportunity {
                    crmId
                    lotId
                    prospect {
                        firstName
                        lastName
                    }
                }
                ... on CallActivity {
                    call {
                        callId
                        numberTo
                        numberFrom
                        duration
                        status
                        result
                    }
                }
                ... on TaskActivity {
                    task {
                        activityTaskId
                        priority
                        status
                    }
                }
                ... on EventActivity {
                    event {
                        activityEventId
                        start
                        end
                        status
                    }
                }
            }
        }
    `;

    static GET_ACTIVITIES = gql`
        query getActivities($processRecordId: ID!, $offset: Int, $limit: Int) {
            getActivities(processRecordId: $processRecordId, offset: $offset, limit: $limit) {
                totalCount
                activities {
                    __typename
                    ... on TaskActivity {
                        activityId
                        type
                        processId
                        processRecordId
                        subject
                        assignee {
                            userId
                            username
                            userPicture
                            firstName
                            lastName
                        }
                        realizationDate
                        note
                        task {
                            activityTaskId
                            activityId
                            priority
                            status
                        }
                        createdBy {
                            userId
                            username
                            userPicture
                            firstName
                            lastName
                        }
                        createdOn
                        modifiedOn
                    }
                    ... on CallActivity {
                        activityId
                        type
                        processId
                        processRecordId
                        subject
                        assignee {
                            userId
                            username
                            userPicture
                            firstName
                            lastName
                        }
                        realizationDate
                        note
                        call {
                            callId
                            activityId
                            status
                            result
                        }
                        createdBy {
                            userId
                            username
                            userPicture
                            firstName
                            lastName
                        }
                        createdOn
                        modifiedOn
                    }
                }
            }
        }
    `;

    static GET_CALL_ACTIVITIES_BY_CRMID = gql`
        query getCallActivitiesByCRMId($crmId: ID!, $paging: DataPaging!) {
            getCallActivitiesByCRMId(crmId: $crmId, paging: $paging) {
                data {
                    activityId
                    type
                    note
                    realizationDate
                    note
                    subject
                    createdOn
                    modifiedOn
                    createdBy {
                        userId
                        firstName
                        middleName
                        lastName
                        avatar
                    }
                    modifiedBy {
                        userId
                        firstName
                        middleName
                        lastName
                        avatar
                    }
                    call {
                        duration
                        status
                        result
                        createdOn
                    }
                }
                totalCount
            }
        }
    `;

    static GET_TASK_ACTIVITIES_BY_CRMID = gql`
        query getTaskActivitiesByCRMId($crmId: ID!, $paging: DataPaging!) {
            getTaskActivitiesByCRMId(crmId: $crmId, paging: $paging) {
                data {
                    activityId
                    type
                    note
                    realizationDate
                    createdOn
                    modifiedOn
                    createdBy {
                        userId
                        firstName
                        middleName
                        lastName
                        avatar
                    }
                    modifiedBy {
                        userId
                        firstName
                        middleName
                        lastName
                        avatar
                    }
                    task {
                        status
                        createdOn
                    }
                }
                totalCount
            }
        }
    `;

    static GET_EVENT_ACTIVITIES_BY_CRMID = gql`
        query getEventActivitiesByCRMId($crmId: ID!, $paging: DataPaging!, $active: Boolean) {
            getEventActivitiesByCRMId(crmId: $crmId, paging: $paging, active: $active) {
                data {
                    activityId
                    type
                    note
                    subject
                    realizationDate
                    modifiedOn
                    createdOn
                    createdBy {
                        userId
                        firstName
                        middleName
                        lastName
                        avatar
                    }
                    modifiedBy {
                        userId
                        firstName
                        middleName
                        lastName
                        avatar
                    }
                    event {
                        start
                        end
                        status
                        createdOn
                    }
                }
                totalCount
            }
        }
    `;

    static GET_NOTE_ACTIVITIES_BY_CRM_ID = gql`
        query getNoteActivitiesByCRMId($crmId: ID! $paging: DataPaging!) {
            getNoteActivitiesByCRMId(crmId: $crmId paging: $paging) {
                totalCount
                data {
                    note
                    realizationDate
                    activityId
                    createdOn
                    modifiedOn
                    createdBy {
                        userId
                        firstName
                        middleName
                        lastName
                        avatar
                    }
                    modifiedBy {
                        userId
                        firstName
                        middleName
                        lastName
                        avatar
                    }
                }
            }
        }
    `;

    static GET_ACTIVITY_CALL_BY_ID = gql`
        query getActivityCallById($crmId: ID!, $activityId: ID!) {
            getActivityCallById(crmId: $crmId, activityId: $activityId) {
                type
                realizationDate
                note
                subject
                assignee
                call {
                    duration
                    status
                    result
                    createdOn
                }
                reminder {
                    reminderOn
                }
                opportunity {
                    crmId
                    leadCode
                    prospect {
                        firstName
                        lastName
                        cellPhone
                        homePhone
                        email
                        allowTextCellPhone
                    }
                }
            }
        }

    `;

    static GET_ACTIVITY_TASK_BY_ID = gql`
        query getActivityTaskById($crmId: ID!, $activityId: ID!) {
            getActivityTaskById(crmId: $crmId, activityId: $activityId) {
                realizationDate
                note
                subject
                assignee
                task {
                    status
                    createdOn
                }
                reminder {
                    reminderOn
                }
                opportunity {
                    crmId
                    leadCode
                    prospect {
                        firstName
                        lastName
                        cellPhone
                        homePhone
                        email
                        allowTextCellPhone
                    }
                }
            }
        }
    `;

    static GET_ACTIVITY_EVENT_BY_ID = gql`
        query getActivityEventById($crmId: ID!, $activityId: ID!) {
            getActivityEventById(crmId: $crmId, activityId: $activityId) {
                realizationDate
                note
                subject
                assignee
                event {
                    start
                    end
                    status
                    createdOn
                }
                reminder {
                    reminderOn
                }
                opportunity {
                    crmId
                    leadCode
                    leadSource
                    leadType
                    dealType
                    availableCash
                    lotId
                    vehicleModel
                    vehicleTrim
                    vehicleYear
                    vehicleMake
                    vehiclePrice
                    vehicle {
                        stockNumber
                        customStockNumber
                        year
                        make
                        model
                        trim
                        thumbnail
                        miles
                        stickerPrice
                        internetPrice
                    }
                    prospect {
                        firstName
                        lastName
                        cellPhone
                        homePhone
                        email
                        workPhone
                        customerCode
                        allowTextCellPhone
                    }
                    chosenVehiclePrice
                }
            }
        }
    `;

    static GET_ACTIVITIES_BY_CRM_ID = gql`
        query getActivitiesByCRMId($crmId: ID!, $paging: DataPaging!, $active: Boolean) {
            getActivitiesByCRMId(crmId: $crmId, paging: $paging, active: $active) {
                totalCount
                data {
                    __typename
                    activityId
                    type
                    note
                    subject
                    realizationDate
                    modifiedOn
                    createdOn
                    createdBy {
                        userId
                        firstName
                        middleName
                        lastName
                        avatar
                    }
                    modifiedBy {
                        userId
                        firstName
                        middleName
                        lastName
                        avatar
                    }
                    ... on CallActivity {
                        call {
                            duration
                            status
                            result
                            createdOn
                        }
                    }
                    ... on TaskActivity {
                        task {
                            status
                            createdOn
                        }
                    }
                    ... on EventActivity {
                        event {
                            start
                            end
                            status
                            createdOn
                        }
                    }
                    ... on ActionActivity {
                        action {
                            data
                            type
                        }
                    }
                }
            }
        }
    `;
}

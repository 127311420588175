import React, {
    useEffect, useReducer, forwardRef, useImperativeHandle, useRef,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import JournalStyles from 'styles/modules/accounting/JournalStyles';
import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Header from 'components/widgets/Header';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { concat, isEmpty } from 'lodash';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import NumberUtils from 'lib/NumberUtils';
import InputControl from 'components/widgets/editorControls/InputControl';
import SelectControl from 'components/widgets/editorControls/SelectControl';
import { useLazyQuery } from '@apollo/client';
import LotQuery from 'services/graphQL/query/LotQuery';
import AccountingCOAQuery from 'services/graphQL/query/accounting/AccountingCOAQuery';
import AccountingGLQuery from 'services/graphQL/query/accounting/AccountingGLQuery';
import JournalDetailMap from 'services/mapData/JournalDetailMap';
import CircularProgress from '@material-ui/core/CircularProgress';
import VirtualTable from 'components/widgets/VirtualTable';
import UploadRowsExcel from 'components/widgets/UploadRowsExcel';
import Summary from 'components/widgets/accounting/Summary';
import { AccountingCOAType } from 'utils/enum/AccountingEnum';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';

const useStyles = makeStyles((theme) => JournalStyles.journalDistributionStyles(theme));
const columnStyles = makeStyles((theme) => AccountingStyles.columnStyles(theme));
const containerStyles = makeStyles((theme) => AccountingStyles.containerStyles(theme));

const ACTION_TYPES = {
    SET_FETCHED_RECORDS: 'setFetchedRecords',
    SET_RECORDS: 'setRecords',
    CHANGE_CELL_RECORDS: 'changeCellRecords',
    ADD_NEW_LINE: 'addNewLine',
    SET_ON_DELETE: 'setOnDelete',
    SET_STATE_VALUES: 'setStateValues',
    VALIDATE_ADD_LINE: 'validateAddLine',
};

const GeneralJournalReducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_FETCHED_RECORDS: {
        let totalDebit = 0;
        let totalCredit = 0;

        action.value.forEach((item) => {
            totalDebit += item.debit;
            totalCredit += item.credit;
        });

        return {
            ...state,
            records: action.value,
            totalDebit,
            totalCredit,
        };
    }
    case ACTION_TYPES.SET_RECORDS: {
        action.notifyDirty();

        return {
            ...state,
            records: action.value,
            entryId: action?.newEntryId ?? state.entryId,
        };
    }
    case ACTION_TYPES.CHANGE_CELL_RECORDS: {
        let totalDebit = 0;
        let totalCredit = 0;
        const {
            value, additionalFields, columnId, cell,
        } = action;
        const keyValue = 'entryId';
        const newRecords = state.records.map((item) => {
            const newItem = { ...item };
            if (item[keyValue] === cell.rowData[keyValue]) {
                newItem[columnId] = value;

                if (columnId === 'accountNumber' && additionalFields) {
                    newItem.isControlled = additionalFields.isControlled;
                    newItem.controlledBy = additionalFields.controlledBy;
                    newItem.accountDescription = additionalFields.fullDescription;
                }

                if (columnId === 'debit' && value > 0) newItem.credit = 0;
                else if (columnId === 'credit' && value > 0) newItem.debit = 0;
            }

            totalDebit += newItem.debit;
            totalCredit += newItem.credit;

            return newItem;
        });

        action.notifyDirty();

        return {
            ...state,
            records: newRecords,
            totalDebit,
            totalCredit,
        };
    }
    case ACTION_TYPES.ADD_NEW_LINE:
    {
        const lastRow = state.records.length > 0 ? state.records[state.records.length - 1] : null;

        const newLine = {
            entryId: state.entryId - 1,
            accountNumber: lastRow?.accountNumber ?? 0,
            debit: 0,
            credit: 0,
            controlNumber: lastRow?.controlNumber ?? '',
            memo: lastRow?.memo ?? action?.value?.memo,
            isControlled: lastRow?.isControlled ?? false,
            controlledBy: lastRow?.controlledBy ?? '',
            lotId: lastRow?.lotId ?? 0,
            lotName: lastRow?.lotName ?? '',
        };
        const data = concat(state.records, newLine);

        return {
            ...state,
            records: data,
            entryId: newLine.entryId,
        };
    }
    case ACTION_TYPES.SET_ON_DELETE: {
        return {
            ...state,
            idToDelete: action.value,
            isModalDeleteOpen: !state.isModalDeleteOpen,
        };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return {
            ...state,
            ...action.value,
        };
    }
    case ACTION_TYPES.VALIDATE_ADD_LINE: {
        const { rowIndex, event } = action;

        if (rowIndex === state.records.length - 1) action.method(state.records, event);

        return {
            ...state,
        };
    }
    default:
        return state;
    }
};

const GeneralJournalDetailDistribution = React.memo(forwardRef((props, ref) => {
    const {
        writePermissions, journalId, onDirty, glOptions, defaultMemo,
    } = props;

    const classes = {
        ...useStyles(),
        ...containerStyles(),
        ...columnStyles(),
    };

    const mountedRef = useRef(true);

    const initialState = {
        records: [],
        isModalDeleteOpen: false,
        idToDelete: 0,
        entryId: 0,
        totalDebit: 0,
        totalCredit: 0,
    };
    const [state, dispatch] = useReducer(GeneralJournalReducer, initialState);

    const [loadData, { loading, error }] = useLazyQuery(AccountingGLQuery.GET_ACCOUNTING_GL_LIST,
        {
            onCompleted: (res) => {
                const { getAccountingGLListForDistribution: { data } } = res;
                const mapValues = JournalDetailMap.glLines(data);

                dispatch({ type: ACTION_TYPES.SET_FETCHED_RECORDS, value: mapValues });
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: FetchPolicy.NETWORK_ONLY,
        });

    const getLineErrors = (records) => {
        const errors = [];

        records.forEach((item, index) => {
            const lineId = index + 1;
            let message = '';
            if (!item.accountNumber || item.accountNumber <= 0) message += ' Account Number is required,';
            if (item.debit + item.credit === 0) message += ' Debit or Credit is required and must be different than zero';
            if (isEmpty(item.lotName.trim())) message += ' Lot name is required,';
            if (item.isControlled && !isEmpty(item.controlledBy) && (!item.controlNumber || (String(item.controlNumber)?.trim() ?? '') === '')) {
                message += ` The account ${item.accountDescription} must have a ${item.controlledBy} as Control number,`;
            }
            if (item.debit < 0 || item.credit < 0) message += ' Debit or Credit cannot be negative,';
            if (item.debit === item.credit) message += ' Debit and Credit cannot be equal,';
            if (item.debit !== 0 && item.credit !== 0) message += ' There cannot be both Debit and Credit in the same line,';

            if (!isEmpty(message)) {
                message = message.substring(0, message.length - 1);
                errors.push({ message: `Line # ${lineId} - ${message}` });
            }
        });

        return errors;
    };

    useImperativeHandle(ref, () => ({
        getRecords() {
            const { records } = state;
            const errors = getLineErrors(records);

            return {
                errors,
                records,
            };
        },
        updatingMemo(newMemo) {
            const newRecords = state?.records?.map((element) => ({
                ...element,
                memo: newMemo,
            }));

            dispatch({
                type: ACTION_TYPES.SET_RECORDS,
                value: newRecords,
                notifyDirty: onDirty,
            });
        },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [state.records]);

    const onAddNewLine = () => {
        dispatch({ type: ACTION_TYPES.ADD_NEW_LINE, value: { memo: defaultMemo } });
    };

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!mountedRef) return;

        if (!isEmpty(journalId)) {
            loadData({
                variables: {
                    paginate: {
                        init: 0,
                        limit: 50,
                        ignoreLimit: true,
                    },
                    filter: {
                        journalId,
                    },
                },
            });
        }

        if (isEmpty(journalId) && state.records.length === 0) onAddNewLine();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [journalId, error]);

    useEffect(() => {
        if (state.entryId !== 0) {
            const rows = state.records.length;
            const nextElement = document.querySelector(`[aria-rowIndex="${rows}"] input.debit-ax-edit-ctrl`);
            if (nextElement && nextElement.focus) {
                nextElement.focus();
                nextElement.select();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.entryId]);

    const onCloseDeleteConfirm = () => {
        dispatch({ type: ACTION_TYPES.SET_ON_DELETE, value: 0 });
    };

    const onDeleteConfirm = () => {
        const dataAfterDeleted = state.records.filter((item) => item.entryId !== state.idToDelete);

        dispatch({
            type: ACTION_TYPES.SET_RECORDS,
            value: dataAfterDeleted,
            notifyDirty: onDirty,
        });
        onCloseDeleteConfirm();
    };

    const handleEditorChange = (columnId, newValue, cell, additionalFields = null) => {
        if (cell.value === newValue) return;

        dispatch({
            type: ACTION_TYPES.CHANGE_CELL_RECORDS,
            columnId,
            value: newValue,
            cell,
            additionalFields,
            notifyDirty: onDirty,
        });
    };

    const handleEditorKeyDown = (cell, event) => {
        const { key, keyCode } = event;
        const { id } = cell.column;
        if (event && (key === 'Tab' || keyCode === 9) && id === 'memo') {
            dispatch({
                type: ACTION_TYPES.VALIDATE_ADD_LINE,
                method: onAddNewLine,
                rowIndex: cell.rowIndex,
                cell,
                notifyDirty: onDirty,
                event,
            });
        }

        if (event && id !== 'memo' && (key === 'Enter' || keyCode === 13 || key === 'ArrowDown' || keyCode === 40)) {
            const nextElement = document.querySelector(`[aria-rowIndex="${cell.rowIndex + 2}"] input.${id}-ax-edit-ctrl`);
            if (nextElement && nextElement.focus) {
                nextElement.focus();
                nextElement.select();
            }
        }

        if (event && id !== 'memo' && (key === 'ArrowUp' || keyCode === 38)) {
            const previousElement = document.querySelector(`[aria-rowIndex="${cell.rowIndex}"] input.${id}-ax-edit-ctrl`);
            if (previousElement && previousElement.focus) {
                previousElement.focus();
            }
        }
    };

    const loadRecords = ({ records, lots }) => {
        if (records) {
            const columnsNameTemplate = [
                {
                    name: ['accountnumber', 'account', 'account#', 'account number'],
                    index: 0,
                },
                {
                    name: ['debit', 'debits'],
                    index: 0,
                },
                {
                    name: ['credit', 'credits'],
                    index: 0,
                },
                {
                    name: ['control#', 'control #', 'controlnumber', 'control number', 'control'],
                    index: 0,
                },
                {
                    name: ['lotname', 'lot name', 'lot'],
                    index: 0,
                },
                {
                    name: ['memo'],
                    index: 0,
                },
            ];
            const { rows } = records;
            const columns = rows[0];

            const columnsName = columnsNameTemplate.map((col) => {
                const currentCol = { ...col };
                columns.every((_, j) => {
                    const columnName = (columns[j] || '').replace(/\s+/g, '').toLowerCase();

                    if (currentCol.name.some((key) => key === columnName)) {
                        currentCol.index = Number(j);
                        return false;
                    }
                    return true;
                });

                return currentCol;
            });

            rows.shift();
            const recordsFromExcel = [];

            rows.forEach((element, i) => {
                const lotName = String((element[columnsName[4].index] || ''))?.trim() || '';
                recordsFromExcel.push({
                    entryId: i * -1,
                    accountNumber: Number(element[columnsName[0].index]) || 0,
                    debit: NumberUtils.round(element[columnsName[1].index] || 0),
                    credit: NumberUtils.round(element[columnsName[2].index] || 0),
                    controlNumber: String(element[columnsName[3].index])?.trim() || '',
                    referenceNumber: '',
                    lotId: lots ? lots.find((item) => item.lotName.toUpperCase() === lotName.toUpperCase())?.lotId : null,
                    lotName,
                    memo: String(element[columnsName[5].index])?.trim() ?? '',
                });
            });

            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    records: recordsFromExcel,
                    entryId: recordsFromExcel[recordsFromExcel.length - 1].entryId,
                },
            });
        }
    };

    const getColumns = () => {
        const columns = [
            {
                headerClassName: classes.columnCenter,
                dataKey: 'line',
                label: 'Line',
                width: 50,
                cellRenderer: (cell) => cell.rowIndex + 1,
            },
            {
                headerClassName: classes.columnLeft,
                dataKey: 'accountNumber',
                label: 'Account',
                width: 250,
                cellRenderer: (cell) => {
                    const {
                        rowData: {
                            accountNumber, accountDescription, entryId, accountType,
                        }, dataKey,
                    } = cell;
                    const accountTypesExcluded = [AccountingCOAType.BANK];
                    const isExcludedAccount = accountTypesExcluded.includes(accountType) || false;
                    if (writePermissions && glOptions.editglAccount && !isExcludedAccount) {
                        return (
                            <SelectControl
                                name={dataKey}
                                value={accountNumber}
                                editorCellObject={cell}
                                placeHolder="select an account"
                                onChange={handleEditorChange}
                                className={accountNumber > 0 ? '' : 'invalid-field'}
                                dataSource={{
                                    query: AccountingCOAQuery.GET_ACCOUNTING_COA_LIST,
                                    variables: {
                                        paginate: {
                                            init: 0,
                                            ignoreLimit: true,
                                        },
                                        filters: {
                                            accountTypesExcluded,
                                        },
                                    },
                                    rootData: 'getAccountingCOAList.data',
                                    idField: 'accountNumber',
                                    descriptionField: 'fullDescription',
                                    additionalFieldsReturned: ['isControlled', 'controlledBy', 'fullDescription'],
                                }}
                                comparePropertyId={entryId}
                            />
                        );
                    }
                    return <span className={classes.paddingLine}>{accountDescription}</span>;
                },
            },
            {
                headerClassName: classes.columnLeft,
                dataKey: 'debit',
                label: 'Debit',
                width: 100,
                cellRenderer: (cell) => {
                    const { rowData: { debit, credit, entryId }, dataKey } = cell;
                    const cellObject = { column: { id: dataKey }, ...cell };
                    if (writePermissions && glOptions.editglAmount) {
                        return (
                            <InputControl
                                name={dataKey}
                                value={debit}
                                className={debit + credit !== 0 ? '' : 'invalid-field'}
                                editorCellObject={cellObject}
                                type="number"
                                onChange={handleEditorChange}
                                onKeyDown={handleEditorKeyDown}
                                comparePropertyId={entryId}
                                allowNegative
                            />
                        );
                    }
                    return <span className={classes.paddingLine}>{NumberUtils.applyCurrencyFormat(debit || 0)}</span>;
                },
            },
            {
                headerClassName: classes.columnLeft,
                dataKey: 'credit',
                label: 'Credit',
                width: 100,
                cellRenderer: (cell) => {
                    const { rowData: { debit, credit, entryId }, dataKey } = cell;
                    const cellObject = { column: { id: dataKey }, ...cell };
                    if (writePermissions && glOptions.editglAmount) {
                        return (
                            <InputControl
                                name={dataKey}
                                value={credit}
                                className={debit + credit !== 0 ? '' : 'invalid-field'}
                                editorCellObject={cellObject}
                                type="number"
                                onChange={handleEditorChange}
                                onKeyDown={handleEditorKeyDown}
                                comparePropertyId={entryId}
                                allowNegative
                            />
                        );
                    }
                    return <span className={classes.paddingLine}>{NumberUtils.applyCurrencyFormat(debit || 0)}</span>;
                },
            },
            {
                headerClassName: classes.columnLeft,
                dataKey: 'controlNumber',
                label: 'Control #',
                width: 120,
                cellRenderer: (cell) => {
                    const {
                        rowData: {
                            isControlled, controlledBy, controlNumber, entryId,
                        }, dataKey,
                    } = cell;
                    const controlledByValue = isControlled ? controlledBy ?? '' : '';
                    const cellObject = { column: { id: dataKey }, ...cell };
                    if (writePermissions && glOptions.editglControlNumber) {
                        return (
                            <InputControl
                                name={dataKey}
                                value={controlNumber}
                                editorCellObject={cellObject}
                                className={controlledByValue === '' || (controlledByValue !== '' && controlNumber !== '') ? '' : 'invalid-field'}
                                onChange={handleEditorChange}
                                onKeyDown={handleEditorKeyDown}
                                comparePropertyId={entryId}
                            />
                        );
                    }
                    return <span className={classes.paddingLine}>{controlNumber}</span>;
                },
            },
            {
                headerClassName: classes.columnLeft,
                dataKey: 'lotName',
                label: 'Lot Name',
                width: 200,
                cellRenderer: (cell) => {
                    const { rowData: { lotName, entryId }, dataKey } = cell;
                    if (writePermissions && glOptions.editglLot) {
                        return (
                            <SelectControl
                                editorCellObject={cell}
                                name={dataKey}
                                value={lotName}
                                className={isEmpty(lotName) ? 'invalid-field' : ''}
                                placeHolder="select a lot"
                                onChange={handleEditorChange}
                                dataSource={{
                                    query: LotQuery.GET_LOTS, rootData: 'lotList', idField: 'lotName', descriptionField: 'lotName',
                                }}
                                comparePropertyId={entryId}
                            />
                        );
                    }
                    return <span className={classes.paddingLine}>{lotName}</span>;
                },
            },
            {
                headerClassName: classes.columnLeft,
                dataKey: 'memo',
                label: 'Memo',
                width: 500,
                cellRenderer: (cell) => {
                    const { rowData: { memo, entryId }, dataKey } = cell;
                    const cellObject = { column: { id: dataKey }, ...cell };
                    if (writePermissions && glOptions.editglMemo) {
                        return (
                            <InputControl
                                name={dataKey}
                                value={memo}
                                editorCellObject={cellObject}
                                type="textarea"
                                rows={1}
                                onChange={handleEditorChange}
                                onKeyDown={handleEditorKeyDown}
                                comparePropertyId={entryId}
                            />
                        );
                    }
                    return <span className={classes.paddingLine}>{memo}</span>;
                },
            },
        ];

        if (writePermissions && glOptions.showDeleteAction) {
            columns.push(
                {
                    headerClassName: classes.columnCenter,
                    dataKey: 'actions',
                    key: 'actions',
                    label: 'Actions',
                    width: 70,
                    cellRenderer: (cell) => (
                        <div className={classes.buttonSpacing}>
                            <IconButton
                                className={classes.buttonWrapper}
                                onClick={() => {
                                    dispatch({ type: ACTION_TYPES.SET_ON_DELETE, value: cell.rowData.entryId });
                                }}
                            >
                                <DeleteIcon className={clsx(classes.actionButtonSize, classes.deleteButton)} />
                            </IconButton>
                        </div>
                    ),
                },
            );
        }

        return columns;
    };

    return (
        <div className={classes.flexContainer}>
            {writePermissions && (glOptions.showSplitAction || glOptions.showNewAction)
            && (
                <Header classes={{ toolbar: classes.toolbar }}>
                    <div className={classes.buttonSpacing}>
                        {writePermissions && glOptions.showNewAction && (
                            <Button
                                variant="outlined"
                                startIcon={<AddCircleOutlineIcon />}
                                size="small"
                                className={classes.buttonGreen}
                                onClick={onAddNewLine}
                            >
                                New Line
                            </Button>
                        )}
                        <UploadRowsExcel setLoadRecords={loadRecords} />
                    </div>
                    <>
                        <Summary displayInline debit={state.totalDebit} credit={state.totalCredit} />
                    </>
                </Header>
            )}
            { (loading) && <div className={classes.centerDiv}><CircularProgress size="1em" color="inherit" /></div>}
            { !loading && (
                <div className={clsx(classes.bottomTableHeight)}>
                    <VirtualTable
                        loading={loading}
                        data={state.records}
                        columns={getColumns()}
                    />
                </div>
            )}
            <ConfirmDialog
                title="Confirm remove line"
                description="Are you sure you want to remove this line?"
                open={state.isModalDeleteOpen}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={onCloseDeleteConfirm}
                onClickSecondary={onCloseDeleteConfirm}
                onClickPrimary={onDeleteConfirm}
            />
        </div>
    );
}), (prev, next) => prev.journalId !== next.journalId
&& prev.defaultMemo !== next.defaultMemo);

GeneralJournalDetailDistribution.propTypes = {
    journalId: PropTypes.string.isRequired,
    writePermissions: PropTypes.bool.isRequired,
    onDirty: PropTypes.func,
    glOptions: PropTypes.object,
    defaultMemo: PropTypes.string,
};

GeneralJournalDetailDistribution.defaultProps = {
    onDirty: () => null,
    defaultMemo: '',
    glOptions: {
        editglAccount: true,
        editglAmount: true,
        editglControlNumber: true,
        editglLot: true,
        editglMemo: true,
        showNewAction: true,
        showSplitAction: true,
        showDeleteAction: true,
    },
};

export default GeneralJournalDetailDistribution;

import { gql } from '@apollo/client';

export default class DealsMutate {
    static DELETE_PRODUCT = gql`
        mutation removeProduct($dealProductId: ID!) {
            removeProduct(dealProductId: $dealProductId)
        }
    `;

    static SAVE_PRODUCT = gql`
        mutation saveProducts($data: [DealProductInput]!) {
            saveProducts(data: $data)
        }
    `;

    static UPDATE_DEAL = gql`
        mutation updateDeal($accountNumber: Int!, $input: DealStructure!) {
            updateDeal(accountNumber: $accountNumber, input: $input)
        }
    `;

    static SAVE_WE_OWE = gql`
        mutation saveWeOwe($dealNumber: Int!, $data: [WeOweInput]!, $deleted: [Int]!) {
            saveWeOwe(dealNumber: $dealNumber, data: $data, deleted: $deleted)
        }
    `;

    static REMOVE_TRADE_IN = gql`
        mutation removeTradeIn($dealVehicleTradeId: ID!){
            removeTradeIn(dealVehicleTradeId: $dealVehicleTradeId)
        }
    `;

    static CREATE_TRADE_IN = gql`
        mutation createTradeIn($dealId: Int!, $input: DealVehicleTradeInput!) {
            createTradeIn(dealId: $dealId, input: $input)
        }
    `;

    static UPDATE_TRADE_IN = gql`
        mutation updateTradeIn($dealVehicleTradeId: ID!, $input: DealVehicleTradeInput!) {
            updateTradeIn(dealVehicleTradeId: $dealVehicleTradeId, input: $input)
        }
    `;

    static CREATE_DEAL = gql`
        mutation createDeal($input: DealCreateInput!) {
            createDeal(input: $input) {
                accountNumber
                success
            }
        }
    `;

    static ADD_ADDRESS_TO_PREVIOUS_LIST = gql`
        mutation createDealPreviousAddress($accountNumber: Int!, $customerAddressId: ID!, $customerId: ID!) {
            createDealPreviousAddress(accountNumber: $accountNumber, customerAddressId: $customerAddressId, customerId: $customerId)
        }
    `;

    static REMOVE_ADDRESS_FROM_PREVIOUS = gql`
        mutation deleteDealPreviousAddress($dealPreviousAddressId: ID!) {
            deleteDealPreviousAddress(dealPreviousAddressId: $dealPreviousAddressId)
        }
    `;

    static ADD_EMPLOYMENT_TO_PREVIOUS_LIST = gql`
        mutation assignDealPreviousEmployment($dealId: Int!, $customerEmploymentId: ID!, $customerId: ID!) {
            assignDealPreviousEmployment(dealId: $dealId, customerEmploymentId: $customerEmploymentId, customerId: $customerId)
        }
    `;

    static REMOVE_EMPLOYMENT_FROM_PREVIEWS = gql`
        mutation removeDealPreviousEmployment($dealPreviousEmploymentId: ID!) {
            removeDealPreviousEmployment(dealPreviousEmploymentId: $dealPreviousEmploymentId)
        }
    `;

    static ASSIGN_CO_BUYER = gql`
        mutation assignCoBuyer($dealId: Int!, $customerId: ID!) {
            assignCoBuyer(
                dealId: $dealId
                customerId: $customerId
            )
        }
    `;

    static SET_DEAL_STATUS = gql`
        mutation setDealStatus($dealId: Int!, $stageId: ID!, $forceDelete: Boolean) {
            setDealStatus(dealId: $dealId, stageId: $stageId, forceDelete: $forceDelete)
        }
    `;

    static FLAG_DEAL = gql`
        mutation setDealFlag($dealId: Int!, $flag: DEAL_FLAGS!){
            setDealFlag(dealId: $dealId, flag: $flag)
        }
    `;

    static SEND_TO_TAG_AGENCY = gql`
        mutation sendDealInfoToTagAgency($accountNumber: Int!){
            sendDealInfoToTagAgency(accountNumber: $accountNumber)
        }
    `;

    static TRANSFER_DEAL = gql`
        mutation transferDeal($dealId: Int!, $lotName: String!){
            transferDeal(dealId: $dealId, lotName: $lotName)
        }
    `;

    static SET_FUNDING_STATUS = gql`
        mutation setFundingStatus($dealId: Int!, $fundingStatus: FUNDING_STATUS!){
            setFundingStatus(dealId: $dealId, fundingStatus: $fundingStatus)
        }
    `;

    static DEACTIVATE_DEAL = gql`
        mutation deactivateDeal($dealId: Int!, $forceDelete: Boolean){
            deactivateDeal(dealId: $dealId, forceDelete: $forceDelete)
        }
    `;

    static REACTIVATE_DEAL = gql`
        mutation reactivateDeal($dealId: Int!) {
          reactivateDeal(dealId: $dealId)
        }
    `;

    static POST_ACCOUNT = gql`
        mutation postAccount($accountNumber: Int!, $postDate: Date!) {
            postAccount(accountNumber: $accountNumber, postDate: $postDate)
        }
    `;

    static CREATE_REFERENCE = gql`
        mutation createDealReference($input: DealReferenceCreateInput!) {
            createDealReference(input: $input)
        }
    `;

    static DELETE_REFERENCE = gql`
        mutation deleteDealReference($dealReferenceId: Int!) {
            deleteDealReference(dealReferenceId: $dealReferenceId)
        }
    `;

    static UPDATE_REFERENCE = gql`
        mutation updateDealReference(
            $dealReferenceId: Int!
            $input: DealReferenceUpdateInput!
        ) {
            updateDealReference(dealReferenceId: $dealReferenceId, input: $input)
        }
    `;

    static CHANGE_DEAL_VEHICLE = gql`
        mutation changeDealVehicle($dealId: Int!, $stockNumber: ID!) {
            changeDealVehicle(dealId: $dealId, stockNumber: $stockNumber) {
                success
                id
            }
        }
    `;

    static ASSIGN_BUYER = gql`
        mutation assignBuyer($dealId: Int!, $customerId: ID!) {
            assignBuyer(dealId: $dealId, customerId: $customerId)
        }
    `;

    static CREATE_DEAL_FROM_NEO = gql`
        mutation createDealFromNEO($input: DealCreateInputFromNEO!) {
            createDealFromNEO(input: $input) {
                accountNumber
                success
            }
        }
    `;

    static SAVE_DEAL_NOTE = gql`
        mutation saveDealNote($dealId: Int!, $note: String!) {
            saveDealNote(dealId: $dealId, note: $note) {
                dealId
                success
            }
        }
    `;

    static SET_DEAL_CURRENT_ADDRESS = gql`
        mutation setDealCurrentAddress($input: DealCurrentAddress!){
            setDealCurrentAddress(input: $input)
        }
    `;

    static SET_DEAL_CURRENT_EMPLOYMENT = gql`
        mutation setDealCurrentEmployment($input: DealCurrentEmployment!){
            setDealCurrentEmployment(input: $input)
        }
    `;

    static REMOVE_COBUYER = gql`
        mutation removeCoBuyer($dealId: Int!) {
            removeCoBuyer(dealId: $dealId)
        }
    `;

    static SWAP_BUYER_COBUYER = gql`
        mutation swapBuyerCoBuyer($dealId: Int!) {
            swapBuyerCoBuyer(dealId: $dealId)
        }
    `;

    static UPDATE_INSURANCE = gql`
        mutation updateInsurance(
            $dealId: Int!
            $input: InsuranceInput!
        ) {
            updateInsurance(dealId: $dealId, input: $input)
        }
    `;

    static UPDATE_DEAL_QUICKNOTE= gql`
        mutation updateNote(
            $dealId: Int!
            $notes: String!
        ) {
            updateNote(
                dealId: $dealId
                notes: $notes
            )
        }
    `;

    static CREATE_CUSTOMER_FROM_NEO = gql`
        mutation createCustomerFromNEO($customerApplicationId: Int!, $isBuyer: Boolean!) {
            createCustomerFromNEO(customerApplicationId: $customerApplicationId, isBuyer: $isBuyer) {
                dob
                dln
                ssn
                email
                lastName
                cellPhone
                firstName
                homePhone
                workPhone
                customerId
                middleName
                customerCode
                currentAddress {
                    end
                    city
                    phone
                    start
                    state
                    county
                    country
                    zipCode
                    address1
                    address2
                    parentId
                    customerId
                    totalMonths
                    housingStatus
                    mortgageOrRent
                    customerAddressId
                }
                currentEmployment {
                    city
                    state
                    title
                    county
                    status
                    country
                    zipCode
                    address1
                    address2
                    employer
                    workPhone
                    customerId
                    totalMonths
                    monthlyIncome
                    otherIncomeSource
                    otherIncomeMonthly
                    customerEmploymentId
                }
            }
        }
    `;

    static SEND_TO_RFC = gql`
        mutation sendToRFC($targetCompanyCode: Int!, $accountNumber: Int!) {
            sendToRFC(targetCompanyCode: $targetCompanyCode, accountNumber: $accountNumber)
        }
    `;

    static REJECT_DEAL = gql`
        mutation rejectDeal($transferQueueId: String!) {
            rejectDeal(transferQueueId: $transferQueueId)
        }
    `;

    static ACCEPT_DEAL = gql`
        mutation acceptDeal($transferQueueId: String!, $lotName: String!, $stage: String) {
            acceptDeal(transferQueueId: $transferQueueId, lotName: $lotName, stage: $stage)
        }
    `;

    static ACCEPT_REJECT_MULTIPLE_DEAL = gql`
        mutation acceptRejectMultipleDeal($transferQueueIds: [String]!, $lotName: String, $stage: String, $accept: Boolean!) {
            acceptRejectMultipleDeal(transferQueueIds: $transferQueueIds, lotName: $lotName, stage: $stage, accept: $accept)
        }
    `;

    static CREATE_DEAL_DOCUMENTS = gql`
        mutation createDealDocuments(
            $accountNumber: Int!, $input: [DealDocumentInput!]!
        ) {
            createDealDocuments(accountNumber: $accountNumber, input: $input)
        }
    `;

    static EDIT_DEAL_DOCUMENT_NAME = gql`
        mutation editDealDocumentName(
            $documentId: Int!, $name: String!
        ) {
            editDealDocumentName(documentId: $documentId, name: $name)
        }
    `;

    static DELETE_DEAL_DOCUMENT = gql`
        mutation deleteDealDocument(
            $documentId: Int!
        ) {
            deleteDealDocument(documentId: $documentId)
        }
    `;

    static SEND_MULTIPLE_TO_RFC = gql`
        mutation sendMultipleToRFC($deals: [Int]!) {
            sendMultipleToRFC(deals: $deals)
        }
    `;
}

const CheckTypes = Object.freeze({
    CUSTOMER: 'Customer',
    VENDOR: 'Vendor',
    EMPLOYEE: 'Employee',
    PAYROLL: 'Payroll',
    BILL: 'Bill',
});

const BankingProcessTypes = Object.freeze({
    CHECK: 'Check',
    ACH: 'ACH',
    DEPOSIT: 'Deposit',
    ACH_DEPOSIT: 'ACH Deposit',
    TRANSFER: 'Transfer',
    PAYROLL: 'Payroll',
    BILL: 'Bill',
});

const AccountingCBStatus = Object.freeze({
    NEW: 'New',
    POSTED: 'Posted',
    APPROVED: 'Approved',
    PRINTED: 'Printed',
    CLEARED: 'Cleared',
    VOID: 'Void',
});

const AccountReceivableTransactionType = Object.freeze({
    DEALS: 'Deals',
    SERVICE: 'Service',
    OTHER: 'Other',
    PETTY_CASH: 'Petty Cash',
    PARTS: 'Parts Ticket',
});

const AccountingSubModules = Object.freeze({
    ACCOUNTS_RECEIVABLE: 'accounts-receivable',
    ACCOUNTS_PAYABLE: 'accounts-payables',
    BANKING: 'banking',
    JOURNALS: 'journals',
    DEAL_JOURNALS: 'deal-journals',
    INVENTORY_JOURNALS: 'inventory-journals',
    SERVICE_JOURNALS: 'service-journals',
    PARTS_TICKET_JOURNALS: 'parts-ticket-journals',
});

const AccountsReceivableEntities = Object.freeze({
    DEALS: 'deals',
    SERVICE: 'services',
    OTHER_COLLECTIONS: 'other-collections',
    PETTY_CASH: 'petty-cash',
    SHIFT_COLLECTIONS: 'shift-collections',
    CASHIER_OPEN_BALANCES: 'cashier-open-balances',
    PARTS: 'parts',
});

const CashierPaymentType = Object.freeze({
    CASH: 'Cash',
    CHARGE: 'Charge',
    CHECK: 'Check',
    CASHIER_CHECK: 'CashierCheck',
    CUSTOM_FIELD: 'CustomField',
});

const CashierDirectionOperation = Object.freeze({
    IN: 'IN',
    OUT: 'OUT',
});

const GeneralAction = Object.freeze({
    VOID: 'Void',
    PRINT: 'Print',
    DELETE: 'Delete',
    DUPLICATE: 'Duplicate',
    APPROVE: 'Approve',
    PRINT_REMITTANCE: 'PrintRemittance',
    REFUND: 'Refund',
    UNDO_APPROVE: 'Undo Approval',
});

const ActionTypeLabels = Object.freeze({
    APPROVE: { label: 'Approve Checks', value: 'Approve' },
    UNDO_APPROVE: { label: 'Undo Approve Checks', value: 'Undo Approval' },
    PRINT: { label: 'Print Checks', value: 'Print' },
});

const CashierTakePaymentStatus = Object.freeze({
    NEW: 'New',
    ACTIVE: 'Active',
    DEPOSITED: 'Deposited',
    VOIDED: 'Voided',
    PARTIALLY_DEPOSIT: 'Partially Deposited',
    PROCESSED: 'Processed',
    DECLINED: 'Declined',
});

const ProcessType = Object.freeze({
    BATCH_OUT: 15,
    CHECK: 16,
    DEPOSIT: 17,
    TRANSFER_BANK_ACCOUNT: 18,
    ACCOUNTS_PAYABLE: 19,
    BILL: 20,
    BILL_CREDIT: 21,
    BATCH_OUT_CASH: 22,
    BATCH_OUT_CHARGE: 23,
    BATCH_OUT_CHECK: 24,
    BATCH_OUT_CASHIER_CHECK: 25,
    BATCH_OUT_CUSTOM: 26,
    DEALS: 27,
    SERVICE: 28,
    INVENTORY: 29,
    PURCHASE_ORDER: 30,
});

const AccountingCOAType = Object.freeze({
    ACCOUNTS_PAYABLE: 'Accounts Payable',
    ACCOUNTS_RECEIVABLE: 'Accounts Receivable',
    BANK: 'Bank',
    CASH: 'Cash',
    COST_OF_GOODS_SOLD: 'Cost of Goods Sold',
    EQUITY: 'Equity',
    EXPENSE: 'Expense',
    FIXED_ASSET: 'Fixed Asset',
    INCOME: 'Income',
    INVENTORY: 'Inventory',
    LONG_TERM_LIABILITY: 'Long Term Liability',
    OTHER_ASSET: 'Other Asset',
    OTHER_CURRENT_ASSET: 'Other Current Asset',
    OTHER_INCOME: 'Other Income',
    OTHER_CURRENT_LIABILITY: 'Other Current Liability',
});

const RecurringType = Object.freeze({
    END_BY: 'end by',
    END_AFTER: 'end after',
    INDEFINITELY: 'indefinitely',
});

const InvoiceStatus = Object.freeze({
    NEW: 'New',
    ACTIVE: 'Active',
    VOID: 'Void',
    PAID: 'Paid',
    PARTIALLY_PAID: 'Partially Paid',
});

const InvoicePaidType = Object.freeze({
    ALL: 'All',
    PAID: 'Paid',
    DUE: 'Due',
    VOID: 'Void',
});

const AccountsPayableType = Object.freeze({
    BILL: 'Bill',
    CREDIT: 'Credit',
});

const TransactionType = Object.freeze({
    DEBIT: 'Debit',
    CREDIT: 'Credit',
});

const AccountingCBType = Object.freeze({
    ACH_CHECK_CUSTOMER: 'ACH Check - Customer',
    ACH_CHECK_EMPLOYEE: 'ACH Check - Employee',
    ACH_CHECK_TRANSFER: 'ACH Check - Transfer',
    ACH_CHECK_VENDOR: 'ACH Check - Vendor',
    ACH_DEPOSIT: 'ACH Deposit',
    ACH_DEPOSIT_TRANSFER: 'ACH Deposit - Transfer',
    ACH_PAYROLL_CHECK_EMPLOYEE: 'ACH Payroll Check - Employee',
    BILL_PAYMENT_ACH_CHECK_VENDOR: 'BILL Payment ACH Check - Vendor',
    BILL_PAYMENT_CHECK_VENDOR: 'BILL Payment Check - Vendor',
    CHECK_CUSTOMER: 'Check - Customer',
    CHECK_EMPLOYEE: 'Check - Employee',
    CHECK_VENDOR: 'Check - Vendor',
    DEPOSIT: 'Deposit',
    PAYROLL_CHECK_EMPLOYEE: 'Payroll Check - Employee',
});

const AccountingProcessControl = {
    BATCH_OUT: 'batch-out',
    DEFAULT: 'default',
};

const JournalTypes = Object.freeze({
    GENERAL: 'General Journal',
    DEALS: 'Deals Journal',
    SERVICE: 'Service Journal',
    INVENTORY: 'Inventory Journal',
    PARTS_TICKET: 'Parts Ticket Journal',
});

const ShowJournalType = Object.freeze({
    SHOW_POSTED: '1',
    SHOW_NO_POSTED: '2',
    SHOW_NO_POSTED_ACTIVE: '3',
});

const ControlledBy = Object.freeze({
    STOCK_NUMBER: 'Stock Number',
    DEAL_NUMBER: 'Deal Number',
    RO_INVOICE_NUMBER: 'RO Invoice Number',
    CUSTOMER_NUMBER: 'Customer Number',
    EMPLOYEE_NUMBER: 'Employee Number',
    LEAD_NUMBER: 'Lead Number',
    RECEIPT_NUMBER: 'Receipt Number',
    VENDOR_NUMBER: 'Vendor Number',
    PART_NUMBER: 'Part Number',
    POST_DATE: 'Post Date',
});

const AccountTypeList = [
    { value: AccountingCOAType.ACCOUNTS_PAYABLE },
    { value: AccountingCOAType.ACCOUNTS_RECEIVABLE },
    { value: AccountingCOAType.BANK },
    { value: AccountingCOAType.CASH },
    { value: AccountingCOAType.COST_OF_GOODS_SOLD },
    { value: AccountingCOAType.EQUITY },
    { value: AccountingCOAType.EXPENSE },
    { value: AccountingCOAType.FIXED_ASSET },
    { value: AccountingCOAType.INCOME },
    { value: AccountingCOAType.INVENTORY },
    { value: AccountingCOAType.LONG_TERM_LIABILITY },
    { value: AccountingCOAType.OTHER_ASSET },
    { value: AccountingCOAType.OTHER_CURRENT_ASSET },
    { value: AccountingCOAType.OTHER_INCOME },
    { value: AccountingCOAType.OTHER_CURRENT_LIABILITY },
];

const ControlledByList = [
    { value: ControlledBy.STOCK_NUMBER },
    { value: ControlledBy.DEAL_NUMBER },
    { value: ControlledBy.RO_INVOICE_NUMBER },
    { value: ControlledBy.CUSTOMER_NUMBER },
    { value: ControlledBy.EMPLOYEE_NUMBER },
    { value: ControlledBy.LEAD_NUMBER },
    { value: ControlledBy.RECEIPT_NUMBER },
    { value: ControlledBy.VENDOR_NUMBER },
    { value: ControlledBy.PART_NUMBER },
    { value: ControlledBy.POST_DATE },
];

const AccountingCBStatusList = [
    { label: AccountingCBStatus.CLEARED, value: AccountingCBStatus.CLEARED },
    { label: AccountingCBStatus.POSTED, value: AccountingCBStatus.POSTED },
    { label: AccountingCBStatus.PRINTED, value: AccountingCBStatus.PRINTED },
    { label: AccountingCBStatus.VOID, value: AccountingCBStatus.VOID },
    { label: AccountingCBStatus.APPROVED, value: AccountingCBStatus.APPROVED },
];

const ErrorCodes = Object.freeze({
    DUPLICATE: '100',
});

const ElectronicPaymentOption = Object.freeze({
    ELECTRONIC_PAYMENT: 'Electronic Payment',
    EMAIL_SMART_LINK: 'Email Smart Link',
    SMS_LINK: 'SMS Link',
    KIOSK_OR_PORTAL: 'Kiosk or Portal',
});

export {
    CheckTypes, BankingProcessTypes, AccountingCBStatus, AccountReceivableTransactionType,
    AccountingSubModules, AccountsReceivableEntities, CashierPaymentType, CashierDirectionOperation,
    GeneralAction, CashierTakePaymentStatus, ProcessType, AccountingCOAType, RecurringType,
    InvoiceStatus, InvoicePaidType, AccountsPayableType, TransactionType, AccountingCBType, JournalTypes,
    AccountingProcessControl, ShowJournalType, AccountTypeList, ControlledByList, AccountingCBStatusList,
    ErrorCodes, ElectronicPaymentOption, ActionTypeLabels,
};

import React, { Component } from 'react';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { Form, Col } from 'react-bootstrap';
import CountryUtils from 'utils/CountryUtils';
import { HousingStatus } from 'utils/enum/Customer';
import Select from 'components/widgets/Select';
import InputNumber from 'components/widgets/InputNumber';
import PhoneInput from 'components/widgets/form/PhoneInput';
import NumberFormat from 'react-number-format';
import CustomerStyles from 'styles/modules/CustomerStyles';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import { DefaultAddressSchema } from 'utils/schema/customer/Address';
import DialogActions from 'components/widgets/modal/DialogActions';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import AddressDialogContainer from 'components/containers/widgets/customers/AddressDialogContainer';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import {
    DialogContent, Dialog, Grid,
} from '@material-ui/core';
import DateUtils from 'lib/DateUtils';

// Utils
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';
import SuggestAddress from './SuggestAddress';

const styles = (theme) => CustomerStyles.addressDialog(theme);

const housingStatusOptions = Object.keys(HousingStatus).map((item) => ({ value: HousingStatus[item], label: HousingStatus[item] }));

class AddressDialog extends Component {
    constructor(props) {
        super(props);

        const keyStore = new KeyStore();
        this.CUSTOMER_WRITE = keyStore.hasPermission(Permission.CUSTOMER_WRITE);
    }

    render() {
        const {
            CUSTOMER_WRITE,
            props: {
                open, classes, toggleModal, record, onChangeValue, toggleAddressSuggest,
                openConfirm, toggleConfirm, isSaveOrEdit, openModalSuggestedAddress,
                isEditing, onChangeEndDate, listCity, onChangeCity, validateAddress,
                onEnableDecoder, isDecodingZip, handleCreatedAddress, saving, suggestedAddress,
                onChangeAddress, loadingSuggestedAddress,
            },
        } = this;
        const {
            end, start, address1, address2, city, state, county, zipCode, country,
            mortgageOrRent, housingStatus, phone, isCurrentAddress,
        } = record;
        const prefixTitle = isEditing ? 'Edit' : 'New';
        const isValidDateEnd = DateUtils.isValid(end);
        const isValidData = isValidSchema(DefaultAddressSchema, record);
        const { isValid, errors } = isValidData;

        return (
            <Dialog
                open={open}
                fullWidth
                maxWidth="md"
                PaperProps={{
                    className: classes.dialog,
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogAppBar title={`${prefixTitle} Address`} onClose={toggleModal} iconSize="sm" />
                <DialogContent className={classes.dialogContent}>
                    <Grid container spacing={6} className="am-form">
                        <Grid item xs={6}>
                            <Form.Group as={Col}>
                                <Form.Label className="required">Address Line 1</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={address1}
                                    maxLength="50"
                                    onChange={(e) => onChangeValue('address1', e.target.value)}
                                    className={isValidField(errors, 'address1') ? 'invalid-field' : ''}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Address Line 2</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={address2}
                                    maxLength="50"
                                    onChange={(e) => onChangeValue('address2', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className="remove-mb-form-group">
                                <Form.Row className={classes.rowCountryZip}>
                                    <Form.Group className={classes.country}>
                                        <Form.Label>Country</Form.Label>
                                        <Select
                                            name="country"
                                            value={country}
                                            placeholder="Select"
                                            options={CountryUtils.getCountry()}
                                            maxMenuHeight={170}
                                            onChange={onChangeValue}
                                        />
                                    </Form.Group>
                                    <Form.Group className={classes.zip}>
                                        <Form.Label className="required">Zip Code</Form.Label>
                                        <NumberFormat
                                            disabled={isDecodingZip}
                                            value={zipCode}
                                            format="#####"
                                            onFocus={onEnableDecoder}
                                            onValueChange={({ value }) => onChangeValue('zipCode', value)}
                                            className={clsx('form-control', isValidField(errors, 'zipCode') ? 'invalid-field' : '')}
                                        />
                                    </Form.Group>
                                </Form.Row>
                            </Form.Group>
                            <Form.Group as={Col} className="remove-mb-form-group">
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>State</Form.Label>
                                        <Form.Control
                                            disabled
                                            type="text"
                                            value={state}
                                            onChange={(e) => onChangeValue('state', e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label className="required">City</Form.Label>
                                        <Select
                                            name="city"
                                            allowToCreate
                                            value={city}
                                            options={listCity}
                                            placeholder="Select"
                                            maxMenuHeight={170}
                                            onChange={onChangeCity}
                                            className={isValidField(errors, 'city') ? 'invalid-field' : ''}
                                        />
                                    </Form.Group>
                                </Form.Row>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>County</Form.Label>
                                <Form.Control
                                    disabled
                                    type="text"
                                    value={county}
                                    onChange={(e) => onChangeValue('county', e.target.value)}
                                />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={6}>
                            <Form.Group as={Col}>
                                <Form.Label>Phone Number</Form.Label>
                                <PhoneInput
                                    value={phone}
                                    onChange={(value) => onChangeValue('phone', value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Housing Status</Form.Label>
                                <Select
                                    name="housingStatus"
                                    value={housingStatus}
                                    placeholder="Select"
                                    options={housingStatusOptions}
                                    maxMenuHeight={170}
                                    onChange={(name, value) => {
                                        onChangeValue(name, value);
                                    }}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Rent/Morgage</Form.Label>
                                <InputNumber
                                    value={mortgageOrRent || ''}
                                    showCurrency
                                    thousandSeparator
                                    onChange={(value) => onChangeValue('mortgageOrRent', value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Row>
                                    <Form.Group as={Col} className="full-width-date-picker">
                                        <Form.Label>From</Form.Label>
                                        <DatePicker
                                            selected={start}
                                            className="form-control"
                                            placeholderText="mm/dd/yyyy"
                                            popperContainer={CalendarContainer}
                                            onChange={(date) => onChangeValue('start', date)}
                                            maxDate={end}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} className="full-width-date-picker">
                                        <Form.Label>To</Form.Label>
                                        <DatePicker
                                            disabled={handleCreatedAddress != null}
                                            selected={end}
                                            className="form-control"
                                            placeholderText="mm/dd/yyyy"
                                            popperContainer={CalendarContainer}
                                            onChange={(date) => onChangeEndDate(date)}
                                            minDate={start}
                                        />
                                        <Form.Group controlId="formBasicCheckbox">
                                            <Form.Check
                                                disabled={isValidDateEnd || handleCreatedAddress != null}
                                                checked={isCurrentAddress}
                                                type="checkbox"
                                                label="Is current address"
                                                className="am-mt-8"
                                                onChange={(e) => onChangeValue('isCurrentAddress', e.target.checked)}
                                            />
                                        </Form.Group>
                                    </Form.Group>
                                </Form.Row>
                            </Form.Group>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions
                    onClickSecondary={toggleModal}
                    onClickPrimary={validateAddress}
                    disablePrimaryButton={!isValid || saving || loadingSuggestedAddress}
                    hiddenPrimaryButton={!CUSTOMER_WRITE}
                />
                <ConfirmDialog
                    title="Confirm current address"
                    description="You didn't provided an End Date. Do you want to make this the current address?"
                    open={openConfirm}
                    titlePrimary="Yes"
                    titleSecondary="No"
                    variant="outlined"
                    onClose={toggleConfirm}
                    onClickSecondary={() => isSaveOrEdit(false)}
                    onClickPrimary={() => isSaveOrEdit(true)}
                />
                {openModalSuggestedAddress && (
                    <SuggestAddress
                        currentAddress={record}
                        onClose={toggleAddressSuggest}
                        open={openModalSuggestedAddress}
                        onChangeAddress={onChangeAddress}
                        suggestedAddress={suggestedAddress}
                    />
                )}
            </Dialog>
        );
    }
}

AddressDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    openConfirm: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    isSaveOrEdit: PropTypes.func.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    toggleConfirm: PropTypes.func.isRequired,
    onChangeAddress: PropTypes.func.isRequired,
    validateAddress: PropTypes.func.isRequired,
    onEnableDecoder: PropTypes.func.isRequired,
    suggestedAddress: PropTypes.object.isRequired,
    toggleAddressSuggest: PropTypes.func.isRequired,
    loadingSuggestedAddress: PropTypes.bool.isRequired,
    openModalSuggestedAddress: PropTypes.bool.isRequired,
    record: PropTypes.shape({
        address1: PropTypes.string,
        address2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        county: PropTypes.string,
        zipCode: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        country: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
        ]),
        phone: PropTypes.string,
        housingStatus: PropTypes.string,
        mortgageOrRent: PropTypes.number,
        end: PropTypes.oneOfType([
            PropTypes.instanceOf(Date),
            PropTypes.string,
        ]),
        start: PropTypes.oneOfType([
            PropTypes.instanceOf(Date),
            PropTypes.string,
        ]),
        isCurrentAddress: PropTypes.bool,
    }).isRequired,
    isEditing: PropTypes.bool.isRequired,
    isDecodingZip: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
    listCity: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChangeCity: PropTypes.func.isRequired,
    onChangeEndDate: PropTypes.func.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    handleCreatedAddress: PropTypes.func,
};

AddressDialog.defaultProps = {
    handleCreatedAddress: null,
};

export default withStyles(styles)(AddressDialogContainer(AddressDialog));

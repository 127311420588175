import React, { useContext, useState } from 'react';
import { makeStyles, fade, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Container from 'components/widgets/Container';
import { useHistory, useParams } from 'react-router-dom';
import SettingsStyles from 'styles/modules/accounting/AccountingStyles';
// widgets
import TabPanel from 'components/widgets/tabs/TabPanel';
import { modules } from 'utils/enum/modules';
import AccountingUtils from 'utils/AccountingUtils';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { WidgetsOutlinedIcon } from 'components/icons';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ServiceSubModules } from 'utils/enum/ServiceInvoiceEnum';
import ServiceUtils from 'utils/ServiceUtils';
import { SupervisorAccountOutlined } from '@material-ui/icons';
import ServiceInvoiceMain from 'components/modules/service/serviceInvoice/list/ServiceInvoiceMain';
import TechnicianJobList from 'components/modules/service/serviceInvoice/list/TechnicianJobList';
import OperationCodeMain from 'components/modules/service/opCode/OperationCodeMain';
import UserContext from 'components/context/UserContext';

const useStyles = makeStyles((theme) => SettingsStyles.settingsPanel(fade, theme));
const keyStore = new KeyStore();

const ServiceTabPanel = () => {
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const { submodule } = useParams();

    const subModules = ServiceUtils.getSubModuleList();
    const [subModuleTab, setSubModuleTab] = useState(AccountingUtils.getIndexFromList(subModules, submodule || 0));

    const SERVICE_READ = keyStore.hasPermission(Permission.SERVICE_READ);
    const OPERATION_CODE_READ = keyStore.hasPermission(Permission.SERVICE_OPERATION_CODE_READ);
    const OPERATION_CODE_EDIT = keyStore.hasPermission(Permission.SERVICE_OPERATION_CODE_EDIT);

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChange = (event, newValue) => {
        history.push(`/${modules.SERVICE}/${subModules[newValue]}`);
        setSubModuleTab(newValue);
    };

    return (
        <>
            <AppBar color="default" position="static">
                <Tabs value={subModuleTab} onChange={handleChange} scrollButtons="on">
                    {SERVICE_READ
                    && <Tab label={isMobile ? '' : 'Repairs Orders'} icon={<WidgetsOutlinedIcon />} />}
                    {SERVICE_READ
                    && <Tab label={isMobile ? '' : 'Technicians'} icon={<SupervisorAccountOutlined />} />}
                    {(OPERATION_CODE_READ || OPERATION_CODE_EDIT)
                    && <Tab label={isMobile ? '' : 'Operation Codes'} icon={<WidgetsOutlinedIcon />} />}
                </Tabs>
            </AppBar>
            {SERVICE_READ
            && (
                <TabPanel
                    value={subModuleTab}
                    index={AccountingUtils.getIndexFromList(subModules, ServiceSubModules.REPAIRS_ORDERS)}
                    className={classes.box}
                >
                    <ServiceInvoiceMain />
                </TabPanel>
            )}
            {SERVICE_READ
            && (
                <TabPanel
                    value={subModuleTab}
                    index={AccountingUtils.getIndexFromList(subModules, ServiceSubModules.TECHNICIAN_JOB_LIST)}
                    className={classes.box}
                >
                    <TechnicianJobList />
                </TabPanel>
            )}
            {(OPERATION_CODE_READ || OPERATION_CODE_EDIT)
            && (
                <TabPanel
                    value={subModuleTab}
                    index={AccountingUtils.getIndexFromList(subModules, ServiceSubModules.OPERATION_CODES)}
                    className={classes.box}
                >
                    <OperationCodeMain />
                </TabPanel>
            )}
        </>
    );
};

const ServiceLayout = () => {
    const classes = useStyles();
    const { boxContainer, panelContainer } = classes;
    const { userInformation } = useContext(UserContext);

    if (!userInformation) return null;

    return (
        <Container className={boxContainer}>
            <div className={panelContainer}>
                <ServiceTabPanel />
            </div>
        </Container>
    );
};

export default ServiceLayout;

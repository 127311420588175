/* eslint-disable max-len */
import React from 'react';

// Components and Others
import PropTypes from 'prop-types';
import ButtonStyles from 'styles/theme/Button';
import { makeStyles } from '@material-ui/core/styles';
import { DialogContent, Dialog, Grid } from '@material-ui/core';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        padding: theme.spacing(3, 1.5),
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    row: {
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        '& .title-address': {
            display: 'block',
            marginBottom: '8px',
        },
    },
    warning: {
        color: theme.palette.warning.main,
    },
    ...ButtonStyles.getStyle(theme),
}));

const SuggestAddress = ({
    open, onClose, currentAddress, suggestedAddress,
    onChangeAddress,
}) => {
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="sm"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogAppBar title="Address Verification Confirmation" onClose={onClose} iconSize="sm" />
            <DialogContent className={classes.dialogContent}>
                <Grid container className="am-form">
                    <Grid item xs={6} className={classes.row}>
                        <span className="title-address">Original address:</span>
                        <span>
                            {currentAddress.address1}
                            ,
                        </span>
                        <span>{`${currentAddress.city}, ${currentAddress.state}, ${currentAddress.zipCode}`}</span>
                    </Grid>
                    <Grid item xs={6} className={classes.row}>
                        <span className="title-address">Suggested address:</span>
                        {!suggestedAddress?.streetAddress && <span className={classes.warning}> No address suggested</span>}
                        {suggestedAddress?.streetAddress && (
                            <>
                                <span>
                                    {suggestedAddress.streetAddress}
                                    ,
                                </span>
                                <span>{`${suggestedAddress.city}, ${suggestedAddress.state}, ${suggestedAddress.ZIPCode}`}</span>
                            </>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions
                titlePrimary="Use suggested address"
                titleSecondary="Use original address"
                onClickSecondary={() => onChangeAddress({
                    address: currentAddress.address1,
                    state: currentAddress.state,
                    zipCode: currentAddress.zipCode,
                    city: currentAddress.city,
                })}
                onClickPrimary={() => onChangeAddress({
                    address: suggestedAddress.streetAddress,
                    state: suggestedAddress.state,
                    zipCode: suggestedAddress.ZIPCode,
                    city: suggestedAddress.city,
                })}
                classSecondaryButton={classes.containedGreen}
                disablePrimaryButton={!suggestedAddress?.streetAddress}
            />
        </Dialog>
    );
};

SuggestAddress.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onChangeAddress: PropTypes.func.isRequired,
    currentAddress: PropTypes.object.isRequired,
    suggestedAddress: PropTypes.object.isRequired,
};

export default SuggestAddress;

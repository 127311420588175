/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import { ServiceInvoiceStatus, ServiceJobStatus, PayTypeData } from 'utils/enum/ServiceInvoiceEnum';
import { v1 as uuid } from 'uuid';
import { initServiceState } from 'components/modules/service/serviceInvoice/serviceInvoiceData';
import NumberUtils from 'lib/NumberUtils';
import DateUtils from 'lib/DateUtils';
import CalculationServiceMethodsHelper from 'components/modules/service/serviceInvoice/create/CalculationMethodsHelper';

export const ACTION_TYPES = {
    SET_RECORD: 'setRecord',
    SET_INITIAL_STATE: 'setInitialState',
    SET_EXPAND_COLLAPSE_ALL: 'setExpandCollapseAll',
    SET_SAVE_AND_NEW_SERVICE: 'setSaveAndNewService',
    SET_INSPECTION_LIST: 'setInspectionList',
    SET_SERVICE_SETTINGS: 'setServiceSettings',
    SET_RECON_COST_SETTINGS: 'setReconCostSettings',
    SET_VALUE_CATALOGS: 'setValueCatalogs',
    SET_ON_POPUP_CLOSE: 'setOnPopupClose',
    SET_DEFAULT_LOT: 'setDefaultLot',
    SET_STATE_VALUES: 'setStateValues',
    CHANGE_RECORD_MAIN_INFO: 'changeRecordMainInfo',
    CHANGE_JOB_INFO: 'changeJobInfo',
    ADD_NEW_JOB: 'addNewJob',
    DELETE_JOB: 'deleteJob',
    ADD_NEW_JOB_PART: 'addNewJobPart',
    DELETE_JOB_PART: 'deleteJobPart',
    CHANGE_JOB_PART: 'changeJobPart',
    DELETE_ALL_JOB_PART: 'deleteAllJobPart',
    ON_CHANGE_VEHICLE_INFO: 'onChangeVehicleInfo',
};

const fieldsMainToRecalculate = ['warrantyDeductable', 'discountPercentage', 'discount', 'fees', 'cancellationFee', 'storageFee', 'lotName', 'shopSupplies', 'overrideShopSuppliesFee'];
const fieldsToRecalculate = ['discount', 'approved', 'isSublet', 'payType', 'technicianId', 'hours', 'laborTotal', 'subletCost', 'subletPrice'];
const fieldsPartsToRecalculate = ['quantity', 'netPrice'];

const ServiceInvoiceReducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORD: {
        const { jobs, ...rest } = action.payload;

        return {
            ...state,
            record: rest,
            jobs,
            identifierJobsMainInfo: uuid(),
            identifierJobs: uuid(),
            isDirty: false,
        };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    case ACTION_TYPES.SET_EXPAND_COLLAPSE_ALL: {
        const currentJobs = state.jobs.map((item) => ({
            ...item,
            open: action.payload,
            identifierJob: uuid(),
        }));

        return {
            ...state,
            jobs: currentJobs,
            identifierJobs: uuid(),
        };
    }
    case ACTION_TYPES.SET_SAVE_AND_NEW_SERVICE: {
        const { isInternal } = action.payload;

        return {
            ...initServiceState,
            record: {
                ...initServiceState.record,
                inHouse: isInternal,
                status: ServiceInvoiceStatus.NEW,
            },
        };
    }
    case ACTION_TYPES.SET_INSPECTION_LIST: {
        const { pending, data } = action.payload;
        return {
            ...state,
            record: {
                ...state.record,
                inspectionDone: !pending,
            },
            inspectionList: data,
            isDirty: !pending,
            approvalInspection: pending,
        };
    }
    case ACTION_TYPES.SET_SERVICE_SETTINGS: {
        return {
            ...state,
            serviceSettings: {
                ...state.serviceSettings,
                ...action.payload,
            },
        };
    }
    case ACTION_TYPES.SET_RECON_COST_SETTINGS: {
        return {
            ...state,
            reconCost: {
                ...state.reconCost,
                ...action.payload,
            },
        };
    }
    case ACTION_TYPES.SET_VALUE_CATALOGS: {
        const { jobs } = state;
        const currentJobs = jobs?.map((job) => ({
            ...job,
            identifierJob: uuid(),
            identifierJobsHeader: uuid(),
            identifierJobsMoreInfo: uuid(),
            identifierJobsParts: uuid(),
        })) ?? [];

        const { key, value } = action.value;

        let technicianSettingsList = [];
        if (key === 'userList' && value.length > 0) {
            technicianSettingsList = value?.map((c) => ({
                userId: c.userId,
                payType: c.payType,
                salary: c.salary,
            }));

            return {
                ...state,
                [key]: value,
                jobs: currentJobs,
                serviceSettings: {
                    ...state.serviceSettings,
                    technicianSettings: technicianSettingsList,
                },
                identifierJobsMainInfo: uuid(),
                identifierJobs: uuid(),
            };
        }

        return {
            ...state,
            [key]: value,
            jobs: currentJobs,
            identifierJobsMainInfo: uuid(),
            identifierJobs: uuid(),
        };
    }
    case ACTION_TYPES.SET_ON_POPUP_CLOSE: {
        return {
            ...state,
            couldLostData: true,
        };
    }
    case ACTION_TYPES.SET_DEFAULT_LOT: {
        return {
            ...state,
            record: {
                ...state.record,
                lotId: action.value.lotId,
                lotName: action.value.lotName,
            },
            identifierJob: uuid(),
            identifierJobsHeader: uuid(),
            identifierJobsMoreInfo: uuid(),
            identifierJobsParts: uuid(),
        };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.payload };
    }
    case ACTION_TYPES.CHANGE_RECORD_MAIN_INFO: {
        const {
            field, value,
        } = action.payload;

        const { record } = state;
        if (field === 'estimatedHours') {
            const currentDate = value?._d;
            let hours = currentDate ? new Date(currentDate).getHours() : 0;
            let minutes = currentDate ? new Date(currentDate).getMinutes() : 0;
            const currentEstimateDate = record.estimateDelivery;
            hours = String(hours).length === 1 ? `0${hours}` : hours;
            minutes = String(minutes).length === 1 ? `0${minutes}` : minutes;
            record.estimateDelivery = new Date(`${DateUtils.getOnlyDateString(currentEstimateDate)}T${hours}:${minutes}:00`);
        } else {
            record[field] = value;
        }

        if (fieldsMainToRecalculate.includes(field)) {
            let currentJobs = state.jobs;

            if (field === 'discountPercentage') {
                record.discount = 0;
                currentJobs = state.jobs.map((jobItem) => ({
                    ...jobItem,
                    discount: 0,
                    identifierJob: uuid(),
                    identifierJobsHeader: uuid(),
                }));
            }

            if (field === 'discount') {
                record.discountPercentage = 0;
                const valueSplit = Number(value) === 0 || currentJobs.length === 0 ? 0 : NumberUtils.round(Number(value) / currentJobs.length);

                currentJobs = state.jobs.map((jobItem) => ({
                    ...jobItem,
                    discount: valueSplit,
                    identifierJob: uuid(),
                    identifierJobsHeader: uuid(),
                }));
            }

            if (field === 'lotName') {
                currentJobs = state.jobs.map((jobItem) => ({
                    ...jobItem,
                    identifierJob: uuid(),
                    identifierJobsParts: uuid(),
                }));
            }

            const currentRecord = {
                ...record,
                jobs: currentJobs,
            };

            const recordCalculated = CalculationServiceMethodsHelper.setCalculationsLocal(currentRecord, state.serviceSettings);
            const { jobs: jobsRecalculated, ...rest } = recordCalculated;
            return {
                ...state,
                record: rest,
                jobs: jobsRecalculated,
                identifierJobsMainInfo: uuid(),
                identifierJobs: uuid(),
                isDirty: true,
            };
        }

        return {
            ...state,
            record,
            identifierJobsMainInfo: uuid(),
            isDirty: true,
        };
    }
    case ACTION_TYPES.CHANGE_JOB_INFO: {
        const {
            actionType, jobNumber, value, field,
            methodAddPart, methodDeleteAllPart,
        } = action.payload;

        let currentOpCodeResult = null;

        if (field === 'laborOperationCode') {
            currentOpCodeResult = state.opCodesList.find((c) => Number(c.opCodeId) === Number(value));
        }

        let sumDiscount = 0;
        const currentJobs = state.jobs.map((item) => {
            if (item.jobNumber === jobNumber) {
                const cloneItem = { ...item };
                if (actionType === 'expandeOrCollapse') {
                    cloneItem.open = value;
                }
                if (actionType === 'header') {
                    cloneItem[field] = value;
                    if (field === 'subletCost' || field === 'overrideSubletPrice') {
                        const subletRate = NumberUtils.round((state.record.inHouse ? state.serviceSettings.internalSubletPriceMarkUp
                            : state.serviceSettings.customerSubletPriceMarkUp) / 100);

                        cloneItem.subletPrice = (cloneItem.overrideSubletPrice ? cloneItem.subletPrice
                            : (Number(cloneItem.subletCost) * subletRate) + cloneItem.subletCost);
                    }

                    if (field === 'discount') {
                        sumDiscount += cloneItem.discount;
                    }

                    cloneItem.identifierJobsHeader = uuid();
                }
                if (actionType === 'moreInfo') {
                    cloneItem[field] = value;
                    cloneItem.identifierJobsMoreInfo = uuid();
                }

                if (field === 'isSublet' && !value) {
                    cloneItem.subletVendorId = null;
                    cloneItem.subletCost = 0;
                    cloneItem.subletPrice = 0;
                    cloneItem.subletInvoice = '';
                    cloneItem.overrideSubletPrice = false;
                }

                if (field === 'isSublet' && value) {
                    cloneItem.technicianId = 0;
                    cloneItem.technician = '';
                    cloneItem.hours = 0;
                    cloneItem.flaggedHours = 0;
                    cloneItem.laborTotal = 0;
                }

                if (field === 'laborOperationCode' && currentOpCodeResult) {
                    cloneItem.customerStates = currentOpCodeResult.customerStates ?? '';
                    cloneItem.cause = currentOpCodeResult.cause ?? '';
                    cloneItem.correction = currentOpCodeResult.correction ?? '';

                    cloneItem.hours = currentOpCodeResult.hours;
                    cloneItem.laborTotal = currentOpCodeResult.laborAmount;
                    cloneItem.isHrsChecked = !cloneItem.laborTotal > 0;
                    if (cloneItem.hours > 0 || cloneItem.laborTotal > 0) {
                        cloneItem.isSublet = false;
                        cloneItem.subletVendorId = null;
                        cloneItem.subletCost = 0;
                        cloneItem.subletPrice = 0;
                    }

                    cloneItem.identifierJobsHeader = uuid();

                    const partsSeletectd = [];
                    if (currentOpCodeResult.detail?.length > 0) {
                        currentOpCodeResult.detail.forEach((det) => {
                            partsSeletectd.push({
                                description: det.partsInventory?.description ?? '',
                                isChecked: true,
                                listPrice: det.partsInventory?.listPrice ?? 0,
                                lotName: det.partsInventory?.lotName ?? '',
                                netPrice: det.partsInventory?.netPrice ?? 0,
                                partCost: det.partsInventory?.partCost ?? 0,
                                partStockNumber: det.partsInventory?.partStockNumber ?? (det.partStockNumber || ''),
                                partsInventoryId: det.partInventoryId,
                                quantityOnHand: det.quantity,
                                quantity: det.quantity,
                                active: det.partsInventory?.active,
                                isTaxable: det.partsInventory.isTaxable,
                            });
                        });

                        if (methodDeleteAllPart) {
                            setTimeout(() => {
                                methodDeleteAllPart(jobNumber);
                            }, 200);
                        }

                        if (methodAddPart) {
                            setTimeout(() => {
                                methodAddPart(partsSeletectd, jobNumber);
                            }, 500);
                        }
                    } else {
                        setTimeout(() => {
                            methodDeleteAllPart(jobNumber);
                        }, 500);
                    }
                }

                cloneItem.identifierJob = uuid();
                cloneItem.identifierJobsParts = ['technicianId', 'payType'].includes(field)
                    ? uuid() : cloneItem.identifierJobsParts;
                return cloneItem;
            }

            if (field === 'discount') {
                sumDiscount += item.discount;
            }

            return item;
        });

        if (fieldsToRecalculate.includes(field)) {
            const currentRecord = {
                ...state.record,
                jobs: currentJobs,
            };

            if (field === 'discount') {
                currentRecord.discount = sumDiscount;
            }

            const recordCalculated = CalculationServiceMethodsHelper.setCalculationsLocal(currentRecord, state.serviceSettings);
            const { jobs: jobsRecalculated, ...rest } = recordCalculated;

            return {
                ...state,
                record: rest,
                jobs: jobsRecalculated,
                identifierJobsMainInfo: uuid(),
                identifierJobs: uuid(),
                isDirty: true,
            };
        }

        return {
            ...state,
            jobs: currentJobs,
            identifierJobs: uuid(),
            isDirty: actionType !== 'expandeOrCollapse' ? true : state.isDirty,
        };
    }
    case ACTION_TYPES.ADD_NEW_JOB: {
        const { record, jobs } = state;

        const payTypeDataFiltered = record.inHouse ? PayTypeData.filter((c) => c.withCustomerPay === false || c.withCustomerPay === null)
            : PayTypeData.filter((c) => c.withCustomerPay === true || c.withCustomerPay === null);

        jobs.push({
            invoiceNumber: record.invoiceNumber,
            jobNumber: (jobs.length + 1) * -1,
            customerStates: '',
            cause: '',
            correction: '',
            notes: '',
            technician: '',
            hours: 0,
            laborCost: 0,
            subletPrice: 0,
            subletCost: 0,
            technicianFunded: false,
            payType: payTypeDataFiltered[0]?.value,
            hourlyRate: 0,
            isFlatRate: false,
            problemWhat: '',
            problemWhen: '',
            problemCause: '',
            laborOperationCode: '',
            recommend1: '',
            discount: 0,
            approved: true,
            status: ServiceJobStatus.DIAGNOSIS,
            estimatedHours: 0,
            actualHours: 0,
            requestedParts: '',
            isHrsChecked: true,
            subletVendorId: 0,
            technicianId: 0,
            flaggedHours: 0,
            overrideSubletPrice: false,
            vehicleInspectionItemIds: '',
            payTypeId: '',
            isSublet: false,
            technicianImage: 'https://images.automatrix.com/1/HR/ProfilePicture/employeeDefault.jpg',
            parts: [],
            identifierJob: uuid(),
            identifierJobsHeader: uuid(),
            identifierJobsParts: uuid(),
            open: true,
        });

        return {
            ...state,
            jobs,
            identifierJobs: uuid(),
            isDirty: true,
        };
    }
    case ACTION_TYPES.DELETE_JOB: {
        const { jobNumber } = action.payload;

        const { record, jobs } = state;

        const currentJobs = jobs.filter((c) => c.jobNumber !== jobNumber);

        const currentRecord = {
            ...record,
            jobs: currentJobs,
        };

        const recordCalculated = CalculationServiceMethodsHelper.setCalculationsLocal(currentRecord, state.serviceSettings);
        const { jobs: jobsRecalculated, ...rest } = recordCalculated;
        return {
            ...state,
            record: rest,
            jobs: jobsRecalculated,
            identifierJobsMainInfo: uuid(),
            identifierJobs: uuid(),
            isDirty: true,
        };
    }
    case ACTION_TYPES.ADD_NEW_JOB_PART: {
        const {
            internalSubletPriceMarkUp,
        } = state.serviceSettings;

        const { partsSelected, jobNumber } = action.payload;
        const currentParts = state.jobs?.find((c) => c.jobNumber === jobNumber)?.parts ?? [];
        let currentIndex = (currentParts?.length ?? 0) * -1;

        const currentPartsInventoryId = currentParts.map((c) => c.partInventoryId);

        const partsToBeInserted = partsSelected.filter((c) => c.active && !currentPartsInventoryId.includes(c.partsInventoryId));

        const newParts = currentParts?.map((part) => {
            const findPart = partsSelected.find((ps) => ps.partsInventoryId === part.partInventoryId);
            if (findPart) {
                return {
                    ...part,
                    quantity: Number(part.quantity) + 1,
                    total: NumberUtils.round(Number(part.quantity) * Number(part.netPrice)),
                };
            }

            return part;
        });

        if (partsToBeInserted.length > 0) {
            partsToBeInserted.forEach((data) => {
                currentIndex -= 1;

                newParts.push({
                    serviceJobPartsId: currentIndex,
                    invoiceNumber: state.record.invoiceNumber,
                    jobNumber,
                    partInventoryId: data.partsInventoryId,
                    partStockNumber: data.partStockNumber,
                    quantity: data.quantity > 0 ? data.quantity : 1,
                    description: data.description,
                    listPrice: Number(data.Price),
                    netPrice: state.record.inHouse ? (internalSubletPriceMarkUp > 0 ? (Number(data.partCost) * (1 + internalSubletPriceMarkUp / 100)) : data.netPrice) : Number(data.netPrice),
                    partCost: Number(data.partCost),
                    total: Number(data.netPrice),
                    isTaxable: data.isTaxable,
                    partTaxAmount: 0,
                });
            });
        }

        const currentJobs = state.jobs?.map((job) => {
            if (job.jobNumber === jobNumber) {
                return {
                    ...job,
                    parts: newParts,
                    identifierJob: uuid(),
                    identifierJobsParts: uuid(),
                };
            }

            return job;
        });

        const currentRecord = {
            ...state.record,
            jobs: currentJobs,
        };

        const recordCalculated = CalculationServiceMethodsHelper.setCalculationsLocal(currentRecord, state.serviceSettings);
        const { jobs: jobsRecalculated, ...rest } = recordCalculated;
        return {
            ...state,
            record: rest,
            jobs: jobsRecalculated,
            identifierJobsMainInfo: uuid(),
            identifierJobs: uuid(),
            isDirty: true,
        };
    }
    case ACTION_TYPES.DELETE_JOB_PART: {
        const { serviceJobPartsId, jobNumber } = action.payload;
        let currentParts = state.jobs?.find((c) => c.jobNumber === jobNumber)?.parts ?? [];
        currentParts = currentParts?.filter((c) => c.jobNumber === jobNumber && c.serviceJobPartsId !== serviceJobPartsId);

        const currentJobs = state.jobs?.map((job) => {
            if (job.jobNumber === jobNumber) {
                return {
                    ...job,
                    parts: currentParts,
                    identifierJob: uuid(),
                    identifierJobsParts: uuid(),
                };
            }

            return job;
        });

        const currentRecord = {
            ...state.record,
            jobs: currentJobs,
        };

        const recordCalculated = CalculationServiceMethodsHelper.setCalculationsLocal(currentRecord, state.serviceSettings);
        const { jobs: jobsRecalculated, ...rest } = recordCalculated;

        return {
            ...state,
            record: rest,
            jobs: jobsRecalculated,
            identifierJobsMainInfo: uuid(),
            identifierJobs: uuid(),
            isDirty: true,
        };
    }
    case ACTION_TYPES.DELETE_ALL_JOB_PART: {
        const { jobNumber } = action.payload;

        const currentJobs = state.jobs?.map((job) => {
            if (job.jobNumber === jobNumber) {
                return {
                    ...job,
                    parts: [],
                    identifierJob: uuid(),
                    identifierJobsParts: uuid(),
                };
            }

            return job;
        });

        const currentRecord = {
            ...state.record,
            jobs: currentJobs,
        };

        const recordCalculated = CalculationServiceMethodsHelper.setCalculationsLocal(currentRecord, state.serviceSettings);
        const { jobs: jobsRecalculated, ...rest } = recordCalculated;

        return {
            ...state,
            record: rest,
            jobs: jobsRecalculated,
            identifierJobsMainInfo: uuid(),
            identifierJobs: uuid(),
            isDirty: true,
        };
    }
    case ACTION_TYPES.CHANGE_JOB_PART: {
        const {
            jobNumber, columnId, newValue, cell,
        } = action.payload;

        const currentParts = state.jobs?.find((c) => c.jobNumber === jobNumber)?.parts ?? [];

        const newParts = currentParts?.map((part) => {
            const clonePart = { ...part };
            if (clonePart.serviceJobPartsId === cell.rowData.serviceJobPartsId) {
                clonePart[columnId] = newValue;
                if (columnId === 'quantity' || columnId === 'netPrice') {
                    clonePart.total = Number(clonePart.quantity) * Number(clonePart.netPrice);
                }
                clonePart.identifierJob = uuid();
                clonePart.identifierJobsParts = uuid();
                return clonePart;
            }
            return part;
        });

        const currentJobs = state.jobs?.map((job) => {
            if (job.jobNumber === jobNumber) {
                return {
                    ...job,
                    parts: newParts,
                    identifierJob: uuid(),
                    identifierJobsParts: uuid(),
                };
            }

            return job;
        });

        if (fieldsPartsToRecalculate.includes(columnId)) {
            const currentRecord = {
                ...state.record,
                jobs: currentJobs,
            };

            const recordCalculated = CalculationServiceMethodsHelper.setCalculationsLocal(currentRecord, state.serviceSettings);
            const { jobs: jobsRecalculated, ...rest } = recordCalculated;
            return {
                ...state,
                record: rest,
                jobs: jobsRecalculated,
                identifierJobsMainInfo: uuid(),
                identifierJobs: uuid(),
                isDirty: true,
            };
        }

        return {
            ...state,
            jobs: currentJobs,
            identifierJobs: uuid(),
            isDirty: true,
        };
    }
    case ACTION_TYPES.ON_CHANGE_VEHICLE_INFO: {
        const {
            vehicleStock,
            vehicleCustomStock,
            vehicleVin,
            vehicleMake,
            vehicleModel,
            vehicleYear,
            vehicleTrim,
            vehicleEngine,
        } = action.payload;

        const { record } = state;
        record.vehicleStock = vehicleStock;
        record.vehicleCustomStock = vehicleCustomStock;
        record.vehicleVin = vehicleVin;
        record.vehicleMake = vehicleMake;
        record.vehicleModel = vehicleModel;
        record.vehicleYear = vehicleYear;
        record.vehicleTrim = vehicleTrim;
        record.vehicleEngine = vehicleEngine;

        return {
            ...state,
            record,
            identifierJobsMainInfo: uuid(),
            isDirty: true,
        };
    }
    default:
        return state;
    }
};

export default ServiceInvoiceReducer;

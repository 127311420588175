import { gql } from '@apollo/client';

export default class AccountingMutation {
    static CREATE_UPDATE_CHECK = gql`
        mutation createUpdateCheck($record: CheckInput!) {
            createUpdateCheck(record: $record)
        }
    `;

    static CREATE_UPDATE_CHECK_FROM_INVOICE = gql`
        mutation createUpdateCheckFromInvoice($record: CheckInput!) {
            createUpdateCheckFromInvoice(record: $record)
        }
    `;

    static CREATE_UPDATE_DEPOSIT = gql`
        mutation createUpdateDeposit($record: DepositInput!) {
            createUpdateDeposit(record: $record)
        }
    `;

    static CREATE_UPDATE_DEPOSIT_BATCH_OUT = gql`
        mutation createUpdateDepositBatchOut($record: DepositInput!) {
            createUpdateDepositBatchOut(record: $record)
        }
    `;

    static CREATE_UPDATE_TRANSFER = gql`
        mutation createUpdateTransfer($record: TransferInput!) {
            createUpdateTransfer(record: $record)
        }
    `;

    static APPROVE_CHECKS = gql`
        mutation approveChecks($checks: [Int!]!) {
            approveChecks(checks: $checks)
        }
    `;

    static VOID_CBID = gql`
        mutation voidCheckOrDeposit($cbId: Int!, $forceVoid: Boolean!, $dateToPost: Date, $forceFromSold: Boolean) {
            voidCheckOrDeposit(cbId: $cbId, forceVoid: $forceVoid, dateToPost: $dateToPost, forceFromSold: $forceFromSold){
            success
            lockedDate
            isLockedDate
            }
        }
    `;

    static PRINT_CHECK = gql`
        mutation printCheck($cbIds: [Int!]!, $reprint: Boolean, $newCheckNumber: Int) {
            printCheck(cbIds: $cbIds, reprint: $reprint, newCheckNumber: $newCheckNumber)
        }
    `;

    static ASSIGN_PAYMENT = gql`
        mutation assignPayment($record: AssignPaymentInput!){
            assignPayment(record: $record)
        }
    `;

    static PRINT_REMITTANCE = gql`
        mutation printRemittance($cbId: Int!, $printDate: String) {
            printRemittance(cbId: $cbId, printDate: $printDate)
        }
    `;

    static MOVING_GL_BETWEEN_ACCOUNTS = gql`
        mutation movingGlBetweenAccounts($record: OverrideAccountInput!){
            movingGlBetweenAccounts(record: $record)
        }
    `;

    static SEND_SMS_SMART_LINK = gql`
        mutation sendSMSSmartLink($accountNumber: Int!, $processType: String!, $reason: String!) {
            sendSMSSmartLink(accountNumber: $accountNumber, processType: $processType, reason: $reason)
        }
    `;

    static SEND_EMAIL_SMART_LINK = gql`
        mutation sendEmailSmartLink($accountNumber: Int!, $processType: String!, $reason: String!) {
            sendEmailSmartLink(accountNumber: $accountNumber, processType: $processType, reason: $reason)
        }
    `;

    static UNDO_APPROVAL_CHECKS = gql`
        mutation undoApproveChecks($checks: [Int!]!) {
            undoApproveChecks(checks: $checks)
        }
    `;
}

/* eslint-disable no-param-reassign */
import React, { useEffect, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
import produce from 'immer';
import { isEmpty } from 'lodash';

import {
    useQuery,
    useLazyQuery,
    useMutation,
} from '@apollo/client';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';
import SettingsQuery from 'services/graphQL/query/SettingsQuery';
import CatalogQuery from 'services/graphQL/query/CatalogQuery';
import InventoryMap from 'services/mapData/InventoryMap';

import {
    makeStyles,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    FormControlLabel,
    Checkbox,
    useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useVinValidation from 'components/modules/inventory/hooks/useVinValidation';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import EditVehicleForm from 'components/modules/inventory/create/dialogs/EditVehicle/EditVehicleForm';
import UserContext from 'components/context/UserContext';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import usePurchasingActions from 'components/modules/inventory/hooks/usePurchasingActions';

import StringUtils from 'lib/StringUtils';
import MessageUtils from 'utils/MessageUtils';
import ModalUtils from 'utils/ModalUtils';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import { FetchPolicy } from 'utils/enum/Core';
import { InventoryColor } from 'utils/enum/InventoryEnum';
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';
import InventoryHelper from 'utils/InventoryHelper';
import ImagesUtils from 'utils/ImagesUtils';

const initState = {
    data: {},
    purchasedCreationLoading: false,
    purchasedFromList: [],
    purchasingAgentList: [],
    styleList: [
        'Unknown',
        'Convertible',
        'Coupe',
        'Hatchback',
        'Minivan',
        'Sedan',
        'SUV',
        'Truck',
        'Cargo Van',
        'Wagon',
        'Motorcycle',
        'Boat',
        'RV',
        'Aircraft',
    ],
    titleStatusList: [
        'None',
        '0-Clean',
        '1-Salvage',
        '3-Rebuild',
        '4-Manufacturer Buyback',
        '5-Water Damage',
    ],
    decodedStyle: '',
    allowEditTrim: true,
    trimList: [],
    engineList: [],
    equipments: {
        standardEquipment: [],
        optionalEquipment: [],
        additionalSpec: [],
    },
    colorsData: [],
    multipleTrim: false,
    openConfirm: false,
    oldVIN: '',
    useCustomStock: false,
    stockNumberPrefix: null,
    stockNumberSuffix: null,
};
const ACTION_TYPES = {
    ON_CHANGE: 'onChange',
    ON_CHANGE_PRICING: 'onChangePrincing',
    ON_CHANGE_TITLE: 'onChangeTitle',
    ON_CHANGE_TAG: 'onChangeTag',
    SET_VIN_DATA: 'setVinData',
    SET_TRIM: 'setTrim',
    SET_COLORS_DATA: 'setColorsData',
    TOGGLE_CONFIRM: 'toggleConfirm',
    SET_NEW_VIN_DATA: 'setNewVinData',
    SET_MULTIPLE_TRIM: 'setMultipleTrim',
    SET_PURCHASED_FROM_OPTIONS: 'setPurchasedFromOptions',
    SET_PURCHASING_AGENT_OPTIONS: 'setPurchasingAgentOptions',
    TOGGLE_PURCHASED_CREATION_LOADING: 'togglePurchasedCreationLoading',
    ON_PURCHASED_FROM_CREATION: 'onPurchasedFromCreation',
    ON_PURCHASING_AGENT_CREATION: 'onPurchasingAgentCreation',
    ON_PURCHASING_AGENT_EDIT: 'onPurchasingAgentEdit',
    ON_PURCHASING_AGENT_DELETE: 'onPurchasingAgentDelete',
};
const reducer = produce((draftState, { type, payload = null }) => {
    switch (type) {
    case ACTION_TYPES.ON_CHANGE:
        const isTrim = payload.fieldName === 'trim';
        if (
            isTrim
            && draftState.trimList.length > 0
            && !draftState.trimList.includes(payload.value)
        ) {
            draftState.trimList = [...draftState.trimList, payload.value];
        }

        if (payload.property) {
            draftState.data[payload.fieldName][payload.property] = payload.value;
        } else if ('useCustomStock' in payload) {
            const { useCustomStock } = payload;
            draftState.useCustomStock = useCustomStock;

            if (useCustomStock) {
                const { stockNumber, customStockNumber } = draftState.data;
                if (!StringUtils.isEmpty(customStockNumber)) {
                    const [prefix, suffix] = customStockNumber.replace(stockNumber, '|').split('|');
                    if (!StringUtils.isEmpty(prefix)) draftState.stockNumberPrefix = prefix;
                    if (!StringUtils.isEmpty(suffix)) draftState.stockNumberSuffix = suffix;
                }
            }
        } else if (['stockNumberPrefix', 'stockNumberSuffix'].includes(payload.fieldName)) {
            draftState[payload.fieldName] = payload.value?.toUpperCase();
        } else {
            draftState.data[payload.fieldName] = payload.value;
        }
        break;
    case ACTION_TYPES.ON_CHANGE_PRICING:
        draftState.data.pricing[payload.fieldName] = payload.value;
        break;
    case ACTION_TYPES.ON_CHANGE_TITLE:
        draftState.data.title = false;
        draftState.data.titleNumber = '';
        break;
    case ACTION_TYPES.ON_CHANGE_TAG:
        draftState.data.tag = '';
        draftState.data.tagExpires = null;
        break;
    case ACTION_TYPES.SET_VIN_DATA:
        draftState.allowEditTrim = payload.allowEditTrim;
        draftState.trimList = payload.trimList;
        draftState.engineList = payload.engineList;
        draftState.decodedStyle = payload.decodedStyle;
        draftState.equipments = payload.equipments;
        draftState.styleList = payload.styleList;
        break;
    case ACTION_TYPES.SET_TRIM:
        draftState.trimList = payload.trimList;
        draftState.multipleTrim = payload.multipleTrim;
        break;
    case ACTION_TYPES.SET_MULTIPLE_TRIM:
        draftState.multipleTrim = payload;
        break;
    case ACTION_TYPES.SET_COLORS_DATA:
        draftState.colorsData = payload;
        break;
    case ACTION_TYPES.TOGGLE_CONFIRM:
        draftState.openConfirm = payload;
        break;
    case ACTION_TYPES.SET_NEW_VIN_DATA:
        draftState.data.year = payload.data.year || '';
        draftState.data.make = payload.data.make || '';
        draftState.data.model = payload.data.model || '';
        draftState.data.trim = payload.data.trim[0] || '';
        draftState.data.engine = payload.data.engines[0] || '';
        draftState.data.style = payload.data.bodyStyle || '';
        draftState.data.drivetrain = payload.data.drivetrain || '';
        draftState.data.cityMpg = payload.data.cityMPG || '';
        draftState.data.highwayMPG = payload.data.highwayMPG || '';
        draftState.data.extColor = payload.data.exteriorColor ? payload.data.exteriorColor.colorName : '';
        draftState.data.intColor = payload.data.interiorColor ? payload.data.interiorColor.colorName : '';
        draftState.data.thumbnail = payload.thumbnail;
        draftState.data.alternativeThumbnail = payload.thumbnail;
        draftState.oldVIN = draftState.data.vin;
        break;
    case ACTION_TYPES.TOGGLE_PURCHASED_CREATION_LOADING:
        draftState.purchasedCreationLoading = !draftState.purchasedCreationLoading;
        break;
    case ACTION_TYPES.ON_PURCHASED_FROM_CREATION:
        const { purchasedFromId, name: purchasedFrom } = payload;

        draftState.purchasedFromList.push({
            purchasedFrom,
            purchasedFromId,
        });
        draftState.data.pricing.purchasedFrom = purchasedFrom;
        draftState.purchasedCreationLoading = false;
        break;
    case ACTION_TYPES.ON_PURCHASING_AGENT_CREATION:
        const { purchasingAgentsId, name: purchasedAgent } = payload;

        draftState.purchasingAgentList.push({
            agent: purchasedAgent,
            purchasingAgentsId,
        });
        draftState.data.pricing.purchasedAgent = purchasedAgent;
        draftState.purchasedCreationLoading = false;
        break;
    case ACTION_TYPES.ON_PURCHASING_AGENT_EDIT:
        draftState.purchasingAgentList = draftState.purchasingAgentList.map((pa) => {
            if (pa.purchasingAgentsId === payload.id) {
                pa.agent = payload.name;
                return pa;
            }

            return pa;
        });
        draftState.purchasedCreationLoading = false;
        break;
    case ACTION_TYPES.ON_PURCHASING_AGENT_DELETE:
        draftState.purchasingAgentList = draftState.purchasingAgentList.filter((pa) => pa.purchasingAgentsId !== payload.id);
        draftState.purchasedCreationLoading = false;
        break;
    case ACTION_TYPES.SET_PURCHASED_FROM_OPTIONS:
        draftState.purchasedFromList = payload;
        break;
    case ACTION_TYPES.SET_PURCHASING_AGENT_OPTIONS:
        draftState.purchasingAgentList = payload;
        break;
    default:
        break;
    }
});
const useStyles = makeStyles((theme) => ({
    dialogActionsStyle: {
        padding: '10px 16px',
        borderTop: '1px solid #EEEEEE',
        justifyContent: 'space-between',
        '& > div:last-child > button': {
            marginLeft: 10,
        },
        '& > div:nth-child(1)': {
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
            '& > label > span:last-child': {
                marginTop: '2px',
            },
            '& > div': {
                fontSize: '14px',
                fontWeight: 500,
            },
        },
    },
}));

const EditVehicleDialog = ({
    stockNumber, toggleModal, open,
    data, invUserTabName, onReload,
}) => {
    const classes = useStyles();
    const {
        createFrom,
        createAgent,
        editAgent,
        deleteAgent,
    } = usePurchasingActions();
    const [state, dispatch] = useReducer(reducer, {
        ...initState,
        data: {
            ...data,
            lienHolder: {},
        },
        oldVIN: data.vin,
    });

    const { data: { year: inputtedYear, vin: inputtedVin, postedDate } } = state;
    const validPostedDate = postedDate && DateUtils.isValid(postedDate);

    const {
        isInvalidVINLength,
        isInvalidVINChars,
        vinNumberLength,
        notAllowedCharsInVIN,
        vinYearStandardization,
    } = useVinValidation(inputtedYear, inputtedVin, true);
    const validateFormFields = (record, engineList) => {
        if (
            engineList
            && engineList.length > 0
            && StringUtils.isEmpty(record.engine)
        ) {
            return false;
        }

        return (
            !InventoryHelper.hasStockInvalidYear(record.year)
            && !StringUtils.isEmpty(record.model)
            && !StringUtils.isEmpty(record.lotName)
            && !isInvalidVINLength
            && !isInvalidVINChars
            && record.miles >= 0
            && InventoryHelper.validateCustomTabURL(record.userTabURL2)
            && InventoryHelper.validateCustomTabURL(record.userTabURL1)
        );
    };

    const {
        data: purchasedFromData, error: purchasedFromError, loading: purchasedFromLoading,
    } = useQuery(SettingsQuery.GET_PURCHASED_FROM, {
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const {
        data: purchasingAgentData, error: purchasingAgentError, loading: purchasingAgentLoading,
    } = useQuery(SettingsQuery.GET_PURCHASING_AGENT, {
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const {
        data: driveTrainsList, error: driveTrainsListError, driveTrainsListLoading, refetch,
    } = useQuery(InventoryQuery.GET_DRIVETRAINS_LIST, {
        variables: { make: state.data?.make || '' },
    });

    const {
        data: vinColorsData, error: colorsError, loading: colorsLoading,
    } = useQuery(InventoryQuery.GET_COLORS, {
        variables: {
            vin: data.vin,
            trim: data.trim || '',
            stockNumber,
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
        skip: !state.data?.year
        || Number(state.data.year) < vinYearStandardization
        || data.vin?.trim()?.length < vinNumberLength,
    });

    const [loadVehicleData, {
        data: vehicleData, loading: vehicleLoading, error: vehicleError,
    }] = useLazyQuery(InventoryQuery.GET_VEHICLE_DATA, {
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const { EXTERIOR_COLOR, INTERIOR_COLOR, GENERIC_EXTERIOR_COLOR } = InventoryColor;
    const {
        data: enumsData, error: enumsError, loading: enumsLoading,
    } = useQuery(CatalogQuery.ENUM_VALUES, {
        variables: {
            descriptions: [EXTERIOR_COLOR, INTERIOR_COLOR, GENERIC_EXTERIOR_COLOR],
        },
    });

    const {
        data: lienHolderData, loading: pullingLienHolderData, error: errorPullingLienHolderData,
    } = useQuery(InventoryQuery.GET_VEHICLE_LIEN_HOLDER, {
        variables: {
            stockNumber,
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [updateVehicle, { loading: saving }] = useMutation(InventoryMutation.UPDATE_VEHICLE);
    const [createEquipment] = useMutation(InventoryMutation.CREATE_EQUIPMENT);
    const { userInformation } = useContext(UserContext);
    const availableLots = userInformation?.lots || [];
    const { styleList } = state;
    const addColors = (record = {}) => {
        const interiorColors = [...record.interiorColors];
        const exteriorColors = [...record.exteriorColors];
        const genericColors = [...record.genericColors];
        let { colorsData } = state;

        interiorColors.reverse();
        exteriorColors.reverse();
        genericColors.reverse();
        colorsData = colorsData.filter((item) => !item.fromAPI);

        genericColors.forEach((item) => {
            const { colorName } = item;

            if (!StringUtils.isEmpty(colorName)) {
                colorsData.unshift({
                    type: EXTERIOR_COLOR,
                    description: colorName,
                    fromAPI: true,
                });
                colorsData.unshift({
                    type: INTERIOR_COLOR,
                    description: colorName,
                    fromAPI: true,
                });
            }
        });

        interiorColors.forEach((item) => {
            const { colorName } = item;

            if (!StringUtils.isEmpty(colorName)) {
                colorsData.unshift({
                    type: INTERIOR_COLOR,
                    description: colorName,
                    fromAPI: true,
                });
            }
        });

        exteriorColors.forEach((item) => {
            const { colorName } = item;

            if (!StringUtils.isEmpty(colorName)) {
                colorsData.unshift({
                    type: EXTERIOR_COLOR,
                    description: colorName,
                    fromAPI: true,
                });
            }
        });

        dispatch({
            type: ACTION_TYPES.SET_COLORS_DATA,
            payload: colorsData || [],
        });
    };
    const handleVINData = (records = {}) => {
        let trimList = records.trim || [];
        let styles = [...styleList];
        const multipleTrim = trimList.length > 1;

        if (!StringUtils.isEmpty(records.bodyStyle) && !styleList.includes(records.bodyStyle)) {
            styles = [records.bodyStyle, ...styles];
        }

        dispatch({
            type: ACTION_TYPES.SET_VIN_DATA,
            payload: {
                allowEditTrim: true,
                trimList,
                styleList: styles,
                engineList: records.engines || [],
                decodedStyle: records.decodedStyle,
                equipments: {
                    standardEquipment: records.standardEquipment || [],
                    optionalEquipment: records.optionalEquipment || [],
                    additionalSpec: records.additionalSpec || [],
                },
            },
        });

        if (!isEmpty(records)) {
            addColors(records);
        }

        if (multipleTrim) {
            const { trim } = data;

            if (!StringUtils.isEmpty(trim) && !trimList.includes(trim)) {
                trimList = [trim, ...trimList];
            }

            dispatch({
                type: ACTION_TYPES.SET_TRIM,
                payload: {
                    trimList,
                    multipleTrim,
                },
            });
        }
    };

    useEffect(() => {
        if (userInformation) {
            const {
                customStockEnabled,
            } = userInformation;

            dispatch({
                type: ACTION_TYPES.ON_CHANGE,
                payload: {
                    useCustomStock: customStockEnabled,
                },
            });
        }
    }, [userInformation]);

    useEffect(() => {
        const options = purchasedFromData?.getPurchasedFrom;
        if (options) {
            dispatch({
                type: ACTION_TYPES.SET_PURCHASED_FROM_OPTIONS,
                payload: options,
            });
        }
    }, [purchasedFromData]);

    useEffect(() => {
        const options = purchasingAgentData?.getPurchasingAgent;
        if (options) {
            dispatch({
                type: ACTION_TYPES.SET_PURCHASING_AGENT_OPTIONS,
                payload: options,
            });
        }
    }, [purchasingAgentData]);

    useEffect(() => {
        if (vinColorsData) {
            const { engine } = data;
            let newRecords = vinColorsData?.decodeVin || {};

            if (engine && newRecords.engines && !newRecords.engines.includes(engine)) {
                newRecords = produce(newRecords, (draft) => {
                    draft.engines.unshift(engine);
                });
            }

            handleVINData(newRecords);
        }
        // eslint-disable-next-line
    }, [vinColorsData]);
    useEffect(() => {
        dispatch({
            type: ACTION_TYPES.SET_COLORS_DATA,
            payload: enumsData?.getEnumValues || [],
        });
    }, [enumsData]);
    useEffect(() => {
        if (vehicleData) {
            let newRecords = vehicleData.decodeVin || {};
            const image = ImagesUtils.getSecureImageURL(
                newRecords.image,
                newRecords.bodyStyle,
            );

            if (newRecords.engines && !newRecords.engines.includes(data.engine)) {
                newRecords = produce(newRecords, (draft) => {
                    draft.engines.unshift(data.engine);
                });
            }

            dispatch({
                type: ACTION_TYPES.SET_NEW_VIN_DATA,
                payload: {
                    data: newRecords,
                    thumbnail: image,
                },
            });

            handleVINData(newRecords);
        }
    // eslint-disable-next-line
    }, [vehicleData]);
    useEffect(() => {
        if (!StringUtils.isEmpty(state.data?.make)) {
            refetch();
        }
    // eslint-disable-next-line
    }, [state.data?.make]);

    useEffect(() => {
        if (errorPullingLienHolderData) {
            ModalUtils.errorMessage(errorPullingLienHolderData?.graphQLErrors);
            return;
        }

        if (!pullingLienHolderData) {
            const holder = lienHolderData?.getVehicleLienHolder;
            if (holder) {
                dispatch({
                    type: ACTION_TYPES.ON_CHANGE,
                    payload: {
                        fieldName: 'lienHolder',
                        value: holder,
                    },
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pullingLienHolderData, errorPullingLienHolderData]);

    if (driveTrainsListError) {
        ModalUtils.errorMessage(null, driveTrainsListError);
    }
    if (purchasedFromError) {
        ModalUtils.errorMessage(null, purchasedFromError);
    }
    if (purchasingAgentError) {
        ModalUtils.errorMessage(null, purchasingAgentError);
    }
    if (colorsError) {
        ModalUtils.errorMessage(null, colorsError);
    }
    if (enumsError) {
        ModalUtils.errorMessage(null, enumsError);
    }
    if (vehicleError) {
        ModalUtils.errorMessage(null, 'There was an error trying to decode VIN');
    }

    const keyStore = new KeyStore();
    const WRITE = keyStore.hasPermission(Permission.INVENTORY_VEHICLE_WRITE);
    const loading = colorsLoading || enumsLoading || purchasedFromLoading
        || purchasingAgentLoading || driveTrainsListLoading
        || vehicleLoading || state.purchasedCreationLoading;

    const saveEquipments = async (trim, decodedStyle) => {
        const oldTrim = data.trim || '';
        const { oldVIN } = state;

        if (trim === oldTrim && oldVIN === data.vin) {
            return;
        }

        const {
            standardEquipment,
            optionalEquipment,
            additionalSpec,
        } = state.equipments;
        const variables = {
            stockNumber,
            trim,
            decodedStyle,
            standardEquipment: standardEquipment || [],
            optionalEquipment: optionalEquipment || [],
            additionalSpec: additionalSpec || [],
            isEditing: true,
        };
        const errorMessage = 'There was an error trying to save the equipments';

        try {
            const response = await createEquipment({ variables });

            if (!response?.data?.createEquipment) {
                ModalUtils.errorMessage(null, errorMessage);
            }
        } catch (ex) {
            ModalUtils.errorMessage(null, errorMessage);
        }
    };
    const onSave = async () => {
        if (WRITE) {
            try {
                const {
                    data: vehicle,
                    useCustomStock,
                    stockNumberPrefix,
                    stockNumberSuffix,
                } = state;

                const variables = {
                    stockNumber,
                    input: {
                        ...InventoryMap.mapUpdateVehicle(vehicle),
                        useCustomStock,
                        stockNumberPrefix,
                        stockNumberSuffix,
                    },
                };

                const response = await updateVehicle({ variables });

                if (response?.data?.updateVehicle) {
                    saveEquipments(state.data.trim, state.decodedStyle);
                    toggleModal();
                    onReload();

                    ModalUtils.successMessage(null, 'Vehicle updated successfully!');
                } else {
                    ModalUtils.errorMessage(null, MessageUtils.getGenericError('save', 'Vehicle'));
                }
            } catch (ex) {
                const message = ex.message || MessageUtils.getGenericError('save', 'Vehicle');
                ModalUtils.errorMessage(null, message);
            }
        }
    };
    const onChange = (fieldName, value, section) => {
        if (section) {
            dispatch({
                type: ACTION_TYPES.ON_CHANGE,
                payload: {
                    fieldName: section,
                    property: fieldName,
                    value,
                },
            });
        } else if (fieldName === 'active' && !value) {
            dispatch({
                type: ACTION_TYPES.TOGGLE_CONFIRM,
                payload: true,
            });
        } else if (
            fieldName === 'purchasedFrom'
            || fieldName === 'purchasedAgent'
            || fieldName === 'purchasedDate'
        ) {
            dispatch({
                type: ACTION_TYPES.ON_CHANGE_PRICING,
                payload: {
                    fieldName,
                    value,
                },
            });
        } else if (fieldName === 'title' && !value) {
            dispatch({
                type: ACTION_TYPES.ON_CHANGE_TITLE,
            });
        } else if (fieldName === 'tag' && StringUtils.isEmpty(value)) {
            dispatch({
                type: ACTION_TYPES.ON_CHANGE_TAG,
            });
        } else {
            dispatch({
                type: ACTION_TYPES.ON_CHANGE,
                payload: {
                    fieldName,
                    value,
                },
            });
        }
    };
    const onCreate = async (field, value) => {
        if (field === 'purchasedFrom') {
            createFrom(
                dispatch,
                ACTION_TYPES.TOGGLE_PURCHASED_CREATION_LOADING,
                ACTION_TYPES.ON_PURCHASED_FROM_CREATION,
                value,
            );
        }
        if (field === 'purchasedAgent') {
            createAgent(
                dispatch,
                ACTION_TYPES.TOGGLE_PURCHASED_CREATION_LOADING,
                ACTION_TYPES.ON_PURCHASING_AGENT_CREATION,
                value,
            );
        }
    };

    const onEditPurchasingAgent = (id, name) => {
        editAgent(
            dispatch,
            ACTION_TYPES.TOGGLE_PURCHASED_CREATION_LOADING,
            ACTION_TYPES.ON_PURCHASING_AGENT_EDIT,
            id,
            name,
        );
    };

    const onDeletePurchasingAgent = (id) => {
        deleteAgent(
            dispatch,
            ACTION_TYPES.TOGGLE_PURCHASED_CREATION_LOADING,
            ACTION_TYPES.ON_PURCHASING_AGENT_DELETE,
            id,
        );
    };

    const toggleConfirm = (action) => {
        if (action) {
            dispatch({
                type: ACTION_TYPES.ON_CHANGE,
                payload: {
                    fieldName: 'active',
                    value: false,
                },
            });
        }

        dispatch({
            type: ACTION_TYPES.TOGGLE_CONFIRM,
            payload: false,
        });
    };
    const decodeNewVin = (newVin) => {
        dispatch({
            type: ACTION_TYPES.SET_MULTIPLE_TRIM,
            payload: false,
        });
        loadVehicleData({
            variables: {
                vin: newVin,
                trim: state.data.trim || '',
                stockNumber,
            },
        });
    };
    const disablePrimaryButton = !WRITE || saving || loading || !validateFormFields(state.data, state.engineList);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Dialog
                open={open}
                fullWidth
                maxWidth="md"
                fullScreen={isMobile}
            >
                <DialogAppBar title="Edit Vehicle Information" onClose={toggleModal} />
                <DialogContent>
                    <EditVehicleForm
                        data={state.data}
                        onChange={onChange}
                        onCreate={onCreate}
                        onEdit={onEditPurchasingAgent}
                        onDelete={onDeletePurchasingAgent}
                        invUserTabName={invUserTabName}
                        styleList={state.styleList}
                        titleStatusList={state.titleStatusList}
                        availableLots={availableLots}
                        colorsData={state.colorsData}
                        engineList={state.engineList}
                        multipleTrim={state.multipleTrim}
                        trimList={state.trimList}
                        allowEditTrim={state.allowEditTrim}
                        loading={loading}
                        decodeNewVin={decodeNewVin}
                        oldVIN={state.oldVIN}
                        purchasedFromList={state.purchasedFromList || []}
                        purchasingAgentList={state.purchasingAgentList || []}
                        driveTrainsList={driveTrainsList?.getDriveTrainsByMake?.driveTrains || []}
                        invalidVINLength={isInvalidVINLength}
                        invalidVINChars={isInvalidVINChars}
                        vinNumberLength={vinNumberLength}
                        notAllowedCharsInVIN={notAllowedCharsInVIN}
                        vinYearStandardization={vinYearStandardization}
                        customStock={{
                            enabled: state.useCustomStock,
                            stockNumberPrefix: state.stockNumberPrefix,
                            stockNumberSuffix: state.stockNumberSuffix,
                        }}
                    />
                </DialogContent>
                <DialogActions className={classes.dialogActionsStyle}>
                    <div>
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    checked={state.data.active}
                                    onChange={(_, value) => onChange('active', value)}
                                    name="active"
                                    color="primary"
                                />
                            )}
                            label="Active"
                        />
                        {validPostedDate && (
                            <div>
                                {`Posted Date: ${DateUtils.getUSFormattedDateFromUTC(postedDate, DateFormat.DEFAULT_DATE)}`}
                            </div>
                        )}
                    </div>
                    <div>
                        <Button onClick={toggleModal} variant="contained">
                            Cancel
                        </Button>
                        <Button
                            onClick={onSave}
                            variant="contained"
                            color="primary"
                            disabled={disablePrimaryButton}
                        >
                            Save
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
            <ConfirmDialog
                title={validPostedDate ? 'Warning' : 'Confirm deactivate Vehicle'}
                description={
                    validPostedDate
                        ? `This vehicle is posted to Accounting. You would need to first reverse the entry in Accounting, 
                        then you are able to deactivate it here from inventory.`
                        : 'Are you sure you want to deactivate this vehicle?'
                }
                open={state.openConfirm}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary={validPostedDate ? 'Ok' : 'No'}
                onClose={() => toggleConfirm(false)}
                onClickSecondary={() => toggleConfirm(false)}
                onClickPrimary={() => toggleConfirm(true)}
                hiddenPrimaryButton={validPostedDate}
            />
        </>
    );
};

EditVehicleDialog.propTypes = {
    stockNumber: PropTypes.number.isRequired,
    toggleModal: PropTypes.func.isRequired,
    invUserTabName: PropTypes.object.isRequired,
    onReload: PropTypes.func.isRequired,
    open: PropTypes.bool,
    data: PropTypes.object,
};

EditVehicleDialog.defaultProps = {
    open: false,
    data: {},
};

export default EditVehicleDialog;

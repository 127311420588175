import DateUtils from 'lib/DateUtils';

export default class OpportunityMapper {
    static mapTradeIn(record) {
        if (record) {
            return {
                tradeVin: record.tradeVin,
                tradeYear: record.tradeYear,
                tradeMake: record.tradeMake,
                tradeModel: record.tradeModel,
                tradeTrim: record.tradeTrim,
                tradeMiles: record.tradeMiles,
                tradeACV: record.tradeACV,
                tradePayoff: record.tradePayoff,
                tradeLeinHolder: record.tradeLeinHolder,
                tradePayoffDate: DateUtils.isValid(record.tradePayoffDate) ? DateUtils.toLocal(record.tradePayoffDate).toDate() : null,
            };
        }

        return {};
    }

    static mapTradeInToUpdate(record) {
        if (record) {
            return {
                ...record,
                tradeYear: record.tradeYear > 0 ? record.tradeYear?.toString() : '',
                tradePayoffDate: DateUtils.formatUTC(record.tradePayoffDate),
            };
        }

        return {};
    }

    static mapVehicleOfInterest(record, chosenVehiclePrice) {
        if (record) {
            const vehicleEnteredBySearch = record.vehicle?.stockNumber > 0;
            return {
                vin: vehicleEnteredBySearch ? record.vehicle.vin : '',
                stockNumber: vehicleEnteredBySearch ? record.vehicle.stockNumber : 0,
                customStockNumber: vehicleEnteredBySearch ? record.vehicle.customStockNumber : null,
                year: vehicleEnteredBySearch ? record.vehicle.year : record.vehicleYear,
                make: vehicleEnteredBySearch ? record.vehicle.make : record.vehicleMake,
                trim: vehicleEnteredBySearch ? record.vehicle.trim : record.vehicleTrim,
                model: vehicleEnteredBySearch ? record.vehicle.model : record.vehicleModel,
                price: vehicleEnteredBySearch ? record.vehicle[chosenVehiclePrice] || record.vehicle.internetPrice : record.vehiclePrice,
            };
        }
        return {};
    }

    static mapUpdateVehicleOfInterest(record, vehicleEnteredBySearch) {
        if (record) {
            if (vehicleEnteredBySearch) return { stockNumber: record.stockNumber };

            return {
                stockNumber: null,
                vehicleYear: record.year.toString(),
                vehicleMake: record.make,
                vehicleTrim: record.trim,
                vehicleModel: record.model,
                vehiclePrice: record.price,
            };
        }
        return {};
    }
}

import DateUtils from 'lib/DateUtils';
import NumberUtils from 'lib/NumberUtils';
import { PurchaseOrderStatus } from 'utils/enum/PartsPurchaseEnum';

export default class PartsMapper {
    static mapPurchaseOrder(record, isCopying) {
        if (record) {
            const cloneRecord = {
                ...record,
                orderDate: new Date(DateUtils.getOnlyDate(record.orderDate)),
                deliveryDate: new Date(DateUtils.getOnlyDate(record.deliveryDate)),
                estimateDelivery: record.estimateDelivery ? new Date(record.estimateDelivery) : null,
            };

            if (isCopying) {
                cloneRecord.purchaseOrderId = 0;
                cloneRecord.status = PurchaseOrderStatus.IN_RETURNED_PROCESS;
                cloneRecord.orderDate = new Date(DateUtils.getOnlyDate(new Date()));
                cloneRecord.deliveryDate = new Date(DateUtils.getOnlyDate(new Date()));
            }

            return cloneRecord;
        }

        return {};
    }

    static mapPurchaseOrderDetail(records, isCopying) {
        if (records) {
            let i = 0;
            return records.map((data) => {
                i -= 1;
                return {
                    purchaseOrderDetailId: isCopying ? i : data.purchaseOrderDetailId,
                    purchaseOrderId: isCopying ? 0 : data.purchaseOrderId,
                    description: data.description,
                    quantity: Number(data.quantity),
                    unitPrice: Number(data.unitPrice),
                    subTotal: Number(data.subTotal),
                    partInventoryId: data.partInventoryId,
                    partStockNumber: data.partsInventory.partStockNumber,
                    netPrice: data.netPrice,
                    listPrice: data.listPrice,
                    partCost: data.partsInventory.partCost,
                };
            });
        }

        return [];
    }

    static mapPurchaseToSave(record) {
        if (record) {
            const recordMap = {
                purchaseOrderId: record.purchaseOrderId,
                vendorId: record.vendorId,
                roNumber: record.roNumber,
                shippingMethodId: record.shippingMethodId,
                orderDate: DateUtils.isValid(record.orderDate) ? DateUtils.toLocal(record.orderDate).toDate() : '',
                deliveryDate: DateUtils.isValid(record.deliveryDate) ? DateUtils.toLocal(record.deliveryDate).toDate() : '',
                lotName: record.lotName,
                notes: record.notes,
                partsClerk: record.partsClerk,
                salesTaxRate: record.salesTaxRate,
                purchaseDetails: this.mapDetailLines(record.purchaseDetails),
                estimateDelivery: record?.estimateDelivery ?? '',
            };

            if (record.purchaseOrderId === 0) recordMap.status = record.status;

            return recordMap;
        }

        return {};
    }

    static mapDetailLines(records) {
        if (records) {
            return records.map((data) => (
                {
                    purchaseOrderDetailId: data.purchaseOrderDetailId,
                    purchaseOrderId: data.purchaseOrderId,
                    partInventoryId: Number(data.partInventoryId),
                    description: data.description,
                    quantity: NumberUtils.round(data.quantity),
                    unitPrice: NumberUtils.round(data.unitPrice),
                    netPrice: NumberUtils.round(data.netPrice),
                    listPrice: NumberUtils.round(data.listPrice),
                }
            ));
        }

        return [];
    }

    static mapPartsInventoryToSave(record) {
        if (record) {
            return {
                partsInventoryId: record.partsInventoryId,
                partStockNumber: record.partStockNumber,
                description: record.description,
                quantityOnHand: record.quantityOnHand,
                reorderQuantity: record.reorderQuantity,
                binNumber: record.binNumber,
                vendorId: record.vendorId,
                partCost: record.partCost,
                netPrice: record.netPrice,
                active: record.active,
                isOem: record.isOem,
                lotName: record.lotName,
                quickNote: record.quickNote,
                shelf: record.shelf,
                isTaxable: record.isTaxable,
                universalProductCode: record.universalProductCode,
            };
        }

        return {};
    }

    static mapPartsTicket(record) {
        if (record) {
            return ({
                ...record,
                customerName: `${record?.customer?.firstName ?? ''} ${record?.customer?.lastName ?? ''}`,
                customerPhone: record?.customer?.cellPhone ?? '',
                customerEmail: record?.customer?.email ?? '',
                customerAddress: record?.customer?.address ?? '',
                ticketOpen: new Date(DateUtils.getOnlyDate(record.ticketOpen)),
                ticketClosed: new Date(DateUtils.getOnlyDate(record.ticketClosed)),
            });
        }

        return {};
    }

    static mapPartsTicketDetail(records) {
        if (records) {
            return records.map((data) => (
                {
                    partsTicketPartId: data.partsTicketPartId,
                    ticketNumber: data.ticketNumber,
                    partInventoryId: data.partInventoryId,
                    partNumber: data.partNumber,
                    bin: data.bin,
                    description: data.description,
                    quantity: Number(data.quantity),
                    listPrice: Number(data.listPrice),
                    netPrice: Number(data.netPrice),
                    discount: Number(data.discount),
                    total: Number(data.total),
                }
            ));
        }

        return [];
    }

    static mapPartsTicketToSave(record) {
        if (record) {
            return {
                ticketNumber: record.ticketNumber,
                ticketOpen: DateUtils.isValid(record.ticketOpen) ? DateUtils.toLocal(record.ticketOpen).toDate() : '',
                ticketClosed: DateUtils.isValid(record.ticketClosed) ? DateUtils.toLocal(record.ticketClosed).toDate() : '',
                customerCode: record.customerCode,
                counterPerson: record.counterPerson,
                quickNote: record.quickNote,
                salesType: record.salesType,
                lotName: record.lotName,
                isTaxable: record.isTaxable,
                discountPercent: record.discountPercent,
                partsTicketDetails: this.mapPartsTicketDetailLines(record.partsTicketDetails),
                lotId: record.lotId,
                counterPersonId: record.counterPersonId,
            };
        }

        return {};
    }

    static mapPartsTicketDetailLines(records) {
        if (records) {
            return records.map((data) => (
                {
                    partsTicketPartId: data.partsTicketPartId,
                    ticketNumber: data.ticketNumber,
                    partInventoryId: data.partInventoryId,
                    partNumber: data.partNumber,
                    bin: data.bin,
                    description: data.description,
                    quantity: NumberUtils.round(data.quantity),
                    listPrice: NumberUtils.round(data.listPrice),
                    netPrice: NumberUtils.round(data.netPrice),
                    partCost: NumberUtils.round(data.partCost),
                }
            ));
        }

        return [];
    }
}

import React, {
    useReducer, useEffect, useContext,
} from 'react';
import Container from 'components/widgets/Container';
import {
    Grid,
    makeStyles, Button, Dialog, DialogContent, AppBar, Toolbar, IconButton, Typography, Slide, Chip,
} from '@material-ui/core';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import LoadingMask from 'components/widgets/LoadingMask';
import AccountingStyles from 'styles/modules/accounting/AccountingStyles';
import { SaveIcon, CloseIcon } from 'components/icons';
import { Row, Form, Col } from 'react-bootstrap';
import InputNumber from 'components/widgets/InputNumber';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { useLazyQuery, useMutation } from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import DialogActions from '@material-ui/core/DialogActions';
import UserContext from 'components/context/UserContext';
import { useHistory, useParams } from 'react-router';
import { modules } from 'utils/enum/modules';
import { DataSort, FetchPolicy } from 'utils/enum/Core';
// todo: check why the datepicker doesn't work
import 'react-datepicker/dist/react-datepicker.css';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import PartsInventorySchema from 'utils/schema/parts/PartsInventorySchema';
import PartsQuery from 'services/graphQL/query/parts/PartsQuery';
import PartsMapper from 'services/mapData/PartsMapper';
import VendorQuery from 'services/graphQL/query/VendorQuery';
import DropdownQuery from 'components/widgets/DropdownQuery';
import PartsMutation from 'services/graphQL/mutate/parts/PartsMutation';
import { VendorType } from 'utils/enum/Vendors';
import { isEmpty } from 'lodash';
import { PartsActions } from 'utils/enum/PartsPurchaseEnum';
import ErrorMessageIndicator from 'components/widgets/ErrorMessageIndicator';
import PropTypes from 'prop-types';

const keyStore = new KeyStore();

const useStyle = makeStyles((theme) => AccountingStyles.dialogForm(theme));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export const ACTION_TYPES = {
    SET_RECORD_CHANGED: 'setRecordChanged',
    SET_ON_POPUP_CLOSE: 'setOnPopupClose',
    SET_INITIAL_STATE: 'setInitialState',
    SET_DEFAULT_LOT: 'setDefaultLot',
    SET_STATE_VALUES: 'setStateValues',
};

const PartsCreate = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORD_CHANGED: {
        const { value, isDirty } = action;
        return { ...state, record: value, isDirty };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_ON_POPUP_CLOSE: {
        return {
            ...state,
            couldLostData: true,
        };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    case ACTION_TYPES.SET_DEFAULT_LOT: {
        return {
            ...state,
            record: {
                ...state.record,
                lotId: action.value.lotId,
                lotName: action.value.lotName,
            },
        };
    }
    default:
        return state;
    }
};

const SortFields = {
    VENDOR_NAME: 'vendorName',
};

const PartsInventoryCreate = (props) => {
    const PARTS_WRITE_ADD = keyStore.hasPermission(Permission.PARTS_WRITE_ADD);
    const PARTS_WRITE_EDIT = keyStore.hasPermission(Permission.PARTS_WRITE_EDIT);
    const PARTS_OVERRIDE_PRICE = keyStore.hasPermission(Permission.PARTS_OVERRIDE_PRICE);
    const PARTS_CHANGE_PARTS_QUANTITY = keyStore.hasPermission(Permission.PARTS_CHANGE_PARTS_QUANTITY);

    const { id } = useParams();

    const history = useHistory();

    const initialState = {
        record: {
            partsInventoryId: 0,
            partStockNumber: '',
            description: '',
            quantityOnHand: 0,
            reorderQuantity: 0,
            binNumber: '',
            vendorId: 0,
            partCost: 0,
            listPrice: 0,
            netPrice: 0,
            active: true,
            flag: 0,
            isOem: false,
            lotName: '',
            quickNote: '',
            shelf: '',
            isTaxable: true,
            universalProductCode: '',
        },
        isDirty: false,
        couldLostData: false,
        vendorList: [],
    };

    const classes = useStyle();

    const { fromProcess, onPopupClose } = props;

    const [state, dispatch] = useReducer(PartsCreate, initialState);
    const {
        record, isDirty, couldLostData, vendorList,
    } = state;

    const {
        availableLots, defaultLotId, defaultLot,
    } = useContext(UserContext);

    if (availableLots && isEmpty(record.lotName)) {
        const lotId = defaultLotId;
        const lotName = defaultLot;
        if (lotName.length > 0) dispatch({ type: ACTION_TYPES.SET_DEFAULT_LOT, value: { lotId, lotName } });
    }

    const { isValid, errors } = isValidSchema(PartsInventorySchema, record);
    const isNew = record.partsInventoryId === 0;

    const [getVendorList] = useLazyQuery(VendorQuery.GET_VENDOR_LIST, {
        variables: {
            filter: {
                vendorType: [VendorType.REPAIR_VENDOR],
                active: true,
            },
            sort: {
                field: SortFields.VENDOR_NAME,
                dir: DataSort.ASC,
            },
        },
        onCompleted: (res) => {
            if (res.getVendorList) {
                const list = res.getVendorList?.map((item) => ({
                    vendorId: item.vendorId,
                    vendor: item.vendorName,
                }));

                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        vendorList: list,
                    },
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getPartsInventory, { loading }] = useLazyQuery(PartsQuery.GET_PARTS_INVENTORY, {
        onCompleted: (res) => {
            if (res.getPartsInventory) {
                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        record: res.getPartsInventory,
                    },
                });
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onClearForm = () => {
        dispatch({
            type: ACTION_TYPES.SET_INITIAL_STATE,
            value: {
                ...initialState,
                record: initialState.record,
            },
        });
    };

    const onCloseWindow = (forceClose = false) => {
        if (isDirty && !forceClose) {
            dispatch({
                type: ACTION_TYPES.SET_ON_POPUP_CLOSE,
            });
            return;
        }

        onClearForm();
        if (!fromProcess) history.push(`/${modules.PARTS}`);
        if (fromProcess) onPopupClose();
    };

    const mutationQuery = isNew ? PartsMutation.CREATE_PARTS_INVENTORY : PartsMutation.UPDATE_PARTS_INVENTORY;
    const [addRecord, { loading: isSaving }] = useMutation(mutationQuery, {
        onCompleted: (mutationData) => {
            if (mutationData?.createPartsInventory || mutationData?.updatePartsInventory) {
                ModalUtils.successMessage(null, 'Successfully saved!');

                if (!fromProcess) onCloseWindow(true);
                if (fromProcess) {
                    onPopupClose({
                        ...record,
                        partsInventoryId: mutationData?.createPartsInventory || mutationData?.updatePartsInventory,
                    });
                }
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const onSave = async () => {
        const recordToSave = PartsMapper.mapPartsInventoryToSave(record);

        await addRecord({
            variables: {
                record: recordToSave,
            },
        });
    };

    const onChangeValue = (field, value) => {
        if (value === record[field]) return;

        const currentRecord = { ...record };

        currentRecord[field] = value;

        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            value: currentRecord,
            isDirty: true,
        });
    };

    const getStatusChip = () => {
        if (record.partsInventoryId === 0) return null;

        let badgeStatus = classes.primary;
        if (record.active) badgeStatus = classes.badgeApproved;
        if (!record.active) badgeStatus = classes.badgeVoid;

        return (
            <>
                <Chip size="small" label={record.active ? PartsActions.ACTIVE : PartsActions.INACTIVE} className={badgeStatus} />
            </>
        );
    };

    const onCloseFormWithoutSave = () => {
        dispatch({ type: ACTION_TYPES.SET_STATE_VALUES, value: { couldLostData: false } });
    };

    useEffect(() => {
        if (Number(id) > 0 && !fromProcess) {
            getPartsInventory({
                variables: {
                    id: Number(id),
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        getVendorList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const disableControls = !((isNew && PARTS_WRITE_ADD) || (!isNew && PARTS_WRITE_EDIT));

    const messageToAllowSave = [];
    // eslint-disable-next-line no-unused-expressions
    errors?.forEach((error) => {
        messageToAllowSave.push(error.message);
    });

    return (
        <Dialog
            open
            onClose={onCloseWindow}
            maxWidth="sm"
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            TransitionComponent={Transition}
        >
            <AppBar className={classes.appBar}>
                <Toolbar className={classes.centerItems}>
                    <Typography variant="h6" className={classes.title}>
                        {isNew ? 'New Part' : `Edit Part ${record.partStockNumber}`}
                        {' - '}
                        {record.lotName}
                    </Typography>
                    <div className={classes.centerItems}>
                        <IconButton edge="start" color="inherit" onClick={onCloseWindow}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <DialogContent className={classes.noPadding}>
                { (loading) ? <LoadingMask />
                    : (
                        <Container className={classes.containerSplit}>
                            <Grid container spacing={1}>
                                <Grid item xs={7}>
                                    <Form.Group as={Row}>
                                        <Form.Label column="sm" xs={12} className="required">Lot Name</Form.Label>
                                        <Col xs={12}>
                                            <DropdownQuery
                                                name="lotName"
                                                className={isValidField(errors, 'lotName') ? 'invalid-field' : ''}
                                                value={record.lotName}
                                                placeHolder="select a lot"
                                                onChange={(name, newValue) => onChangeValue(name, newValue)}
                                                dataSource={{
                                                    localData: availableLots,
                                                    idField: 'value',
                                                    descriptionField: 'value',
                                                }}
                                                allowEmptyLine={false}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column="sm" xs={12} className="required">Vendor</Form.Label>
                                        <Col xs={12}>
                                            <DropdownQuery
                                                name="vendorId"
                                                disabled={disableControls}
                                                className={isValidField(errors, 'vendorId') ? 'invalid-field' : ''}
                                                value={record.vendorId}
                                                placeHolder="select a vendor"
                                                onChange={(name, newValue) => onChangeValue(name, newValue)}
                                                dataSource={{
                                                    localData: vendorList,
                                                    idField: 'vendorId',
                                                    descriptionField: 'vendor',
                                                }}
                                                defaultEmptyLineText="Select vendor"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column="sm" xs={12}>Universal Product Code (UPC)</Form.Label>
                                        <Col xs={12}>
                                            <Form.Control
                                                size="sm"
                                                disabled={disableControls}
                                                value={record.universalProductCode}
                                                placeholder="Universal Product Code (UPC)"
                                                onChange={(e) => onChangeValue('universalProductCode', e.target.value)}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column="sm" xs={12} className="required">Part Number</Form.Label>
                                        <Col xs={12}>
                                            <Form.Control
                                                className={isValidField(errors, 'partStockNumber') ? 'invalid-field' : ''}
                                                size="sm"
                                                value={record.partStockNumber}
                                                placeholder="Part stock"
                                                onChange={(e) => onChangeValue('partStockNumber', e.target.value)}
                                                disabled={disableControls}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column="sm" xs={12} className="required">Bin Number</Form.Label>
                                        <Col xs={12}>
                                            <Form.Control
                                                className={isValidField(errors, 'binNumber') ? 'invalid-field' : ''}
                                                size="sm"
                                                value={record.binNumber}
                                                placeholder="Bin Number"
                                                onChange={(e) => onChangeValue('binNumber', e.target.value)}
                                                disabled={disableControls}
                                            />
                                        </Col>
                                    </Form.Group>
                                </Grid>
                                <Grid item xs={5}>
                                    <Form.Group as={Row}>
                                        <Form.Label column="sm" xs={12} className="required">Quantity on hand</Form.Label>
                                        <Col xs={12}>
                                            <InputNumber
                                                className={isValidField(errors, 'quantityOnHand') ? 'invalid-field' : ''}
                                                size="sm"
                                                value={record.quantityOnHand}
                                                placeholder="QOH"
                                                onChange={(value) => onChangeValue('quantityOnHand', value)}
                                                disabled={disableControls || !PARTS_CHANGE_PARTS_QUANTITY}
                                                allowNegative={false}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column="sm" xs={12} className="required">Re-Order Quantity</Form.Label>
                                        <Col xs={12}>
                                            <InputNumber
                                                size="sm"
                                                value={record.reorderQuantity}
                                                className={isValidField(errors, 'reorderQuantity') ? 'invalid-field' : ''}
                                                thousandSeparator
                                                placeholder="Re-order quantity"
                                                onChange={(value) => onChangeValue('reorderQuantity', value)}
                                                disabled={disableControls}
                                                allowNegative={false}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column="sm" xs={12} className="required">Cost</Form.Label>
                                        <Col xs={12}>
                                            <InputNumber
                                                size="sm"
                                                showCurrency
                                                value={record.partCost}
                                                className={isValidField(errors, 'partCost') ? 'invalid-field' : ''}
                                                thousandSeparator
                                                placeholder="Part cost"
                                                onChange={(value) => onChangeValue('partCost', value)}
                                                disabled={disableControls || !PARTS_OVERRIDE_PRICE}
                                                allowNegative={false}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column="sm" xs={12} className="required">List Price</Form.Label>
                                        <Col xs={12}>
                                            <InputNumber
                                                size="sm"
                                                showCurrency
                                                value={record.netPrice}
                                                className={isValidField(errors, 'netPrice') ? 'invalid-field' : ''}
                                                thousandSeparator
                                                placeholder="List Price"
                                                onChange={(value) => onChangeValue('netPrice', value)}
                                                disabled={disableControls || !PARTS_OVERRIDE_PRICE}
                                                allowNegative={false}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Col xs={12}>
                                            <Form.Check
                                                id="isTaxablePartsId"
                                                checked={Boolean(record.isTaxable)}
                                                type="checkbox"
                                                label="Is Taxable"
                                                onChange={(e) => onChangeValue('isTaxable', e.target.checked)}
                                            />
                                        </Col>
                                    </Form.Group>
                                </Grid>
                                <Grid item xs={12}>
                                    <Form.Group as={Row}>
                                        <Form.Label column="sm" xs={12} className="required">Description</Form.Label>
                                        <Col xs={12}>
                                            <Form.Control
                                                className={isValidField(errors, 'description') ? 'invalid-field' : ''}
                                                size="sm"
                                                value={record.description}
                                                placeholder="Description"
                                                onChange={(e) => onChangeValue('description', e.target.value)}
                                                disabled={disableControls}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column="sm" xs={12} className="required">Shelf</Form.Label>
                                        <Col xs={12}>
                                            <Form.Control
                                                className={isValidField(errors, 'shelf') ? 'invalid-field' : ''}
                                                size="sm"
                                                value={record.shelf}
                                                placeholder="Shelf"
                                                onChange={(e) => onChangeValue('shelf', e.target.value)}
                                                disabled={disableControls}
                                            />
                                        </Col>
                                    </Form.Group>
                                </Grid>
                                <Grid item xs={12}>
                                    <Form.Group as={Row}>
                                        <Form.Label column="sm" xs={12} className="required">Notes</Form.Label>
                                        <Col xs={12}>
                                            <Form.Control
                                                className={isValidField(errors, 'quickNote') ? 'invalid-field' : ''}
                                                size="sm"
                                                value={record.quickNote}
                                                placeholder="Notes"
                                                onChange={(e) => onChangeValue('quickNote', e.target.value)}
                                                disabled={disableControls}
                                                as="textarea"
                                            />
                                        </Col>
                                    </Form.Group>
                                </Grid>
                            </Grid>
                            {couldLostData && (
                                <ConfirmDialog
                                    title="Attention - You have unsaved changes!"
                                    description="Do you want to close without saving?"
                                    open={couldLostData}
                                    variant="outlined"
                                    titlePrimary="Close"
                                    titleSecondary="Cancel"
                                    onClose={onCloseFormWithoutSave}
                                    onClickSecondary={onCloseFormWithoutSave}
                                    onClickPrimary={() => onCloseWindow(true)}
                                />
                            )}
                        </Container>
                    )}
            </DialogContent>
            <DialogActions className={classes.borderTop}>
                <div className={classes.fullWidth}>
                    <Toolbar className={classes.centerItems}>
                        <div className={classes.buttonSpacing}>
                            { getStatusChip() }
                        </div>
                        {((isNew && PARTS_WRITE_ADD) || (!isNew && PARTS_WRITE_EDIT))
                        && (
                            <div className={classes.buttonSpacing}>
                                <ErrorMessageIndicator
                                    messages={messageToAllowSave}
                                >
                                    <Button
                                        className={classes.buttonSave}
                                        startIcon={<SaveIcon />}
                                        size="small"
                                        disabled={!(isValid && isDirty)}
                                        onClick={onSave}
                                    >
                                        Save
                                    </Button>
                                </ErrorMessageIndicator>
                            </div>
                        )}
                    </Toolbar>
                </div>
            </DialogActions>
            {isSaving && <DialogActionMessage message="Saving information... " />}
        </Dialog>
    );
};

PartsInventoryCreate.propTypes = {
    fromProcess: PropTypes.bool,
    onPopupClose: PropTypes.func,
};

PartsInventoryCreate.defaultProps = {
    fromProcess: false,
    onPopupClose: () => {},
};

export default PartsInventoryCreate;

import { isEmpty } from 'lodash';
import PhoneUtils from 'lib/PhoneUtils';
import NumberUtils from 'lib/NumberUtils';
import { DefaultCountry } from 'utils/enum/Customer';
import DateUtils, { DateFormat } from 'lib/DateUtils';

export default class CustomerMapper {
    // This mapper format some fields and add default values
    static mapCustomerData(data = {}) {
        const currentAddress = data.currentAddress || {};
        const currentEmployment = data.currentEmployment || {};

        return {
            customerCode: data.customerCode,
            customerId: data.customerId || '',
            firstName: data.firstName || '',
            middleName: data.middleName || '',
            lastName: data.lastName || '',
            email: data.email || '',
            cellPhone: PhoneUtils.formatPhone(data.cellPhone),
            workPhone: PhoneUtils.formatPhone(data.workPhone),
            homePhone: PhoneUtils.formatPhone(data.homePhone),
            dob: DateUtils.setTimeToZero(data.dob),
            ssn: data.ssn || '',
            dln: data.dln || '',
            isBusiness: data.isBusiness,
            allowCallCellPhone: data.allowCallCellPhone,
            allowTextCellPhone: data.allowTextCellPhone,
            allowCallHomePhone: data.allowCallHomePhone,
            allowCallWorkPhone: data.allowCallWorkPhone,
            isTaxable: data.isTaxable,
            language: data.language,
            currentAddress: {
                customerAddressId: currentAddress.customerAddressId,
                customerId: currentAddress.customerId,
                parentId: currentAddress.parentId,
                address1: currentAddress.address1 || '',
                address2: currentAddress.address2 || '',
                phone: PhoneUtils.formatPhone(currentAddress.phone),
                housingStatus: currentAddress.housingStatus || '',
                mortgageOrRent: currentAddress.mortgageOrRent || '',
                start: DateUtils.format(currentAddress.start, 'L'),
                end: DateUtils.format(currentAddress.end, 'L'),
                totalMonths: currentAddress.totalMonths || 0,
                city: currentAddress.city || '',
                state: currentAddress.state || '',
                county: currentAddress.county || '',
                zipCode: currentAddress.zipCode || '',
                country: currentAddress.country || DefaultCountry.COUNTRY,
            },
            currentEmployment: {
                customerId: currentEmployment.customerId,
                customerEmploymentId: currentEmployment.customerEmploymentId,
                parentId: currentEmployment.parentId,
                status: currentEmployment.status,
                employer: currentEmployment.employer,
                title: currentEmployment.title,
                start: currentEmployment.start,
                end: currentEmployment.end,
                monthlyIncome: currentEmployment.monthlyIncome,
                workPhone: currentEmployment.workPhone,
                otherIncomeMonthly: currentEmployment.otherIncomeMonthly,
                otherIncomeSource: currentEmployment.otherIncomeSource,
                address1: currentEmployment.address1,
                address2: currentEmployment.address2,
                city: currentEmployment.city,
                state: currentEmployment.state,
                county: currentEmployment.county,
                zipCode: currentEmployment.zipCode,
                country: currentEmployment.country || DefaultCountry.COUNTRY,
                totalMonths: currentEmployment.totalMonths,
            },
        };
    }

    static mapAddressData(record) {
        if (record) {
            return {
                address1: record.address1,
                address2: record.address2,
                city: record.city,
                state: record.state,
                county: record.county,
                zipCode: record.zipCode,
                country: record.country || DefaultCountry.COUNTRY,
                start: DateUtils.isValid(record.start) ? DateUtils.toLocal(record.start).toDate() : '',
                end: DateUtils.isValid(record.end) ? DateUtils.toLocal(record.end).toDate() : '',
                mortgageOrRent: record.mortgageOrRent,
                housingStatus: record.housingStatus,
                phone: record.phone,
                isCurrentAddress: record.isCurrentAddress || false,
            };
        }

        return {};
    }

    static mapEmploymentData(record) {
        if (record) {
            return ({
                employer: record.employer,
                title: record.title,
                workPhone: record.workPhone,
                monthlyIncome: record.monthlyIncome,
                otherIncomeMonthly: record.otherIncomeMonthly,
                otherIncomeSource: record.otherIncomeSource,
                end: DateUtils.isValid(record.end) ? DateUtils.toLocal(record.end).toDate() : '',
                start: DateUtils.isValid(record.start) ? DateUtils.toLocal(record.start).toDate() : '',
                isCurrentEmployment: record.isCurrentEmployment || false,
                address1: record.address1,
                address2: record.address2,
                zipCode: record.zipCode,
                city: record.city,
                county: record.county,
                state: record.state,
                country: record.country || DefaultCountry.COUNTRY,
            });
        }

        return {};
    }

    static mapOpportunity({ record = [] }) {
        return record.map((item) => ({
            status: item.status,
            leadCode: item.leadCode,
            processStage: item.processStage,
            modifiedOn: DateUtils.format(item.modifiedOn, DateFormat.DATETIME_WITHOUT_SECONDS),
            make: isEmpty(item.vehicle?.stockNumber) ? item.vehicleMake : item.vehicle.make,
            trim: isEmpty(item.vehicle?.stockNumber) ? item.vehicleTrim : item.vehicle.trim,
            year: isEmpty(item.vehicle?.stockNumber) ? item.vehicleYear : item.vehicle.year,
            model: isEmpty(item.vehicle?.stockNumber) ? item.vehicleModel : item.vehicle.model,
        }));
    }

    static mapDeal({ record = [] }) {
        return record.map((item) => ({
            status: item.status,
            dealType: item.dealType,
            make: item.vehicle?.make,
            year: item.vehicle?.year,
            model: item.vehicle?.model,
            accountNumber: item.accountNumber,
            stockNumber: item.vehicle?.stockNumber,
            customStockNumber: item.vehicle?.customStockNumber ?? item.vehicle?.stockNumber,
            soldDate: DateUtils.format(item.soldDate),
        }));
    }

    static mapRO({ record = [] }) {
        return record.map((item) => ({
            status: item.status,
            vehicleVin: item.vehicleVin,
            vehicleYear: item.vehicleYear,
            vehicleMake: item.vehicleMake,
            vehicleModel: item.vehicleModel,
            invoiceNumber: item.invoiceNumber,
            total: NumberUtils.applyCurrencyFormat(item.total),
            date: DateUtils.format(item.date, DateFormat.DATETIME_WITHOUT_SECONDS),
        }));
    }
}

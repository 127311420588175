import * as yup from 'yup';

const CashierPaymentSchema = yup.object().shape({
    transactionType: yup.string().required(),
    lotName: yup.string().required(),
    reason: yup.string().required(),
    paymentMethodId: yup.string().required(),
    amount: yup.number().required().moreThan(0),
    paymentOptionId: yup.string().required(),
});

export default CashierPaymentSchema;

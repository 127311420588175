import { gql } from '@apollo/client';

export default class UserQuery {
    static GET_USERS = gql`
        query getUsers($types: [String]!) {
            getUsers(types: $types) {
                userId
                employeeType
                firstName
                lastName
            }
        }
    `;

    static GET_USER_LOTS = gql`
        query getUserLots {
            getUserLots
        }
    `;

    static GET_TIME_ZONES = gql`
        query getTimeZones($lotId: Int!) {
            getTimeZones(lotId: $lotId) {
                companyTimeZone {
                    timeZone
                    timeZoneId
                }
                lotTimeZone {
                    timeZone
                    timeZoneId
                }
            }
        }
    `;

    // TODO: Verify which fields are being sent at the time of login to send them to be brought at the moment of obtaining the user's information
    static USER_DETAILS = gql`
        query {
            userDetails {
                userId
                username: userName
                employeeType
                lots {
                    lotId
                    lotName
                    packCost
                }
                defaultLot
                usersLot
                userPicture
                company {
                    carfaxId
                    companyName
                    secureCloseEnabled
                    logo
                    activeProducts
                    isCarfaxEnabled
                }
                firstName
                lastName
                customStockEnabled
                customStockSettings {
                    name
                    value
                }
            }
        }
    `;

    static GET_BANKING_EMPLOYEES = gql`
    query getBankingEmployees{
        getBankingEmployees{
            userId
            firstName
            lastName
            address
        }
    }
    `;

    static GET_ALL_USERS = gql`
query getAllUsers($showInactive: Boolean) {
    getAllUsers(showInactive: $showInactive) {
        userId
        firstName
        lastName
        username
    }
}`;

    static GET_USERS_BY_COMPANY = gql`
        query getUsersByCompany($includeInactive: Boolean) {
            getUsersByCompany(includeInactive: $includeInactive) {
                firstName
                lastName
                employeeType
                department
                userPicture
                userId
            }
        }
    `;

    static GET_RECORD_MANAGERS = gql`
        query getRecordManagers($lotId: Int!) {
            getRecordManagers(lotId: $lotId) {
                userId
                firstName
                lastName
                userPicture
            }
        }
    `;

    static GET_SALESPERSONS = gql`
        query getSalesPersons($lotId: Int!) {
            getSalesPersons(lotId: $lotId) {
                userId
                firstName
                lastName
                userPicture
            }
        }
    `;

    static GET_RECORD_MANAGERS_BY_LOTS = gql`
        query getRecordManagersByLots($lots: [Int!]!) {
            getRecordManagersByLots(lots: $lots) {
                userId
                firstName
                lastName
            }
        }
    `;

    static GET_SALESPERSONS_BY_LOTS = gql`
        query getSalespersonsByLots($lots: [Int!]!) {
            getSalespersonsByLots(lots: $lots) {
                userId
                firstName
                lastName
                lots
            }
        }
    `;

    static GET_RECORD_MANAGERS_AND_SALESPERSON_BY_LOTS = gql`
        query getRecordManagersAndSalespersonByLots($lots: [Int!]!) {
            getRecordManagersAndSalespersonByLots(lots: $lots) {
                userId
                firstName
                lastName
                avatar
                isRecordManager
            }
        }
    `;

    static GET_SALESPERSONS_LIST = gql`
        query getSalesPersonsList($includeRecordManagers: Boolean) {
            getSalesPersonsList(includeRecordManagers: $includeRecordManagers) {
                firstName
                lastName
                userId
                isRecordManager
            }
        }
    `;

static GET_USER_AND_SALARY_INFO = gql`
query getUsersAndSalary($types: [String]!) {
    getUsersAndSalary(types: $types) {
        userId
        employeeType
        firstName
        lastName
        salary
        payType
        userPicture
    }
}
`;
}

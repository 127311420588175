export default class ErrorMessages {
    static get LOT_ACCESS_DENIED() {
        return 'LOT ACCESS DENIED';
    }

    static get FORBIDDEN() {
        return 'FORBIDDEN';
    }

    static ACCESS_DENIED(value) {
        return `${value} ACCESS DENIED`;
    }

    static get DEAL_NOT_FOUND() {
        return 'Account Number not found';
    }

    static get DEAL_DELETE_SOLD_TRADE() {
        return 'Do you want to proceed to delete deal';
    }
}

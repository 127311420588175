import React, { Component } from 'react';

// Others
import PropTypes from 'prop-types';
import ModalUtils from 'utils/ModalUtils';
import { DefaultCountry } from 'utils/enum/Customer';

// Mapper
import CustomerMapper from 'services/mapData/CustomerMapper';

// GraphQL
import GraphQLClient from 'services/apollo/GraphQLClient';
import CustomerQuery from 'services/graphQL/query/CustomerQuery';
import CustomerSubscription from 'services/graphQL/subscription/CustomerSubscription';

const PersonalTabContainer = (WrappedComponent) => class extends Component {
    static propTypes = {
        customerCode: PropTypes.string.isRequired,
        isCustomStockEnabled: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.graphqlClient = new GraphQLClient();

        this.state = {
            loading: false,
            data: {
                customerCode: null,
                customerId: '',
                firstName: '',
                middleName: '',
                email: '',
                lastName: '',
                cellPhone: '',
                workPhone: '',
                homePhone: '',
                dob: '',
                ssn: '',
                dln: '',
                isBusiness: false,
                isTaxable: true,
                language: 'English',
                currentAddress: {
                    customerAddressId: null,
                    customerId: null,
                    parentId: null,
                    address1: '',
                    address2: '',
                    phone: '',
                    housingStatus: '',
                    mortgageOrRent: 0,
                    start: '',
                    end: '',
                    totalMonths: 0,
                    city: '',
                    state: '',
                    county: '',
                    zipCode: '',
                    country: DefaultCountry.COUNTRY,
                },
                currentEmployment: {},
            },
            opportunities: [],
            loadingOpportunities: false,
            deals: [],
            loadingDeals: false,
            invoices: [],
            loadingInvoices: false,
        };
        this.customerSubscription = null;
        this.initBind();
    }

    getServicesData() {
        const { props: { customerCode } } = this;
        const input = {
            customerCode,
        };
        this.setState({
            loading: true,
        });
        this.graphqlClient
            .query(CustomerQuery.GET_CUSTOMER_AND_ADDRESS, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.getCustomerAndAddress) {
                    const { getCustomerAndAddress } = data;
                    this.setData(getCustomerAndAddress);
                    this.getOpportunities(getCustomerAndAddress);
                    this.getDeals(getCustomerAndAddress);
                    this.getROs(getCustomerAndAddress);
                }
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    }

    setData(record) {
        this.setState({
            data: CustomerMapper.mapCustomerData(record),
        }, () => {
            if (this.customerSubscription === null) {
                this.subscribeEditCustomer();
            }
        });
    }

    getOpportunities = ({ customerId }) => {
        this.setState({
            loadingOpportunities: true,
        });
        this.graphqlClient
            .query(CustomerQuery.GET_CUSTOMER_OPPORTUNITIES_BY_CUSTOMER_ID, { customerId })
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.getCustomerOpportunitiesByCustomerId) {
                    const { getCustomerOpportunitiesByCustomerId } = data;
                    this.setState({
                        opportunities: CustomerMapper.mapOpportunity({ record: getCustomerOpportunitiesByCustomerId }),
                    });
                }
            })
            .finally(() => {
                this.setState({
                    loadingOpportunities: false,
                });
            });
    }

    getOpportunitiesTableColumns = () => [{
        minWidth: 10,
        id: 'leadCode',
        Header: 'Lead Code',
        accessor: 'leadCode',
        className: 'd-flex-justify-center-align-center',
    }, {
        id: 'status',
        minWidth: 10,
        Header: 'Status',
        accessor: 'status',
        className: 'd-flex-justify-center-align-center',
    }, {
        minWidth: 10,
        id: 'processStage',
        Header: 'Process Stage',
        accessor: 'processStage',
        className: 'd-flex-justify-center-align-center',
    }, {
        minWidth: 10,
        id: 'modifiedOn',
        Header: 'Last Updated',
        accessor: 'modifiedOn',
        className: 'd-flex-justify-center-align-center',
    }, {
        id: 'year',
        minWidth: 10,
        Header: 'Year',
        accessor: 'year',
        className: 'd-flex-justify-center-align-center',
    }, {
        id: 'make',
        minWidth: 10,
        Header: 'Make',
        accessor: 'make',
        className: 'd-flex-justify-center-align-center',
    }, {
        id: 'model',
        minWidth: 10,
        Header: 'Model',
        accessor: 'model',
        className: 'd-flex-justify-center-align-center',
    }, {
        id: 'trim',
        minWidth: 10,
        Header: 'Trim',
        accessor: 'trim',
        className: 'd-flex-justify-center-align-center',
    }]

    getDeals = ({ customerId }) => {
        this.setState({
            loadingDeals: true,
        });
        this.graphqlClient
            .query(CustomerQuery.GET_CUSTOMER_DEALS_BY_CUSTOMER_ID, { customerId })
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.getCustomerDealsByCustomerId) {
                    const { getCustomerDealsByCustomerId } = data;
                    this.setState({
                        deals: CustomerMapper.mapDeal({ record: getCustomerDealsByCustomerId }),
                    });
                }
            })
            .finally(() => {
                this.setState({
                    loadingDeals: false,
                });
            });
    }

    getDealsTableColumns = () => {
        const { props: { isCustomStockEnabled } } = this;

        return [{
            minWidth: 10,
            id: 'accountNumber',
            Header: 'Account Number',
            accessor: 'accountNumber',
            className: 'd-flex-justify-center-align-center',
        }, {
            id: 'status',
            minWidth: 10,
            Header: 'Status',
            accessor: 'status',
            className: 'd-flex-justify-center-align-center',
        }, {
            minWidth: 10,
            id: 'dealType',
            Header: 'Deal Type',
            accessor: 'dealType',
            className: 'd-flex-justify-center-align-center',
        }, {
            minWidth: 10,
            id: 'soldDate',
            Header: 'Sold Date',
            accessor: 'soldDate',
            className: 'd-flex-justify-center-align-center',
        }, {
            id: isCustomStockEnabled ? 'customStockNumber' : 'stockNumber',
            minWidth: 10,
            Header: 'Stock Number',
            accessor: isCustomStockEnabled ? 'customStockNumber' : 'stockNumber',
            className: 'd-flex-justify-center-align-center',
        }, {
            id: 'year',
            minWidth: 10,
            Header: 'Year',
            accessor: 'year',
            className: 'd-flex-justify-center-align-center',
        }, {
            id: 'make',
            minWidth: 10,
            Header: 'Make',
            accessor: 'make',
            className: 'd-flex-justify-center-align-center',
        }, {
            id: 'model',
            minWidth: 10,
            Header: 'Model',
            accessor: 'model',
            className: 'd-flex-justify-center-align-center',
        }];
    }

    getROs = ({ customerId }) => {
        this.setState({
            loadingInvoices: true,
        });
        this.graphqlClient
            .query(CustomerQuery.GET_ROS_BY_CUSTOMER_ID, { customerId })
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.getROsByCustomerId) {
                    const { getROsByCustomerId } = data;
                    this.setState({
                        invoices: CustomerMapper.mapRO({ record: getROsByCustomerId }),
                    });
                }
            })
            .finally(() => {
                this.setState({
                    loadingInvoices: false,
                });
            });
    }

    getROsTableColumns = () => [{
        minWidth: 10,
        id: 'invoiceNumber',
        Header: 'Invoice Number',
        accessor: 'invoiceNumber',
        className: 'd-flex-justify-center-align-center',
    }, {
        id: 'status',
        minWidth: 10,
        Header: 'Status',
        accessor: 'status',
        className: 'd-flex-justify-center-align-center',
    }, {
        minWidth: 10,
        id: 'date',
        Header: 'Date',
        accessor: 'date',
        className: 'd-flex-justify-center-align-center',
    }, {
        minWidth: 10,
        id: 'total',
        Header: 'Total',
        accessor: 'total',
        className: 'd-flex-justify-center-align-center',
    }, {
        id: 'vehicleVin',
        minWidth: 10,
        Header: 'VIN',
        accessor: 'vehicleVin',
        className: 'd-flex-justify-center-align-center',
    }, {
        id: 'vehicleYear',
        minWidth: 10,
        Header: 'Year',
        accessor: 'vehicleYear',
        className: 'd-flex-justify-center-align-center',
    }, {
        id: 'vehicleMake',
        minWidth: 10,
        Header: 'Make',
        accessor: 'vehicleMake',
        className: 'd-flex-justify-center-align-center',
    }, {
        id: 'vehicleModel',
        minWidth: 10,
        Header: 'Model',
        accessor: 'vehicleModel',
        className: 'd-flex-justify-center-align-center',
    }]

    subscribeEditCustomer() {
        const { state: { data: { customerId } } } = this;
        const input = {
            customerId,
        };

        this.graphqlClient.subscribe(this.responseSubscription, CustomerSubscription.CUSTOMER_EDITED, input)
            .then((response) => {
                this.customerSubscription = response;
            });
    }

    responseSubscription(record) {
        const { data } = record;

        if (data && data.customerEdited) {
            const { customerEdited } = data;
            this.setData(customerEdited);
        }
    }

    unsubscribeEditCustomer() {
        if (this.customerSubscription) {
            this.customerSubscription.unsubscribe();
        }
    }

    initBind() {
        this.setData = this.setData.bind(this);
        this.getServicesData = this.getServicesData.bind(this);
        this.responseSubscription = this.responseSubscription.bind(this);
        this.subscribeEditCustomer = this.subscribeEditCustomer.bind(this);
        this.unsubscribeEditCustomer = this.unsubscribeEditCustomer.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                getServicesData={this.getServicesData}
                getROsTableColumns={this.getROsTableColumns}
                getDealsTableColumns={this.getDealsTableColumns}
                subscribeEditCustomer={this.subscribeEditCustomer}
                unsubscribeEditCustomer={this.unsubscribeEditCustomer}
                getOpportunitiesTableColumns={this.getOpportunitiesTableColumns}
            />
        );
    }
};

export default PersonalTabContainer;

import { gql } from '@apollo/client';

export default class PartsQuery {
  static GET_PARTS_INVENTORY_LIST = gql`
    query getPartsInventoryList(
      $paginate: PaginationInput
      $sort: [SortField]
      $filter: PartsInventoryFilter
    ) {
      getPartsInventoryList(paginate: $paginate, sort: $sort, filter: $filter) {
        totalCount
        data {
          partsInventoryId
          partStockNumber
          description
          listPrice
          netPrice
          partCost
          quantityOnHand
          quickNote
          lotName
          active
          binNumber
          shelf
          reorderQuantity
          isTaxable
          universalProductCode
        }
      }
    }
  `;

static GET_PARTS_INVENTORY_LIST_SELECT = gql`
query getPartsInventoryList(
  $paginate: PaginationInput
  $sort: [SortField]
  $filter: PartsInventoryFilter
) {
  getPartsInventoryList(paginate: $paginate, sort: $sort, filter: $filter) {
    totalCount
    data {
      partsInventoryId
      partStockNumber
      description
      listPrice
      netPrice
      partCost
      quantityOnHand
      lotName
      binNumber
      active
      isTaxable
      universalProductCode
    }
  }
}
`;

static GET_PARTS_TICKET_LIST = gql`
    query getPartsTicketList(
      $paginate: PaginationInput
      $sort: [SortField]
      $filter: PartsTicketFilter
    ) {
      getPartsTicketList(paginate: $paginate, sort: $sort, filter: $filter) {
        totalCount
        data {
          ticketNumber
          ticketOpen
          customer
          counterPerson
          quickNote
          status
          lotName
          salesType
          totalTax
          discount
          subTotal
          total
          postedDate
        }
      }
    }
  `;

static GET_PURCHASE_LIST = gql`
    query getPurchaseOrderList(
      $paginate: PaginationInput
      $sort: [SortField]
      $filter: PurchaseOrderFilter
    ) {
      getPurchaseOrderList(paginate: $paginate, sort: $sort, filter: $filter) {
        totalCount
        data {
          purchaseOrderId
          oemOrderNumber
          orderType
          vendor
          shippingMethodId
          shippingMethod
          partsClerk
          orderDate
          deliveryDate
          roNumber
          subTotal
          salesTax
          total
          status
          notes
        }
      }
    }
  `;

static GET_PURCHASE_DETAIL_LIST = gql`
    query getPurchaseOrderDetails($id: Int!) {
      getPurchaseOrderDetails(id: $id) {
        purchaseOrderDetailId
        purchaseOrderId
        description
        quantity
        unitPrice
        netPrice
        listPrice
        subTotal
        partInventoryId
        partsInventory{
          partStockNumber
          partCost
        }
      }
    }
  `;

static GET_PURCHASE_ORDER = gql`
query getPurchaseOrder($id: Int!) {
      getPurchaseOrder(id: $id) {
        purchaseOrderId
        oemOrderNumber
        orderType
        shippingMethodId
        partsClerk
        orderDate
        deliveryDate
        roNumber
        total
        status
        notes
        vendorId
        lotName
        salesTaxRate
        estimateDelivery
        purchaseOrderReturnId
      }
    }`;

static GET_PARTS_INVENTORY = gql`
query getPartsInventory($id: Int!) {
  getPartsInventory(id: $id) {
        partsInventoryId
        partStockNumber
        description
        quantityOnHand
        reorderQuantity
        binNumber
        vendorId
        partCost
        listPrice
        netPrice
        active
        flag
        isOem
        isEditing
        editingBy
        editingTime
        lotName
        quickNote
        shelf
        isTaxable
        universalProductCode
      }
    }`;

static GET_PARTS_INVENTORY_NOTES = gql`
query getPartsInventoryNotes($id: Int!) {
  getPartsInventoryNotes(partsInventoryId: $id) {
        noteUser
        noteDate
        noteText
        client
      }
    }`;

static GET_PARTS_TICKETS_ASSOCIATED = gql`
query getPartsTicketAssociatedToPartsInventory($id: Int!) {
  getPartsTicketAssociatedToPartsInventory(partsInventoryId: $id) {
        ticketNumber
        ticketOpen
        customer
        status
      }
    }`;

static GET_PURCHASE_ORDERS_ASSOCIATED = gql`
query getPurchaseOrderAssociatedToPartsInventory($id: Int!) {
  getPurchaseOrderAssociatedToPartsInventory(partsInventoryId: $id) {
        purchaseOrderId
        orderDate
        deliveryDate
        vendor
        status
        unitPrice
        netPrice
        listPrice
      }
    }`;

static GET_PARTS_DEFAULT_SETTINGS = gql`
query getPartsSettings($key: [String]){
  getPartsSettings(key: $key){
    key
    value
  }
}`;

static GET_PARTS_TICKET_DETAIL_LIST = gql`
    query getPartsTicketDetails($id: Int!) {
      getPartsTicketDetails(id: $id) {
        partsTicketPartId
        ticketNumber
        partInventoryId
        partNumber
        bin
        description
        quantity
        listPrice
        netPrice
        discount
        total
        partCost
      }
    }
  `;

static GET_PARTS_TICKET = gql`
query getPartsTicket($id: Int!) {
  getPartsTicket(id: $id) {
        ticketNumber
        ticketOpen
        ticketClosed
        customerCode
        counterPerson
        status
        quickNote
        salesType
        totalTax
        totalListPrice
        totalNetPrice
        discount
        lotName
        isTaxable
        discountPercent
        postedDate
        counterPersonId
        lotId
        surplusTax
        total
        customer {
          customerCode
          firstName
          lastName
          address
          email
          cellPhone
        }
      }
    }`;

static GET_PARTS_DEFAULT_SETTINGS = gql`
query getPartsDefaultSettings($lotName: String!){
  getPartsDefaultSettings(lotName: $lotName){
    surplusTax
    customPartTaxRateEnabled
    customPartTaxRateValue
  }
}`;
}

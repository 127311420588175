import React, { useReducer, useEffect, useContext } from 'react';

import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import NumberUtils from 'lib/NumberUtils';
import Radio from '@material-ui/core/Radio';
import MessageUtils from 'utils/MessageUtils';
import InputNumber from 'components/widgets/InputNumber';
import LeadHelper from 'components/modules/lead/LeadHelper';
import DialogActions from 'components/widgets/modal/DialogActions';
import VehicleDialog from 'components/modules/deals/read/vehicle/VehicleDialog';
import UserContext from 'components/context/UserContext';
import useCustomStockNumber from 'components/hook/inventory/useCustomStockNumber';

// Material UI
import {
    Dialog,
    DialogContent,
    makeStyles,
    IconButton,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// Bootstrap
import { Form, Row, Col } from 'react-bootstrap';

// Apollo Client
import { useMutation } from '@apollo/client';
import OpportunityMapper from 'services/mapData/OpportunityMapper';
import OpportunityMutation from 'services/graphQL/mutate/crm/OpportunityMutation';
import Select from 'components/widgets/Select';

const PRICE = {
    STICKER_PRICE: 'stickerPrice',
    INTERNET_PRICE: 'internetPrice',
};
const useStyles = makeStyles((theme) => ({
    row: {
        margin: 0,
        marginBottom: 10,
    },
    group: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        '& > label': {
            marginBottom: 0,
            marginRight: 10,
            minWidth: 60,
            textAlign: 'end',
            color: theme.palette.text.minsk,
            fontSize: '14px',
            fontWeight: 500,
        },
        '& > *:last-child': {
            flex: 1,
        },
    },
    firstColumn: {
        paddingRight: '10px',
    },
    searchIcon: {
        marginTop: '3px',
    },
    inputType: {
        justifyContent: 'center',
        marginBottom: theme.spacing(1.5),
    },
    searchInventory: {
        marginRight: theme.spacing(6),
    },
    inputTypeLabel: {
        fontSize: 'larger',
    },
    dialogContent: {
        width: 650,
    },
    containerSearch: {
        width: 30,
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'center',
    },
}));

const ACTION_TYPES = {
    LOAD_DATA: 'loadData',
    CLEAR_DATA: 'clearData',
    CHANGE_VALUE: 'changeValue',
    TOGGLE_MODAL: 'toggleModal',
    ON_PRICE_SELECTED: 'onPriceSelected',
    TOGGLE_INPUT_TYPE: 'toggleInputType',
};

const INPUT_TYPES = {
    SEARCH: 'Search',
    MANUAL: 'Manual',
};

const clearedData = {
    vin: '',
    trim: '',
    year: '',
    make: '',
    price: 0,
    model: '',
    stockNumber: 0,
};

const initState = {
    inputType: 'Search',
    isDialogOpened: false,
    priceOption: [],
    priceSelected: null,
    data: {
        ...clearedData,
    },
};

const reducer = (state, action) => {
    const { type, payload, inputType } = action;

    switch (type) {
    case ACTION_TYPES.LOAD_DATA:
        return update(state, {
            data: { $set: payload.data },
            inputType: { $set: inputType },
            vehicle: { $set: payload.data },
            priceOption: { $set: action.priceOption },
            priceSelected: { $set: action.priceSelected },
        });
    case ACTION_TYPES.CHANGE_VALUE:
        return update(state, {
            data: {
                [payload.field]: { $set: payload.value },
            },
        });
    case ACTION_TYPES.TOGGLE_MODAL:
        return update(state, {
            isDialogOpened: { $set: !state.isDialogOpened },
        });
    case ACTION_TYPES.TOGGLE_INPUT_TYPE:
        return update(state, {
            inputType: { $set: inputType },
        });
    case ACTION_TYPES.CLEAR_DATA:
        return update(state, {
            data: { $set: clearedData },
            priceOption: { $set: [] },
            priceSelected: { $set: null },
        });
    case ACTION_TYPES.ON_PRICE_SELECTED:
        const record = state.priceOption.find((item) => item.value === payload.value);

        return update(state, {
            priceSelected: { $set: payload.value },
            data: {
                price: { $set: record.price },
            },
        });
    default:
        return initState;
    }
};

const VehicleOfInterest = ({
    open, onClose, record, crmId,
}) => {
    const classes = useStyles();
    const [state, dispatchData] = useReducer(reducer, initState);
    const { data } = state;
    const [updateVehicleOfInterest, { loading: isUpdating }] = useMutation(OpportunityMutation.UPDATE_VEHICLE_OF_INTEREST);
    const enterVehicleManually = state.inputType === INPUT_TYPES.MANUAL;

    const { userInformation } = useContext(UserContext);
    const customizedStockNumber = useCustomStockNumber(userInformation, { stockNumber: data?.stockNumber, customStockNumber: data?.customStockNumber });

    const getPriceOption = (stickerPrice, internetPrice) => [{
        price: stickerPrice,
        value: PRICE.STICKER_PRICE,
        label: `Sticker Price - ${NumberUtils.applyCurrencyFormat(stickerPrice, '$0,0')}`,
    },
    {
        price: internetPrice,
        value: PRICE.INTERNET_PRICE,
        label: `Internet Price - ${NumberUtils.applyCurrencyFormat(internetPrice, '$0,0')}`,
    }];

    const onChange = (field, value) => {
        if (enterVehicleManually) {
            dispatchData({
                type: ACTION_TYPES.CHANGE_VALUE,
                payload: {
                    field,
                    value,
                },
            });
        }
    };

    const onChangePrice = (value) => {
        dispatchData({
            type: ACTION_TYPES.ON_PRICE_SELECTED,
            payload: { value },
        });
    };

    const toggleModal = () => {
        dispatchData({
            type: ACTION_TYPES.TOGGLE_MODAL,
        });
    };

    const clearData = () => {
        dispatchData({
            type: ACTION_TYPES.CLEAR_DATA,
        });
    };

    const onToggleInputType = (value, shouldClearData = false) => {
        dispatchData({
            type: ACTION_TYPES.TOGGLE_INPUT_TYPE,
            inputType: value,
        });

        if (shouldClearData) clearData();
    };

    useEffect(() => {
        const chosenVehiclePrice = LeadHelper.getVehiclePriceField(record.chosenVehiclePrice);
        const mappedData = OpportunityMapper.mapVehicleOfInterest(record, chosenVehiclePrice);
        const vehicleEnteredBySearch = record.vehicle?.stockNumber > 0;
        const { stickerPrice, internetPrice } = record.vehicle || {};

        dispatchData({
            type: ACTION_TYPES.LOAD_DATA,
            payload: {
                data: {
                    ...mappedData,
                },
            },
            priceOption: vehicleEnteredBySearch ? getPriceOption(stickerPrice, internetPrice) : [],
            priceSelected: record.chosenVehiclePrice || PRICE.INTERNET_PRICE,
            inputType: vehicleEnteredBySearch ? INPUT_TYPES.SEARCH : INPUT_TYPES.MANUAL,
        });
    }, [record]);

    const onSelectVehicle = (vehicleSelected) => {
        const mappedData = OpportunityMapper.mapVehicleOfInterest({
            vehicle: {
                ...vehicleSelected,
            },
        });

        dispatchData({
            type: ACTION_TYPES.LOAD_DATA,
            payload: {
                data: {
                    ...mappedData,
                },
            },
            priceOption: getPriceOption(vehicleSelected.stickerPrice, vehicleSelected.internetPrice),
            priceSelected: PRICE.INTERNET_PRICE,
            inputType: INPUT_TYPES.SEARCH,
        });
        toggleModal();
    };

    const onSave = async () => {
        try {
            const mappedData = OpportunityMapper.mapUpdateVehicleOfInterest(data, state.inputType === INPUT_TYPES.SEARCH, state.priceSelected);
            const input = {
                ...mappedData,
                chosenVehiclePrice: state.inputType === INPUT_TYPES.SEARCH ? state.priceSelected : null,
            };
            const response = await updateVehicleOfInterest({ variables: { input, crmId } });

            if (response.data?.updateVehicleOfInterest) {
                ModalUtils.successMessage(null, 'Saved Successfully!');
                onClose();
            } else {
                ModalUtils.errorMessage(null, MessageUtils.getGenericError('saving', 'Vehicle of Interest'));
            }
        } catch (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
        }
    };

    return (
        <Dialog
            open={open}
            maxWidth="md"
        >
            <DialogAppBar
                title="Vehicle of Interest"
                onClose={onClose}
            />
            <DialogContent className={classes.dialogContent}>
                <Form>
                    <Row className={classes.inputType}>
                        <FormControlLabel
                            value={INPUT_TYPES.SEARCH}
                            className={classes.searchInventory}
                            control={(
                                <Radio
                                    size="small"
                                    checked={!enterVehicleManually}
                                    onChange={() => onToggleInputType(INPUT_TYPES.SEARCH, true)}
                                />
                            )}
                            label={
                                <Typography className={classes.inputTypeLabel}>Search Inventory</Typography>
                            }
                        />
                        <FormControlLabel
                            value={INPUT_TYPES.MANUAL}
                            control={(
                                <Radio
                                    size="small"
                                    checked={enterVehicleManually}
                                    onChange={() => onToggleInputType(INPUT_TYPES.MANUAL)}
                                />
                            )}
                            label={
                                <Typography className={classes.inputTypeLabel}>Enter Manually</Typography>
                            }
                        />
                    </Row>
                    <Row className="g-2">
                        <Col md className={classes.firstColumn}>
                            {!enterVehicleManually && (
                                <>
                                    <Form.Row className={classes.row}>
                                        <Form.Group as={Row} className={classes.group}>
                                            <Form.Label>Stock:</Form.Label>
                                            <Form.Control
                                                value={customizedStockNumber}
                                                type="text"
                                                size="sm"
                                                disabled
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row className={classes.row}>
                                        <Form.Group as={Row} className={classes.group}>
                                            <Form.Label>VIN:</Form.Label>
                                            <Form.Control
                                                value={data.vin || ''}
                                                type="text"
                                                size="sm"
                                                disabled
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                </>
                            )}
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Year:</Form.Label>
                                    <InputNumber
                                        value={data.year}
                                        size="sm"
                                        disabled={!enterVehicleManually}
                                        onChange={(value) => onChange('year', value)}
                                        max={9999}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Make:</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        maxLength={25}
                                        value={data.make || ''}
                                        disabled={!enterVehicleManually}
                                        onChange={(e) => onChange('make', e.target.value)}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Model:</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        maxLength={30}
                                        value={data.model || ''}
                                        disabled={!enterVehicleManually}
                                        onChange={(e) => onChange('model', e.target.value)}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={classes.row}>
                                <Form.Group as={Row} className={classes.group}>
                                    <Form.Label>Trim:</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        maxLength={100}
                                        value={data.trim || ''}
                                        disabled={!enterVehicleManually}
                                        onChange={(e) => onChange('trim', e.target.value)}
                                    />
                                </Form.Group>
                            </Form.Row>
                        </Col>
                        {!enterVehicleManually && (
                            <div className={classes.containerSearch}>
                                <Form.Row>
                                    <Form.Group as={Row} className={classes.searchIcon}>
                                        <IconButton size="small" onClick={toggleModal}>
                                            <SearchIcon fontSize="small" />
                                        </IconButton>
                                    </Form.Group>
                                </Form.Row>
                            </div>
                        )}
                        {enterVehicleManually && (
                            <Col md>
                                <Form.Row className={classes.row}>
                                    <Form.Group as={Row} className={classes.group}>
                                        <Form.Label>Price:</Form.Label>
                                        <InputNumber
                                            size="sm"
                                            showCurrency
                                            thousandSeparator
                                            value={data.price}
                                            placeholder="$0.00"
                                            disabled={!enterVehicleManually}
                                            onChange={(value) => onChange('price', value)}
                                        />
                                    </Form.Group>
                                </Form.Row>
                            </Col>
                        )}
                        {!enterVehicleManually && (
                            <Col md>
                                <Form.Row className={classes.row}>
                                    <Form.Group as={Row} className={classes.group}>
                                        <Form.Label>Price:</Form.Label>

                                        <Select
                                            size="sm"
                                            name="result"
                                            options={state.priceOption}
                                            value={state.priceSelected}
                                            onChange={(_, field) => onChangePrice(field)}
                                            maxMenuHeight={100}
                                        />
                                    </Form.Group>
                                </Form.Row>
                            </Col>
                        )}
                    </Row>
                </Form>
            </DialogContent>
            <DialogActions
                titlePrimary="Save"
                onClickPrimary={onSave}
                disablePrimaryButton={(enterVehicleManually && isEqual(state.vehicle, state.data)) || isUpdating}
                titleSecondary="Cancel"
                onClickSecondary={onClose}
                variant="contained"
            />
            { state.isDialogOpened && (
                <VehicleDialog
                    toggleModal={toggleModal}
                    open={state.isDialogOpened}
                    onSelectVehicle={onSelectVehicle}
                    includeDefaultLot={false}
                />
            ) }
        </Dialog>
    );
};

VehicleOfInterest.propTypes = {
    open: PropTypes.bool,
    record: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    crmId: PropTypes.string.isRequired,
};

VehicleOfInterest.defaultProps = {
    open: false,
    record: {},
};

export default VehicleOfInterest;

import DateUtils, { DateFormat } from 'lib/DateUtils';
import StringUtils from 'lib/StringUtils';
import ImagesUtils from 'utils/ImagesUtils';
import InventoryHelper from 'utils/InventoryHelper';

export default class MapInventoryData {
    static mapList(record) {
        if (record) {
            const emptyColor = 'None';
            const interiorColor = record.intColor || emptyColor;
            const exteriorColor = record.extColor || emptyColor;

            return {
                ...record,
                stock: record.stockNumber,
                image: ImagesUtils.getSecureImageURL(
                    record.thumbnail,
                    InventoryHelper.getThumbnail(record.style),
                ),
                exteriorColor: (exteriorColor.toLowerCase() !== 'null') ? exteriorColor : emptyColor,
                interiorColor: (interiorColor.toLowerCase() !== 'null') ? interiorColor : emptyColor,
                lastUpdatedPrice: DateUtils.getUSFormattedDateFromUTC(record.lastUpdatedPrice, DateFormat.DEFAULT_DATE),
                purchasedDate: DateUtils.getUSFormattedDateFromUTC(record.purchasedDate, DateFormat.DEFAULT_DATE),
                quickNote: record.quickNote || '',
                placeAd: record.placeAd || false,
                editable: false,
            };
        }

        return {};
    }

    static mapVehicleDetail(record) {
        if (record) {
            return {
                images: (record.images || []).map((image) => ImagesUtils.getSecureImageURL(image, record.style)),
                thumbnail: ImagesUtils.getSecureImageURL(
                    record.thumbnail,
                    record.style,
                ),
                alternativeThumbnail: ImagesUtils.getSecureImageURL(
                    record.alternativeThumbnail,
                    record.style,
                ),
                userTabURL1: record.userTabURL1 || '',
                userTabURL2: record.userTabURL2 || '',
                detail: {
                    stockNumber: record.stockNumber,
                    customStockNumber: record.customStockNumber,
                    year: record.year,
                    description: record.description,
                    make: String(record.make).toUpperCase(),
                    model: String(record.model).toUpperCase(),
                    vin: record.vin,
                    trim: record.trim,
                    style: record.style,
                    engine: record.engine,
                    cityMpg: record.cityMpg,
                    highwayMPG: record.highwayMPG,
                    miles: record.miles,
                    eml: record.eml,
                    tmu: record.tmu,
                    exempt: record.exempt,
                    tags: record.tags || [],
                    transmission: record.transmission,
                    drivetrain: record.drivetrain,
                    titleStatus: record.titleStatus,
                    title: record.title,
                    titleNumber: record.titleNumber,
                    lotName: record.lotName,
                    extColor: record.extColor,
                    intColor: record.intColor,
                    genericExteriorColor: record.genericExteriorColor,
                    spareKey: record.spareKey,
                    wholesale: record.wholesale,
                    notes: record.notes,
                    gpsCompany: record.gpsCompany,
                    gpsInfo: record.gpsDevice,
                    gpsDeviceType: record.gpsDeviceType,
                    tag: record.tag,
                    tagExpires: record.tagExpires || '',
                    vehicleLocation: record.vehicleLocation || '',
                    active: record.active,
                    postedDate: record.postedDate,
                    pricing: {
                        age: record.age || 0,
                        purchasedFrom: record.purchasedFrom,
                        purchasedAgent: record.purchasingAgent,
                        purchasedDate: record.purchasedDate || new Date(),
                        purchasedPrice: record.purchasedPrice,
                        repairs: record.repairs,
                        cost: record.cost,
                        stickerPrice: record.stickerPrice,
                        nadaTrade: record.nada,
                        nadaRetail: record.nadaRetail,
                        internetPrice: record.internetPrice,
                        minimumPrice: record.minimumPrice,
                        floorplanCost: record.floorplanCost,
                        markUp: record.markUp,
                        slashedPrice: record.slashedPrice,
                        pack: record.pack,
                        packOverride: record.packOverride,
                        wholeSalePrice: record.wholesalePrice,
                        showSlashed: record.showSlashed,
                        maximumReconCost: record.maximumReconCost,
                        transport: record.transport,
                    },
                    bookValue: {
                        nadaTrade: record.nada,
                        nadaRetail: record.nadaRetail,
                    },
                    floorPlan: {
                        flooredRate: Number(record.flooredRate ?? 0),
                        flooredBy: record.flooredBy,
                        floorPlannedDate: record.floorPlannedDate,
                        curtailmentDate: record.cartalmentDate,
                        floorPaidOn: record.floorPaidOn,
                        borrowedAmount: record.borrowedAmount,
                        isFloorPlanned: record.isFloorPlanned,
                        isBorrowedAmountOverride: record.borrowedAmountOverride,
                        floorplanCost: record.floorplanCost,
                        purchasedPrice: record.purchasedPrice,
                    },
                    advertising: {
                        enableReduce: record.enableReduce,
                        reducePrice: record.reducePrice,
                        reduceFrequency: record.reduceFrequency,
                        removeAd: record.removeAdvertising,
                        placeAd: record.placeAd,
                        websiteDownpayment: record.websiteDownpayment,
                        websitePayment: record.websitePayment,
                        paymentFrequency: record.paymentFrequency,
                        lastReduced: record.lastReduced,
                    },
                    customFields: {
                        customField1Id: record.customField1Id || '',
                        customField2Id: record.customField2Id || '',
                        customField3Id: record.customField3Id || '',
                        customField4Id: record.customField4Id || '',
                    },
                    carfaxUrl: record.carfaxUrl || '',
                    carfaxButtonUrl: record.carfaxButtonUrl || '',
                },
            };
        }

        return {};
    }

    static mapRepairs(record) {
        if (record) {
            return {
                stockNumber: record.StockNumber,
                checkNo: record.CheckNo === 0 ? '' : record.CheckNo,
                vendorName: record.VendorName,
                datePaid: record.DatePaid,
                comment: record.Comment,
                amount: record.Amount,
                categoryName: record.CategoryName,
                billID: record.BillID,
                memo: record.BillMemo,
                inventoryRepairId: record.InventoryRepairId,
                imported: record.Imported,
            };
        }

        return {};
    }

    static mapSaveRepair(record) {
        if (record) {
            return {
                CheckNo: record.checkNo,
                VendorName: record.vendorName,
                DatePaid: DateUtils.formatUTC(record.datePaid),
                Comment: record.comment,
                Amount: record.amount,
                CategoryName: record.categoryName,
            };
        }

        return {};
    }

    static mapCreateUpdateVechicle(record) {
        return {
            thumbnail: record.thumbnail,
            alternativeThumbnail: record.alternativeThumbnail,
            year: String(record.year),
            make: record.make.toUpperCase(),
            model: record.model.toUpperCase(),
            trim: record.trim,
            style: record.style,
            engine: record.engine,
            vin: record.vin,
            transmission: record.transmission,
            extColor: record.extColor,
            intColor: record.intColor,
            genericExteriorColor: record.genericExteriorColor,
            lotName: record.lotName,
            active: true,
            newUsed: 'Used',
            miles: record.miles,
            tmu: record.isTMU,
            exempt: record.isExempt,
            eml: record.isEML,
            title: record.title || false,
            titleNumber: record.title ? record.titleNumber : '',
            titleStatus: record.titleStatus,
            wholesale: record.isWholesale,
            spareKey: record.spareKey,
            purchasedFrom: record.purchasingInfo.purchasedFrom,
            purchasingAgent: record.purchasingInfo.purchasedAgent,
            purchasedDate: DateUtils.format(record.purchasingInfo.purchasedDate),
            cr: record.purchasingInfo.cr,
            frame: record.purchasingInfo.isFrame,
            purchasedPrice: record.purchasingInfo.purchasedPrice,
            repairs: record.purchasingInfo.repairs,
            cost: record.purchasingInfo.cost,
            carFax: record.purchasingInfo.carFax,
            autoCheck: record.purchasingInfo.autoCheck,
            pack: record.purchasingInfo.pack,
            packOverride: record.purchasingInfo.packOverride,
            maximumReconCost: record.purchasingInfo.maximumReconCost,
            showSlashed: record.pricingInfo.isSlashedPrice,
            slashedPrice: record.pricingInfo.slashedPrice,
            stickerPrice: record.pricingInfo.stickerPrice,
            internetPrice: record.pricingInfo.internetPrice,
            minimumPrice: record.pricingInfo.minimumPrice,
            enableReduce: record.advertisingInfo.isReducePrice,
            reducePrice: record.advertisingInfo.reducePrice,
            reduceFrequency: record.advertisingInfo.every,
            removeAdvertising: record.advertisingInfo.removeAd,
            placeAd: record.advertisingInfo.isPlaceOnline,
            lastReduced: record.advertisingInfo.lastReduced,
            tag: record.tag,
            tagExpires: !StringUtils.isEmpty(record.tagExpires) ? record.tagExpires : null,
            drivetrain: record.drivetrain,
            userTabURL1: record.userTabURL1,
            userTabURL2: record.userTabURL2,
        };
    }

    static mapWindowStickerRecord(record) {
        if (record) {
            return {
                stockNumber: record.stockNumber,
                year: record.year,
                make: record.make,
                model: record.model,
                trim: record.trim,
                miles: record.miles,
                isChecked: false,
            };
        }

        return null;
    }
}

import { gql } from '@apollo/client';

export default class PayrollSettingsQuery {
    static GET_PAYROLL_DEFAULT_SETTINGS = gql`
        query getPayrollDefaultSettings($lotName: String!) {
            getPayrollDefaultSettings(lotName: $lotName) {
                payPeriod
                defaultBank
                minimumWage
                overtimeRate
                medicareTaxRate
                payPeriodStarts
                defaultCheckType
                payPeriodStartDate
                weeklyRegularHours
                socialSecurityLimit
                medicareSingleLimit
                medicareMarriedLimit
                editableCheckNumbers
                socialSecurityTaxRate
                medicareAdditionalTaxRate
            }
        }
    `;

    static GET_PAYROLL_MAP_LIST_FOR_SETTINGS = gql`
        query getPayrollMapListForSettings {
            getPayrollMapListForSettings{
                id,
                isCore
                account,
                mapField,
                department,
                employeeType
            }
        }
    `;

    static GET_PAYROLL_DB_DESCRIPTION_LIST = gql`
        query getPayrollDBList {
            getPayrollDBList {
                bdId
                description
            }
        }
    `;

    static GET_EMPLOYEE_TYPE_LIST = gql`
        query getEmployeeTypeList {
            getEmployeeTypeList {
                employeeTypeId
                description
            }
        }
    `;

    static GET_PAYROLL_MAP = gql`
        query getPayrollMap($mapId: Int!) {
            getPayrollMap(mapId: $mapId) {
                mapField
                glAccount
                department
                employeeType
            }
        }
    `;

    static GET_PAYROLL_DB_LIST = gql`
        query getPayrollDBList {
            getPayrollDBList {
                bdId
                isCore
                isLoan
                bdType
                taxType
                description
            }
        }
    `;

    static GET_DEPARTMENT_LIST = gql`
        query departmentList {
            departmentList {
                departmentId
                description
            }
        }
    `;

    static GET_PAYROLL_CUSTOM_TAX_FIELDS = gql`
        query getPayrollCustomTaxFields($lotName: String!) {
            getPayrollCustomTaxFields(lotName: $lotName) {
                userTax
                stateTax
                userTaxName
                customTaxBankAccount
            }
        }
    `;
}

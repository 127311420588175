import React, { Component } from 'react';

// Others
import PropTypes from 'prop-types';
import update from 'immutability-helper';

// GraphQL
import GraphQLClient from 'services/apollo/GraphQLClient';

import { cloneDeep } from 'lodash';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import DealService from 'services/modules/DealService';
import VehicleService from 'services/modules/VehicleService';
import KeyStore from 'utils/KeyStore';

const TradeInFormContainer = (WrappedComponent) => class extends Component {
    static propTypes = {
        hideTradeInForm: PropTypes.func.isRequired,
        accountNumber: PropTypes.number.isRequired,
        isEditing: PropTypes.bool,
        tradeIn: PropTypes.object,
        dealVins: PropTypes.arrayOf(PropTypes.string).isRequired,
    };

    static defaultProps = {
        isEditing: false,
        tradeIn: {},
    };

    constructor(props) {
        super(props);

        this.graphqlClient = new GraphQLClient();
        this.dealService = new DealService();
        this.vehicleService = new VehicleService();
        this.keyStore = new KeyStore();

        this.state = {
            saving: false,
            isValidVin: props.isEditing,
            tradeIn: {
                name: '',
                address: '',
                city: '',
                state: '',
                zip: '',
                county: '',
                phone: '',
                notes: '',
                allowance: 0,
                acv: 0,
                payOff: 0,
                payOffGoodTill: null,
                noSalesTaxCredit: false,
                vehicle: {
                    stockNumber: null,
                    vin: '',
                    year: '',
                    make: '',
                    model: '',
                    trim: '',
                    extColor: '',
                    intColor: '',
                    miles: 0,
                    tmu: false,
                    exempt: false,
                    eml: false,
                    engine: '',
                    transmission: '',
                    style: '',
                    drivetrain: '',
                    titleStatus: '',
                    title: false,
                    titleNumber: '',
                    spareKey: '',
                },
            },
            trimList: [],
            engineList: [],
            isEditing: false,
        };

        this.initBind();
    }

    componentDidMount() {
        const { props: { isEditing, tradeIn } } = this;
        const { state } = this;

        if (isEditing) {
            const updateTrade = update(state.tradeIn, { $merge: tradeIn });
            const updateVehicle = update(state.tradeIn.vehicle, { $merge: tradeIn.vehicle });

            this.setState({
                tradeIn: {
                    ...updateTrade,
                    vehicle: updateVehicle,
                },
            }, () => {
                this.decodeVIN(tradeIn.vehicle.vin);
            });
        }
    }

    onSave() {
        const { props: { isEditing, dealVins } } = this;
        const { state } = this;
        const { saving } = state;

        if (saving) {
            return;
        }

        this.setState({ saving: true });

        if (dealVins.some((vin) => vin === state.tradeIn.vehicle.vin)) {
            ModalUtils.errorMessage([], 'You can’t add a Vehicle with the same VIN more than once for this deal.');

            this.setState({ saving: false });

            return;
        }

        if (isEditing) {
            this.update();

            return;
        }

        const { props, props: { accountNumber } } = this;
        const selectedLot = this.keyStore.getSelectedLot();
        const tradeIn = cloneDeep(state.tradeIn);

        tradeIn.payOffGoodTill = tradeIn.payOffGoodTill ? tradeIn.payOffGoodTill.toISOString() : null;
        tradeIn.vehicle.year = StringUtils.isEmpty(tradeIn.vehicle.year) ? '' : tradeIn.vehicle.year.toString();
        tradeIn.vehicle.lotName = selectedLot.lotName;
        tradeIn.vehicle.lotId = selectedLot.lotId;

        const args = {
            dealId: accountNumber,
            input: tradeIn,
        };

        this.dealService.createTradeIn(args)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.createTradeIn) {
                    props.hideTradeInForm();
                }
            }).finally(() => {
                this.setState({ saving: false });
            });
    }

    onChangeTradeInInfo(name, value) {
        this.setState((prevState) => ({
            tradeIn: update(prevState.tradeIn, { [name]: { $set: value } }),
        }));
    }

    onChangeVehicleInfo(name, value) {
        this.setState(({ tradeIn }) => {
            const newRecord = { ...tradeIn };
            newRecord.vehicle[name] = value;

            return { tradeIn: newRecord };
        }, () => {
            if (name === 'vin') {
                this.setState({
                    isValidVin: false,
                    trimList: [],
                    engineList: [],
                });
                if ((value || '').length === 17) this.decodeVIN(value);
            }
        });
    }

    onCancel() {
        const { props } = this;
        props.hideTradeInForm();
    }

    update() {
        const { state: { tradeIn }, props } = this;
        const { dealVehicleTradeID } = tradeIn;
        // TODO: Remove tradeNumber field from the tradeIn after removing compatibility mode
        delete tradeIn.dealVehicleTradeID;
        delete tradeIn.dealId;
        delete tradeIn.stockNumber;
        delete tradeIn.vehicle.customStockNumber;
        tradeIn.vehicle.year = StringUtils.isEmpty(tradeIn.vehicle.year) ? '' : tradeIn.vehicle.year.toString();

        const args = {
            dealVehicleTradeId: dealVehicleTradeID,
            input: tradeIn,
        };

        this.dealService.updateTradeIn(args)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.updateTradeIn) {
                    props.hideTradeInForm();
                }
            }).finally(() => {
                this.setState({ saving: false });
            });
    }

    decodeVIN(vin) {
        const {
            state,
        } = this;

        this.vehicleService.decodeVIN(vin)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    this.setState({ isValidVin: false });
                    if (graphQLErrors?.[0].message === 'VIN is not supported') {
                        ModalUtils.warningMessage(null, "VIN can't be decoded. Please update information and save manually.");
                    } else {
                        ModalUtils.errorMessage(graphQLErrors);
                    }
                    return;
                }

                if (!data || !data.decodeVin) return;

                const { decodeVin } = data;
                const tradeIn = update(state.tradeIn, {
                    vehicle: {
                        $merge: {
                            year: Number(decodeVin.year),
                            make: decodeVin.make,
                            model: decodeVin.model,
                            style: decodeVin.bodyStyle,
                            drivetrain: decodeVin.drivetrain,
                            extColor: decodeVin.exteriorColor ? decodeVin.exteriorColor.colorCode : '',
                        },
                    },
                });

                this.setState({
                    tradeIn,
                    trimList: decodeVin.trim,
                    engineList: decodeVin.engines,
                    isValidVin: true,
                });
            });
    }

    initBind() {
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.decodeVIN = this.decodeVIN.bind(this);
        this.onChangeTradeInInfo = this.onChangeTradeInInfo.bind(this);
        this.onChangeVehicleInfo = this.onChangeVehicleInfo.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                onSave={this.onSave}
                onCancel={this.onCancel}
                onChangeTradeInInfo={this.onChangeTradeInInfo}
                onChangeVehicleInfo={this.onChangeVehicleInfo}
            />
        );
    }
};

export default TradeInFormContainer;
